import React, { useMemo } from 'react';
import BaseTable from '../../Table';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../../Table/EditableTextInput';
import EditableDatePickerInput from '../../Table/EditableDatePickerInput';
import { TrashSquareIcon } from '../../../components/Icons';
import * as style from '../index.module.css';
import Toastr from '../../../components/Toastr';

const ChildrenTable = ({ tableToolbar, staffId, trigger, setTrigger }) => {
  const { t, i18n } = useTranslation();

  const getChildren = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], staff_id: staffId }

    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.children(), { params: params })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateChild = (id, params) => {
    axiosInstance.put(routes.child(id), params)
      .then(resp => {
        Toastr({ message: t("update_success"), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    return (
      <div className={`${style['trash-button']}`} onClick={() => deleteChild(row.original.id)}>
        <TrashSquareIcon className="fs-2hx text-danger" />
      </div>
    )
  }

  const deleteChild = (id) => {
    axiosInstance.delete(routes.child(id))
      .then(resp => {
        Toastr({ message: t('delete_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: t('first_name'),
        accessorFn: row => row?.attributes?.name,
        cell: ({ row }) => <EditableTextInput
          id={row?.original?.id}
          attribute='name'
          initialValue={row?.original?.attributes?.name || "-"}
          handleSubmit={updateChild}
        />
      },
      {
        id: 'surname',
        header: t('last_name'),
        accessorFn: row => row?.attributes?.surname,
        cell: ({ row }) => <EditableTextInput
          id={row?.original?.id}
          attribute='surname'
          initialValue={row?.original?.attributes?.surname || "-"}
          handleSubmit={updateChild}
        />
      },
      {
        id: 'birth_date',
        header: t('birthday'),
        accessorFn: row => row?.attributes?.birth_date,
        cell: ({ row }) => <EditableDatePickerInput
          id={row.original.id}
          attribute='birth_date'
          initialValue={row.original.attributes.birth_date}
          handleSubmit={updateChild}
        />
      },
      {
        id: 'delete',
        header: null,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      }
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#resource-children" role="button">
          {t('children').toUpperCase()}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3" />
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="resource-children" className="collapse show">
        <BaseTable
          keyName="children"
          getDataFn={getChildren}
          columns={columns}
          tableToolbar={tableToolbar}
          trigger={trigger}
          showSearchInput={false}
        />
      </div >
    </>
  )
}

export default ChildrenTable;

