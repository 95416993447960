import React, { useMemo, useState } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../../components/Table/EditableTextInput';
import EditableSelectInput from '../../components/Table/EditableSelectInput';
import Toastr from '../../components/Toastr';
import { TrashSquareIcon } from '../../components/Icons';
import * as style from './index.module.css';

const Table = ({ tableToolbar, filters, staff_id, staff_order_id, date }) => {
  const { t, i18n } = useTranslation();
  const [trigger, setTrigger] = useState(false);

  const getBookingResults = async (pagination, sorting, globalFilter, customFilters,) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], staff_id: staff_id, staff_order_id: staff_order_id, day: date }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.bookingResults(), { params: { ...params } })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateBookingResult = (id, params) => {
    axiosInstance.put(routes.bookingResult(id), params)
      .then(resp => {
        Toastr({ message: t("booking_results_update_success"), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const updateBookingResultItem = (id, params) => {
    axiosInstance.put(routes.bookingResultItem(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const formatDriverInputs = (driverInputs, editable) => {
    return (
      <>
        {driverInputs.map((item) => (
          editable ? editableFormat(item) : staticFormat(item)
        ))}
      </>
    );
  };

  const editableFormat = (item) => {
    const [currentValue, setCurrentValue] = useState(item.result_boolean);
    const handleToggle = () => {
      const newValue = !currentValue;
      setCurrentValue(newValue);

      const params = {
        booking_result_item: {
          id: item.id,
          result_boolean: newValue,
        }
      };

      updateBookingResultItem(item.id, params);
    };

    return <div key={item.id}>
      <small>
        <u>{item.service_field_name}</u>
        : {item.is_integer ? (
          <>
            <span style={{ display: 'inline-block', marginRight: '4px' }}>
              <EditableTextInput
                id={item.id}
                attribute="quantity"
                initialValue={item.quantity.toFixed(2)}
                handleSubmit={updateBookingResultItem}
              />
            </span>
            {item.unit}
          </>
        ) : item.is_boolean ? (
          <span
            style={{ display: 'inline-block', cursor: 'pointer', color: 'blue' }}
            onClick={handleToggle}
          >
            {currentValue ? t('yes') : t('no')}
          </span>
        ) : (
          item.result_string
        )}
      </small>
    </div>
  };

  const staticFormat = (item) => {
    return <div key={item.id}>
      <small>
        <u>{item.service_field_name}</u>
        : {item.is_integer ? (
          <>
            {item.quantity.toFixed(2)} {item.unit}
          </>
        ) : item.is_boolean ? (
          item.result_boolean ? t('yes') : t('no')
        ) : (
          item.result_string
        )}
      </small>
    </div>
  };

  const getStaffOrders = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      staff_id: staff_id,
      day: day,
      query: inputText
    }

    return axiosInstance.get(routes.staffOrders(), { params: params })
      .then((response) => {
        return response.data.data.map((staffOrder) => ({
          value: staffOrder.id,
          label: staffOrder.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getFields = (inputText, bookingResultId) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      booking_result_id: bookingResultId
    }

    return axiosInstance.get(routes.fields(), { params: params })
      .then((response) => {
        return response.data.data.map((field) => ({
          value: field.id,
          label: field.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const deleteHtml = (row) => {
    return (
      row.original.attributes.editable ?
        <div className={style['trash-button']} onClick={() => deleteBookingResult(row.original.id)}>
          <TrashSquareIcon className="fs-2hx text-danger" />
        </div> :
        <div className={style['trash-button-cancel']}>
          <TrashSquareIcon className="fs-2hx test-muted" />
        </div>
    )
  }

  const deleteBookingResult = (id) => {
    axiosInstance.delete(routes.bookingResult(id))
      .then(resp => {
        Toastr({ message: t('booking_results_delete_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const columns = useMemo(
    () => [
      {
        id: 'time',
        header: t("time_expressions.time"),
        enableSorting: false,
        cell: ({ row }) => <EditableTextInput
          id={row.original.id}
          attribute='time'
          initialValue={row?.original?.attributes?.custom_time}
          handleSubmit={updateBookingResult}
        />
      },
      {
        id: 'driver_inputs',
        size: 250,
        minSize: 250,
        accessorFn: row => formatDriverInputs(row?.attributes?.driver_inputs, row?.attributes?.editable),
        header: t("driver_inputs"),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'staff_order',
        header: t("staff_orders"),
        enableSorting: false,
        cell: ({ row }) => {

          const InitialValue = {
            value: row.original.attributes?.staff_order_id,
            label: row.original.attributes?.staff_order_name
          }

          return (
            <EditableSelectInput
              id={row.original.id}
              attribute='staff_order_id'
              initialValue={InitialValue}
              handleSubmit={updateBookingResult}
              getEntities={getStaffOrders}
            />
          )
        }
      },
      {
        id: 'field',
        header: t("fields"),
        enableSorting: false,
        cell: ({ row }) => {

          const InitialValue = {
            value: row.original.attributes?.field_id,
            label: row.original.attributes?.field_long_name
          }

          return (
            <EditableSelectInput
              id={row.original.id}
              attribute='field_id'
              initialValue={InitialValue}
              handleSubmit={updateBookingResult}
              getEntities={getFields}
            />
          )
        }
      },
      {
        id: 'delete',
        accessorFn: row => row?.attributes?.trash_icon,
        header: null,
        size: 50,
        minSize: 50,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getBookingResults}
      columns={columns}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection={'asc'}
      trigger={trigger + date}
    />
  )
}

export default Table;
