import React from 'react'
import { useTranslation } from 'react-i18next';

const UtilizationScenariosTable = ({machine}) => {
  const { t } = useTranslation()
  return (
    <div className="card card-flush">
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          Utilization Scenarios
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="row mt-2 justify-content-md-center">
          <div className="col-12 table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
              <thead>
              <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th colSpan="1">
                  <div>{t('occupancy_year')}</div>
                </th>
                <th colSpan="1">
                  <div>{t('deployment_hour')}</div>
                </th>
              </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
              {[...Array(11)].map((x, index) => {
                return(
                  <tr>
                    <td>{ (machine.plan_work_hours_per_year * (0.25 + 0.15 * index)).toFixed(2) }</td>
                    <td>{ ((parseFloat(machine.fix_cost_value) + parseFloat(machine.machine_work_per_hour_value)) / (0.25 + 0.15  * index)).toFixed(2) } EUR</td>
                  </tr>
                )
              }
              )}
              </tbody>
              <tfoot className="fw-bold text-black">
              <tr>
                <th/>
                <th/>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilizationScenariosTable;
