import React, { useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import BaseTable from '../../components/Table';
import EditableTextInput from '../../components/Table/EditableTextInput';
import TableButton from '../../components/Table/TableButton';
import { TrashSquareIcon, PlusIcon } from '../../components/Icons';
import Toastr from '../../components/Toastr';
import FieldSelect from './FieldSelect';

const ServiceFeatureTable = ({ businessPartnerId, serviceId, updateBusinessPartnerServiceFeature }) => {
  const { t, i18n } = useTranslation()
  const [trigger, setTrigger] = useState(true)

  const tableToolbar = () => { return (<></>) }

  const updateServiceFeature = (id, params) => {
    updateBusinessPartnerServiceFeature(id, params)
    setTrigger(Math.floor(Date.now() / 1000)) // FIXME: Dirty hack to refresh the table
  }

  const getServiceFeatures = async () => {
    const params = { business_partner_id: businessPartnerId, service_id: serviceId }
    const res = await axiosInstance.get(routes.businessPartnerServiceFeatures(), { params: params })

    return (
      { rows: res.data.data }
    )
  }

  const updateServiceFeatureRow = (id, params) => {
    axiosInstance.patch(routes.serviceFeatureRows(id), { service_feature_row: params })
      .then(resp => {
        Toastr({message: t("update_success")})
        setTrigger(Math.floor(Date.now() / 1000)) // FIXME: Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: "error",
          message: error.response?.data?.error || t("critical_error")
        })
      })
  }

  const addRange = (id) => {
    axiosInstance.put(routes.addRangeBusinessPartnerServiceFeature(id))
      .then(resp => {
        Toastr({message: t("update_success")})
        setTrigger(Math.floor(Date.now() / 1000)) // FIXME: Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: "error",
          message: error.response?.data?.error || t("critical_error")
        })
      })
  }

  const invoiceTextHtml = (row) => {
    return (
      <EditableTextInput
        id={row?.original?.id}
        attribute="name"
        placeholder={t("service_features.invoice_text")}
        initialValue={row?.original?.attributes?.name}
        handleSubmit={updateServiceFeature}
        className={row?.original?.attributes?.show_bold_invoice_text ? "fw-bolder" : "" }
      />
    )
  }

  const conditionHtml = (row) => {
    const { original } = row
    const { attributes } = original || {}
    const { base_feature, show_conditions, rule_field_id, rule_fields } = attributes || {}

    return (
      <>
        {
          !base_feature &&
            <div className="align-items-center px-1 min-h-30px">
              <FieldSelect
                serviceFeatureId={original.id}
                fieldId={rule_field_id}
                fieldName="rule_field_id"
                collection={rule_fields}
                updateServiceFeature={updateServiceFeature}
              />
            </div>
        }
        {show_conditions && rangesHtml(row)}
        {!show_conditions && base_feature && t("always")}
        {
          <div className="mt-4">
            <TableButton
              className="btn-primary p-1"
              size="small"
              onClick={() => addRange(original.id)}
              icon={<PlusIcon />}
              title={t("service_features.add_range")}
            />
          </div>
        }
      </>
    )
  }

  const rangesHtml = (row) => {
    const { attributes } = row.original || {}
    const { service_feature_rows, rule_field_unit_name } = attributes || []

    if (service_feature_rows.length <= 1) return null

    return (
      <>
        {service_feature_rows.map((serviceFeatureRow, index) => {
          const previousRangeTo = index === 0 ? 0 : service_feature_rows[index - 1]?.attributes?.range_to
          const currentRangeTo = serviceFeatureRow.attributes?.range_to
          const isLast = index === service_feature_rows.length - 1

          return (
            <div key={serviceFeatureRow.id} className="d-flex border-bottom bg-primary-subtle align-items-center px-1 min-h-30px">
              <span className="me-1"> &gt; </span>
              <span className="me-1">{previousRangeTo}</span>
              <span className="me-1"> - </span>
              <span className="me-1">
                {
                  isLast ? "∞" :
                    <EditableTextInput
                      id={serviceFeatureRow.id}
                      attribute="range_to"
                      placeholder={t("service_features.condition")}
                      initialValue={currentRangeTo ? currentRangeTo : "0.00"}
                      handleSubmit={updateServiceFeatureRow}
                    />
                }
              </span>
              <span className="me-1">{rule_field_unit_name}</span>
            </div>
          )
        })}
      </>
    )
  }


  const originalPriceHtml = (row) => {
    const { attributes } = row.original || {}
    const { service_feature_rows, base_feature } = attributes || []

    return (<>
      {!base_feature && <div className="align-items-center px-1 min-h-30px"></div>}
      {
        service_feature_rows.length > 0 &&
          service_feature_rows.map((serviceFeatureRow, index) => {
            return (
              <div key={index} className="d-flex border-bottom bg-primary-subtle align-items-center px-1 min-h-30px">
                <EditableTextInput
                  id={serviceFeatureRow.id}
                  attribute="original_price"
                  placeholder={t("service_features.original_price")}
                  initialValue={serviceFeatureRow.attributes?.formatted_original_price}
                  handleSubmit={updateServiceFeatureRow}
                />
              </div>
            )
          })
      }
    </>)
  }

  const pricePerUnitHtml = (row) => {
    const { attributes } = row.original || {}
    const { base_feature, price, mult_field_id, mult_fields } = attributes || {}

    return (
      <>
        {
          !base_feature &&
            <div className="align-items-center px-1 min-h-30px">
              <FieldSelect
                serviceFeatureId={row.original.id}
                fieldId={mult_field_id}
                fieldName="mult_field_id"
                collection={mult_fields}
                updateServiceFeature={updateServiceFeature}
              />
            </div>
        }
        {pricesHtml(attributes)}
      </>
    )
  }

  const pricesHtml = (attributes) => {
    const { service_feature_rows, mult_field_unit_name, mult_field_id } = attributes || []

    return (<>
      {
        service_feature_rows.length > 0 &&
          service_feature_rows.map((serviceFeatureRow, index) => {
            return (
              <div key={index} className="d-flex border-bottom bg-primary-subtle align-items-center px-1 min-h-30px">
                <EditableTextInput
                  id={serviceFeatureRow.id}
                  attribute="price"
                  placeholder={t("service_features.price_per_unit")}
                  initialValue={serviceFeatureRow.attributes?.price_per_unit}
                  handleSubmit={updateServiceFeatureRow}
                />
                {
                  mult_field_id && <>
                    <span className="mx-1"> {t("shared.per")} </span>
                    <span className="me-1">{mult_field_unit_name}</span>
                  </>
                }
              </div>
            )
          })
      }
    </>)
  }

  const pricePerentageIncreaseHtml = (row) => {
    const { service_feature_rows, base_feature } = row.original?.attributes || []

    return (<>
      {!base_feature && <div className="px-1 min-h-30px"></div>}
      {
        service_feature_rows.length > 0 &&
          service_feature_rows.map((serviceFeatureRow, index) => {
            return (
              <div key={index} className="d-flex border-bottom bg-primary-subtle align-items-center px-1 min-h-30px">
                {serviceFeatureRow.attributes?.price_percentage_increase} %
              </div>
            )
          })
      }
    </>)
  }

  const dieselHtml = (row) => {
    const { service_feature_rows, mult_field_unit_name, mult_field_id, base_feature } = row.original?.attributes || []

    if (!mult_field_id) return (<>
      <div className="px-1 min-h-30px"></div>
      <div className="bg-primary-subtle">-</div>
    </>)

    return (<>
      {!base_feature && <div className="px-1 min-h-30px"></div>}
      {
        service_feature_rows.length > 0 &&
          service_feature_rows.map((serviceFeatureRow, index) => {
            return (
              <div key={index} className="d-flex border-bottom bg-primary-subtle align-items-center px-1 min-h-30px">
                <EditableTextInput
                  id={serviceFeatureRow.id}
                  attribute="diesel"
                  placeholder={t("service_features.diesel")}
                  initialValue={serviceFeatureRow.attributes?.diesel}
                  handleSubmit={updateServiceFeatureRow}
                />
                {
                  mult_field_id && <>
                    <span className="mx-1"> L / </span>
                    <span className="me-1">{mult_field_unit_name}</span>
                  </>
                }
              </div>
            )
          })
      }
    </>)
  }

  const discountHtml = (row) => {
    const initialValue = row.original?.attributes?.discount_perc ? row.original?.attributes?.discount_perc : "-"

    return (
      <div className="d-flex align-items-center px-1 min-h-30px">
        <EditableTextInput
          id={row.original.id}
          attribute="discount_perc"
          placeholder={t("service_features.discount")}
          initialValue={initialValue}
          handleSubmit={updateServiceFeature}
        />
        <span className="mx-1"> % </span>
      </div>
    )
  }

  const deleteServiceFeatureRow = (id) => {
    Swal.fire({
      title: t("shared.confirmation_question"),
      width: 400,
      toast: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("shared.delete"),
      cancelButtonText: t("cancel"),
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.delete(routes.serviceFeatureRows(id))
          .then(response => {
            Toastr({message: t("delete_success")})
            setTrigger(Math.floor(Date.now() / 1000)) // FIXME: Dirty hack to refresh the table
           })
          .catch(response => {
            Toastr({type: 'error', message: response.data?.errors})
          })
      }
    })
  }

  const deleteServiceFeature = (id) => {
    Swal.fire({
      title: t("shared.confirmation_question"),
      width: 400,
      toast: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("shared.delete"),
      cancelButtonText: t("cancel"),
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.delete(routes.businessPartnerServiceFeature(id))
          .then(response => {
            Toastr({message: response.data?.message})
            setTrigger(Math.floor(Date.now() / 1000)) // FIXME: Dirty hack to refresh the table
           })
          .catch(response => {
            Toastr({type: 'error', message: response.data?.errors})
          })
      }
    })
  }

  const actionsHtml = (row) => {
    const { service_feature_rows, base_feature } = row.original?.attributes || []

    return (<>
      {!base_feature && <div className="px-1 min-h-30px"></div>}
      {
        service_feature_rows.length > 0 &&
          service_feature_rows.map((serviceFeatureRow, index) => {
            return (
              <div key={index} className="align-items-center h-30px">
                {
                  serviceFeatureRow.attributes?.can_be_deleted ?
                    <div className="d-flex">
                      <div className="cursor-pointer" onClick={() =>
                        serviceFeatureRow.attributes?.is_single_row ?
                          deleteServiceFeature(row.original.id) :
                          deleteServiceFeatureRow(serviceFeatureRow.id)
                      }>
                        <TrashSquareIcon className="fs-2hx text-danger" />
                      </div>
                    </div>
                    :
                    <TrashSquareIcon className="fs-2hx text-muted" />
                }
              </div>
            )
          })
      }
    </>)
  }

  const columns = useMemo(
    () => [
      {
        id: "name",
        header: t("service_features.invoice_text"),
        enableSorting: false,
        cell: ({ row }) => invoiceTextHtml(row)
      },
      {
        id: "service_feature_rows",
        header: t("service_features.condition"),
        enableSorting: false,
        cell: ({ row }) => conditionHtml(row)
      },
      {
        id: "formatted_original_price",
        header: t("service_features.original_price"),
        enableSorting: false,
        cell: ({ row }) => originalPriceHtml(row)
      },
      {
        id: "price_per_unit",
        header: t("service_features.price_per_unit"),
        enableSorting: false,
        cell: ({ row }) => pricePerUnitHtml(row)
      },
      {
        id: "price_percentage_increase",
        header: t("service_features.money_percentage_difference"),
        enableSorting: false,
        cell: ({ row }) => pricePerentageIncreaseHtml(row)
      },
      {
        id: "diesel",
        header: t("service_features.diesel"),
        enableSorting: false,
        cell: ({ row }) => dieselHtml(row)
      },
      {
        id: "discount_perc",
        header: t("service_features.discount"),
        enableSorting: false,
        cell: ({ row }) => discountHtml(row)
      },
      {
        id: "actions",
        header: "",
        enableSorting: false,
        size: 35,
        cell: ({ row }) => actionsHtml(row)
      },

    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getServiceFeatures}
      tableToolbar={tableToolbar}
      showSearchInput={false}
      enableSorting={false}
      enablePagination={false}
      showHeader={false}
      tableAlign={"top"}
      trigger={trigger + serviceId}
    />
  )
}

export default ServiceFeatureTable;
