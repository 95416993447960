import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'
import { axiosInstance, routes } from '../../utils/api_base';
import Toastr from '../../components/Toastr';
import TableButton from '../../components/Table/TableButton';
import JobProgressBar from '../../components/JobProgressBar';

const ChangePricesForm = ({ businessPartnerId }) => {
  const { t } = useTranslation()
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [jobId, setJobId] = useState();

  const onSubmit = async values => {
    const data = {
      percentage: values.percentage
    }

    return axiosInstance.put(routes.increaseAllPrices(businessPartnerId), data)
      .then(response => {
        Toastr({
          title: t("shared.success"),
          message: response.data.message,
          options: { closeButton: true }
        })
        setJobId(response.data.job_id)
        setShowProgressBar(true)
      }).catch(error => {
        Toastr({
          type: "error",
          message: error.response.data.message,
          options: { closeButton: true }
        })
        setShowProgressBar(false)
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        percentage: ""
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="pt-3">
          <label>{t("service_features.price_change_in_percent")}</label>
          <div className="d-flex align-items-center">
            <Field
              name="percentage"
              component="input"
              type="text"
              className="form-control w-300px"
            />
            <TableButton
              className="btn btn-primary fw-semibold px-6 ms-3"
              type="submit"
              title={t("service_features.change_all_prices")}
              disabled={submitting || pristine}
            />
          </div>
          {showProgressBar && <JobProgressBar jobId={jobId} />}
        </form>
      )}
    />
  )
}

export default ChangePricesForm;
