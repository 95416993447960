import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EditableSelectInput from '../../components/Table/EditableSelectInput';

const FieldSelect = ({ serviceFeatureId, fieldId, fieldName, collection, updateServiceFeature }) => {
  const { t } = useTranslation()

  const findLabelById = () => {
    const foundItem = collection.find(item => item[1] === fieldId)
    return foundItem ? foundItem[0] : null
  }

  const initialSelectValue = useMemo(() => {
    if (fieldId == null && collection && collection.length > 0) {
      const foundItem = collection.find(item => item[1] === 0) // Item with id 0
      return { label: foundItem[0], value: foundItem[1] };
    }

    return { value: fieldId, label: findLabelById() }
  }, [fieldId, collection])

  const collectionOptions = () => {
    return (
      collection?.map((item) => ({
        label: item[0],
        value: item[1]
      })) || []
    )
  }

  const getCollectionOptions = async () => {
    return await collectionOptions
  }

  return (
    <EditableSelectInput
      id={serviceFeatureId}
      initialValue={initialSelectValue}
      attribute={fieldName}
      placeholder={t(fieldName)}
      handleSubmit={updateServiceFeature}
      getEntities={getCollectionOptions}
    />
  )
}

export default FieldSelect;
