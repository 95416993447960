import React, { useState } from 'react';
import Table from './Table';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import Navigation from '../../components/Navigation';
import EditCustomizingModal from '../Articles/EditCustomizingModal';
import Modal from '../../components/Modal';
import { GearIcon } from '../../components/Icons';

const ArticlePurchases = ({ articleId, plant_protection }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <TableButton
        type="button"
        className="btn-light-primary me-3"
        icon={<GearIcon />}
        onClick={() => setModalOpen(true)}
      />
    )
  }

  const navItems = [
    { href: `/articles/${articleId}`, label: t('master_data') },
    { href: `/articles/${articleId}/purchases`, label: t('purchases'), active: true },
    { href: `/articles/${articleId}/show_disposed`, label: t('planned') },
    { href: `/articles/${articleId}/consumptions`, label: t('consumptions')},
    { href: `/articles/${articleId}/bills`, label: t('sales') }
  ]

  return (
    <>
      <Navigation items={navItems} />
      <Modal className="modal fade show w-750px mw-750px" open={modalOpen} onClose={() => setModalOpen(false)} title={t('stock_settings')}>
        <EditCustomizingModal onClose={() => setModalOpen(false)} plantProtection={plant_protection} />
      </Modal>
      <Table tableToolbar={tableToolbar} articleId={articleId} />
    </>
  )
}

export default ArticlePurchases;
