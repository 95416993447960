import React, { useMemo, useState } from 'react';
import BaseTable from '../Table';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../Table/EditableTextInput';
import * as style from './index.module.css';
import Toastr from '../Toastr';
import {TrashSquareIcon} from '../Icons';

const PlannedArticlesTable = ({ tableToolbar, orderId, trigger, setTrigger }) => {
  const { t, i18n } = useTranslation();

  const getOrderArticles = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], order_id: orderId }

    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.orderArticles(), { params: params })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateOrderArticle = (id, data) => {
    axiosInstance.put(routes.orderArticle(id), { order_article: data })
      .then(resp => {
        Toastr({ message: t('order_article_update_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteOrderArticle = (id) => {
    axiosInstance.delete(routes.orderArticle(id))
      .then(resp => {
        Toastr({ message: t('order_article_delete_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    return (
      <div className={`${style['trash-button']}`} onClick={() => deleteOrderArticle(row.original.id)}>
        <TrashSquareIcon className="fs-2hx text-danger" />
      </div>
    )
  }

  const quantityHtml = (row) => {
    return (
      <EditableTextInput
        id={row.original.id}
        attribute='quantity'
        initialValue={row.original.attributes.quantity || "-"}
        handleSubmit={updateOrderArticle}
      />
    )
  }


  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: t('article'),
        accessorFn: row => row?.attributes?.article_name,
        enableSorting: true,
        cell: row => row?.getValue(),
      },
      {
        id: 'quantity',
        header: t("quantity"),
        enableSorting: true,
        size: 20,
        cell: ({ row }) => {
          return quantityHtml(row)
        },
      },
      {
        id: 'delete',
        header: null,
        size: 10,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      }
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#resource-planned-article" role="button">
          {t('planned_article')}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"/>
          </span>
        </div>
      </div>
      <div id="resource-planned-article" className="collapse show">
        <BaseTable
          keyName="planned_articles"
          getDataFn={getOrderArticles}
          columns={columns}
          tableToolbar={tableToolbar}
          trigger={trigger}
          sortBy={"identifier"}
          showSearchInput={false}
        />
      </div >
    </>
  )
}

export default PlannedArticlesTable;

