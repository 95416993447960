import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import Select from 'react-select'
import Toastr from '../Toastr';
import { Form, Field } from 'react-final-form'
import TableButton from '../Table/TableButton';
import QuestionMark from '../Icons/QuestionMark';
import { Tooltip } from 'bootstrap'
import * as style from './create.module.css';

const Create = ({ attr, onClose, setTrigger }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [useShortName, setUseShortName] = useState([]);
  const [locations, setlocations] = useState([]);

  useEffect(() => {
    getOptions();
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [onClose]);

  const getOptions = async () => {
    const res = await axiosInstance.get(routes.customizings());

    const parsedLocations = JSON.parse(res.data.locations).data.map(location => ({
      value: location.attributes.id,
      label: location.attributes.name
    }))

    setlocations(parsedLocations);
    setUseShortName(JSON.parse(res.data.use_short_name));
  }

  function onSubmit(values) {
    const data = {
      business_partner: {
        offical_name: values.officialName,
        name: values.shortName,
        location_id: values.locationId,
        company_code: values.companyCode,
        [attr]: true
      }
    };

    axiosInstance
      .post(
        routes.businessPartners(),
        data
      )
      .then(response => {
        Toastr({
          title: t('success'),
          message: response.data.message,
          options: { closeButton: true }
        })
        setTrigger && setTrigger(Math.floor(Date.now() / 1000))
        onClose();
      })
      .catch(error => {
        setErrors(error.response.data.error);
      })
  };

  const ReactSelectAdapter = ({ input, ...rest }) => {
    return <Select
      placeholder={t("select")}
      {...input}
      {...rest}
      searchable />
  }

  const invalidInput = (message) => {
    return (
      <div className="invalid-feedback">
        {message}
      </div>
    )
  };

  const shortNameCustomizing = (
    useShortName ? (
      <>
        <div className="row mb-3">
          <div className="col">
            <label>{t("official_name")}</label>
            <Field
              name="officialName"
              component="input"
              type="text"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label>{t("short_name")}</label>
            <span
              data-bs-toggle="tooltip"
              data-bs-custom-class="tooltip-inverse"
              data-bs-placement="top"
              title={t('nickname_used_message')}
              className={`${style['required-icon']} btn btn-sm p-1 mb-1`}
            >
              <QuestionMark />
            </span>
            <Field
              name="shortName"
              component="input"
              type="text"
              className={`form-control ${errors["name"] ? "is-invalid" : ""}`}
            />
            {errors["name"] ? invalidInput(errors["name"]) : null}
          </div>
        </div>
      </>
    ) : (
      <div className="row mb-3">
        <div className="col">
          <label className='required'>{t("short_name")}</label>
          <Field
            name="shortName"
            component="input"
            type="text"
            className={`form-control ${errors["name"] ? "is-invalid" : ""}`}
          />
          {errors["name"] ? invalidInput(errors["name"]) : null}
        </div>
      </div>
    )
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="container">
            {shortNameCustomizing}
            {attr === "company" && (
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t("abbreviation_for_number_range")}</label>
                  <Field
                    name="companyCode"
                    component="input"
                    type="text"
                    className={`form-control ${errors["company_code"] ? "is-invalid" : ""}`}
                  />
                  {errors["company_code"] ? invalidInput(errors["company_code"]) : null}
                </div>
              </div>
            )}
            <div className="row mb-3">
              <div className="col">
                <label>{t("locations")}</label>
                <Field
                  name="locations"
                  component={ReactSelectAdapter}
                  options={locations}
                />
              </div>
            </div>
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t("cancel")}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t("create")}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default Create;
