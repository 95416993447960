import React, {useEffect, useMemo, useState, useRef} from 'react';

import Select, {
    components,
} from 'react-select';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';
import {axiosInstance, routes} from "../../../utils/api_base";
import Map from "../../Map";
import {find, filter} from 'lodash';
import Button from "../../Button";
import classNames from 'classnames'
import Input from "../../Input";

function arrayMove(array, from, to) {
    const slicedArray = array.slice();
    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0]
    );
    return slicedArray;
}

const SortableMultiValue = SortableElement(
    (props) => {
        // this prevents the menu from being opened/closed when the user clicks
        // on a value to begin dragging it. ideally, detecting a click (instead of
        // a drag) would still focus the control and toggle the menu, but that
        // requires some magic with refs that are out of scope for this example
        const onMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const innerProps = {...props.innerProps, onMouseDown};
        return <components.MultiValue {...props} innerProps={innerProps}/>;
    }
);

const SortableMultiValueLabel = SortableHandle(
    (props) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select);

export default function FieldMultiSelect({businessPartnerRef, fieldsRef}) {
    const [fields, setFields] = useState([])
    const [selected, setSelected] = useState([])
    const [selectedIds, setSelectedIds] = useState([])
    const [drawing, setDrawing] = useState(false)
    const [polyType, setPolyType] = useState('polygon')
    const fieldRef = useRef({name: '', poly_points: [], business_partner_id: businessPartnerRef.current})

    const onFieldClick = (field) => {
        setSelected(prevState => {
            if (find(prevState, {value: field.id})) {
                return filter(prevState, {value: field.id})
            } else {
                return [...prevState, {value: field.id, label: field.name_with_hectare}]
            }
        })
        setSelectedIds(prevState => {
            if (find(prevState, field.id)) {
                return filter(prevState, field.id)
            } else {
                return [...prevState, field.id]
            }
        })
    }

    useEffect(() => {
        fieldsRef.current = selected
    }, [selected])

    const fieldData = useMemo(() => fields.map(field => ({value: field.id, label: field.name_with_hectare})), [fields])

    const getFields = async (pagination, businessPartnerId) => {
        const {pageIndex, pageSize} = pagination
        const params = {page: pageIndex + 1, limit: pageSize, business_partner_id: businessPartnerId}
        const res = await axiosInstance.get(
            routes.fields(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    useEffect(() => {
        if (!businessPartnerRef.current) return

        getFields({pageIndex: 0, pageSize: 10000}, businessPartnerRef.current).then(result => {
            setFields(result.rows.map(row => row.attributes))
        })
        setSelected([])
        setSelectedIds([])

        fieldRef.current.business_partner_id = businessPartnerRef.current
    }, [businessPartnerRef.current]);

    const onChange = (selectedOptions) => {
        setSelected(selectedOptions)
        setSelectedIds(selectedOptions.map(option => option.value))
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        const newValue = arrayMove(selected, oldIndex, newIndex);
        setSelected(newValue)
    };

    const onSaveDrawing = () => {
        axiosInstance.post(routes.fields(), fieldRef.current).then(console.log)
    }

    return (<>
            {!drawing && <SortableSelect
                useDragHandle
                // react-sortable-hoc props:
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                getHelperDimensions={({node}) => node.getBoundingClientRect()}
                // react-select props:
                isMulti
                options={fieldData}
                value={selected}
                onChange={onChange}
                components={{
                    // @ts-ignore We're failing to provide a required index prop to SortableElement
                    MultiValue: SortableMultiValue,
                    MultiValueLabel: SortableMultiValueLabel,
                }}
                closeMenuOnSelect={false}
            />}

            {!drawing && <Button onClick={() => setDrawing(true)}>+ Fläche zeichnen</Button>}
            {drawing && <Input placeholder={'Feld Name'} className={"form-control"} value={fieldRef.name}
                               onChange={(value) => fieldRef.current.name = value}/>}
            {drawing && <Button onClick={onSaveDrawing}>Speichern</Button>}
            {drawing && <div className="btn-group ms-3">
                {[{label: 'Fläche', value: 'polygon'}, {label: 'Linie', value: 'polyline'}].map(item => <label
                    className={classNames("btn btn-outline btn-active-primary btn-sm", item.value === polyType && "active")}>
                    <button className="btn-check" onClick={() => setPolyType(item.value)}/>
                    {item.label}
                </label>)}
            </div>}

            <Map selected={selectedIds} fields={fields} onClick={onFieldClick} businessPartnerRef={businessPartnerRef}
                 onUpdatePolygon={(polys) => fieldRef.current.poly_points = polys}
                 drawLineEnabled={polyType === 'polyline' && drawing}
                 drawPolygonEnabled={polyType === 'polygon' && drawing}/>
        </>
    );
}
