import React, { useMemo } from 'react';
import BaseTable from '../Table';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../Table/EditableTextInput';
import * as style from './index.module.css';
import Toastr from '../Toastr';
import {TrashSquareIcon} from '../Icons';

const CommentsTable = ({ tableToolbar, resourceId, trigger, setTrigger }) => {
  const { t, i18n } = useTranslation();
  const appStore = ReactOnRails.getStore("appStore");
  const { currentUser, currentCustomizing } = appStore.getState()?.appStore || {};

  const getComments = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], machine_id: resourceId }

    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.machineComments(), { params: params })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateComment = (id, data) => {
    axiosInstance.put(routes.machineComment(id), { comment: data })
      .then(resp => {
        Toastr({ message: t('comment_update_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteComment = (id) => {
    axiosInstance.delete(routes.machineComment(id))
      .then(resp => {
        Toastr({ message: t('comment_delete_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    if(row.original.attributes.staff_id !== currentUser.staff_id) {
      return <TrashSquareIcon className="fs-2hx text-muted" />
    }

    return (
      <div className={`${style['trash-button']}`} onClick={() => deleteComment(row.original.id)}>
        <TrashSquareIcon className="fs-2hx text-danger" />
      </div>
    )
  }

  const commentHtml = (row) => {
    if(row.original.attributes.staff_id === currentUser.staff_id) {
      return (
        <EditableTextInput
          id={row.original.id}
          attribute='comment'
          initialValue={row.original.attributes.comment || "-"}
          handleSubmit={updateComment}
        />
      )
    } else {
      return (
        <div>{row.original.attributes.comment}</div>
      )
    }
  }


  const columns = useMemo(
    () => [
      {
        id: 'author',
        header: t('author'),
        size: 60,
        accessorFn: row => row?.attributes?.author_name,
        enableSorting: true,
        cell: row => row?.getValue(),
      },
      {
        id: 'comment',
        header: t("comment"),
        enableSorting: true,
        cell: ({ row }) => {
          return commentHtml(row)
        },
      },
      {
        id: 'date',
        header: t('date'),
        size: 40,
        accessorFn: row => row?.attributes?.created_at,
        enableSorting: true,
        cell: row => row?.getValue(),
      },
      {
        id: 'delete',
        header: null,
        size: 10,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      }
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#resource-comments" role="button">
          {t('comments')}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"/>
          </span>
        </div>
      </div>
      <div className="separator my-3"/>
      <div id="resource-comments" className="collapse show">
        <BaseTable
          keyName="comments"
          getDataFn={getComments}
          columns={columns}
          tableToolbar={tableToolbar}
          trigger={trigger}
          sortBy={"date"}
        />
      </div >
    </>
  )
}

export default CommentsTable;

