import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'
import { debounce } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import AsyncSelectAdapter from '../../components/Form/AsyncSelectAdapter';
import TableButton from '../../components/Table/TableButton';
import Toastr from '../../components/Toastr';
import JobProgressBar from '../../components/JobProgressBar';

const ImportPricesForm = ({ businessPartnerId }) => {
  const { t } = useTranslation();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [jobId, setJobId] = useState();

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadSources = createLoadOptions((inputText) => {
    const params = {
      page: 1, limit: 20, query: inputText, active: true, serialize: "short",
      with_service_features: true, id_to_exclude: businessPartnerId
    }

    return axiosInstance.get(routes.businessPartners(), { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.long_name,
      })))
      .catch(error => {
        console.log("ERROR", error);
        return [];
      });;
  });

  const onSubmit = async values => {
    const data = {
      source_id: values.source?.value,
      business_partner_id: businessPartnerId
    };

    return axiosInstance.post(routes.cloneBusinessPartnerServiceFeatures(), data)
      .then(response => {
        Toastr({
          title: t("shared.success"),
          message: response.data.message,
          options: { closeButton: true }
        })
        setJobId(response.data.job_id)
        setShowProgressBar(true)
      }).catch(error => {
        Toastr({
          type: "error",
          message: error.response.data.message,
          options: { closeButton: true }
        })
        setShowProgressBar(false)
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        source: ""
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="pt-3">
          <label>{t("business_partners.choose_customer")}</label>
          <div className="d-flex align-items-center">
            <Field
              name="source"
              component={AsyncSelectAdapter}
              loadOptions={loadSources}
              placeholder={t("select")}
              isClearable={true}
              className="min-w-300px"
            />
            <TableButton
              className="btn btn-primary fw-semibold px-6 ms-3"
              type="submit"
              title={t("business_partners.import_prices_for_services")}
              disabled={submitting || pristine}
            />
          </div>
          {showProgressBar && <JobProgressBar jobId={jobId} />}
        </form>
      )}
    />
  )
}

export default ImportPricesForm;
