import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'
import { debounce } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import AsyncSelectAdapter from '../../components/Form/AsyncSelectAdapter';
import TableButton from '../../components/Table/TableButton';
import Toastr from '../../components/Toastr';

const DefinePriceForm = ({ businessPartnerId }) => {
  const { t } = useTranslation();

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadServices = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, query: inputText, active: true, serialize: "short" }

    return axiosInstance.get(routes.services(), { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.long_name,
      })))
      .catch(error => {
        console.log("ERROR", error);
        return [];
      });;
  });

  const onSubmit = async values => {
    const data = {
      service: values.service?.value
    };

    return axiosInstance.post(routes.addServiceFeatures(businessPartnerId), data)
      .then(response => {
        Toastr({
          title: t("shared.success"),
          message: response.data.message,
          options: { closeButton: true }
        })
        window.location.reload()
        onClose()
      }).catch(error => {
        Toastr({
          type: "error",
          message: error.response.data.message,
          options: { closeButton: true }
        })
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        service: ""
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <label>{t("service_features.choose_service")}</label>
          <div className="d-flex align-items-center">
            <Field
              name="service"
              component={AsyncSelectAdapter}
              loadOptions={loadServices}
              placeholder={t("select")}
              isClearable={true}
              className="min-w-300px"
            />
            <TableButton
              className="btn btn-primary fw-semibold px-6 ms-3"
              type="submit"
              title={t("service_features.define_prices_for_service")}
              disabled={submitting || pristine}
            />
          </div>
        </form>
      )}
    />
  )
}

export default DefinePriceForm;
