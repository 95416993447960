import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { CheckSquareIcon, CrossSquareIcon, EditNotepadIcon } from '../Icons';
import * as style from './index.module.css';
import classNames from 'classnames';

const EditableTextInput = ({ id, attribute, initialValue, handleSubmit, placeholder, className = ""}) => {
  const [editable, setEditable] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const onSubmit = async values => {
    setEditable(false)
    await handleSubmit(id, values)
  }

  const inputHtml = () => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ [attribute]: initialValue }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={style.form}>
              <Field
                name={attribute}
                component="input"
                type="text"
                placeholder={placeholder || attribute}
              />
              <div className={style['form-buttons']}>
                <button type="submit" disabled={submitting || pristine}>
                  <CheckSquareIcon className={classNames("fs-3 text-success", { "text-gray-500": submitting || pristine })} />
                </button>
                <button
                  type="button"
                  onClick={() => setEditable(false)}
                  disabled={submitting}
                >
                  <CrossSquareIcon className="fs-3 text-danger" />
                </button>
              </div>
            </div>
          </form>
        )}
      />
    )
  }

  const viewHtml = () => {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setEditable(true)}
        className={className}
      >
        {initialValue}
        {isHovered && <EditNotepadIcon />}
      </div>
    )
  }
  return (
    <>
      {editable ? inputHtml() : viewHtml()}
    </>
  )
}

export default EditableTextInput
