import React from 'react';
import StatusProgress from '../StatusProgress';
import { map } from 'lodash';

const Overview = ({order, updateOrder}) => {
  const avaialbleStatuses = map((order.statuses?.data || []), (status) => status.attributes )

  return (
    <div>
      <StatusProgress orderId={order.id} statusId={order.status_id} statuses={avaialbleStatuses} callback={updateOrder}/>
      <h1>Overview</h1>
    </div>
  );
};

export default Overview;
