import React, { useMemo, useState, useEffect} from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import EditableTextInput from '../../components/Table/EditableTextInput';
import Toastr from '../../components/Toastr';
import { TrashSquareIcon } from '../../components/Icons';
import Form from 'react-bootstrap/Form';
import EditableDatePickerInput from '../../components/Table/EditableDatePickerInput';

const Table = ({ tableToolbar, businessPartnerId, trigger, activeTab, treaty, storeParams}) => {
  const { t, i18n } = useTranslation()
  const [baseTrigger, setBaseTrigger] = useState('')

  const getDocuments = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], tab: activeTab }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.documentOfBusinessPartners(), { params: { ...params, business_parnter_id: businessPartnerId, treaty: treaty } })
    storeParams && pushToHistory({tab: activeTab})

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateDocument = (id, params) => {
    axiosInstance.put(routes.documentOfBusinessPartner(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setBaseTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteCombination = (id) => {
    axiosInstance.delete(routes.documentOfBusinessPartner(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setBaseTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const activeHtml = (row) => {
    const isActive = row.original.attributes?.no_renewal
    const label = isActive ? t('expires') : t('is_automatically_renewed')

    return (
      <Form.Check
        onChange={() => updateDocument(row.original.id, { no_renewal: !isActive })}
        checked={!isActive}
        type="checkbox"
        className='form-check-custom'
        id="document-of-business_partner-no-renewal-switch"
        label={label}
      />
    )
  }

  const deleteHtml = (row) => {
    return (
      <div className='cursor-pointer' onClick={() => deleteCombination(row.original.id)}><TrashSquareIcon className="fs-2hx text-danger" /></div>
    )
  }

  const nameHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.download_link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.document_file_name}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'contract',
        header: t('contract'),
        enableSorting: true,
        size: 70,
        minSize: 70,
        accessorFn: row => row?.attributes?.name,
        cell: ({ row }) => {
          return nameHtml(row)
        },
      },
      {
        id: 'comment',
        header: t('shared.comment'),
        accessorFn: row => row?.attributes?.comment,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='comment' placeholder={'Type here'} initialValue={row.original.attributes.comment} handleSubmit={updateDocument} />
      },
      {
        id: 'valid_from',
        header: t('valid_from'),
        minSize: 100,
        accessorFn: row => row?.attributes?.start_date,
        cell: ({ row }) => <EditableDatePickerInput
          id={row.original.id}
          attribute='start_date'
          initialValue={row.original.attributes.start_date || '- - - - -'}
          handleSubmit={updateDocument}
        />
      },
      {
        id: 'valid_until',
        header: t('valid_until'),
        accessorFn: row => row?.attributes?.end_date,
        cell: ({ row }) => <EditableDatePickerInput
          id={row.original.id}
          attribute='end_date'
          initialValue={row.original.attributes.end_date || '- - - - -'}
          handleSubmit={updateDocument}
        />

      },
      {
        id: 'cancellation_date',
        header: t('to_cancel_until'),
        accessorFn: row => row?.attributes?.cancellation_date,
        cell: ({ row }) => <EditableDatePickerInput
          id={row.original.id}
          attribute='cancellation_date'
          initialValue={row.original.attributes.cancellation_date || '- - - - -'}
          handleSubmit={updateDocument}
        />
      },
      {
        id: 'automatic_renewal',
        header: t('automatic_renewal'),
        accessorFn: row => row?.attributes?.no_renewal,
        cell: ({ row }) => {
          return activeHtml(row)
        },
      },
      {
        id: 'created_at',
        header: t('creation_date'),
        accessorFn: row => row?.attributes?.created_at,
        cell: row => row.getValue(),

      },
      {
        id: 'delete',
        header: null,
        size: 50,
        minSize: 50,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getDocuments}
      tableToolbar={tableToolbar}
      initialSortDirection={'asc'}
      trigger={baseTrigger + trigger}
    />
  )
}

export default Table;
