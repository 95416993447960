import React from 'react'
import AsyncSelect from 'react-select/async';

const AsyncSelectAdapter = ({ input, ...rest }) => {
  return (
    <AsyncSelect
      {...input}
      cacheOptions
      defaultOptions
      {...rest}
    />
  )
}

export default AsyncSelectAdapter
