import React, { useState } from 'react';
import Table from './Table';
import DatePicker from '../../components/DatePicker';
import TableButton from '../../components/Table/TableButton';
import classNames from 'classnames'
import { useTranslation } from 'react-i18next';

const ChroniclesStaffPerDay = ({ staffId, staffName, filters }) => {
  const { t, i18n } = useTranslation()
  const urlParams = new URLSearchParams(window.location.search);
  const [selectedDate, setSelectedDate] = useState(urlParams.get('day') || null);
  const [datePickerKey, setDatePickerKey] = useState(0);
  const [staffNameTitle, setStaffNameTitle] = useState(staffName);

  const onClickAllDates = () => {
    setSelectedDate(null)
    setDatePickerKey(prevKey => prevKey + 1)
  };

  const onChangeDatePicker = (_, dateStr) => {
    setSelectedDate(dateStr)
  };

  const handleApplyFilter = (params) => {
    const foundStaff = filters[0].options.find(item => Number(item[0]) == params.staff)
    setStaffNameTitle(foundStaff[1])
  };

  const tableToolbar = () => (
    <>
      <DatePicker
        key={datePickerKey}
        placeholder={t('select_day')}
        options={{
          onChange: onChangeDatePicker,
          defaultDate: selectedDate,
          dateFormat: i18n.language === "de" ? 'Y.m.d' : 'Y-m-d'
        }}
      />
      {selectedDate &&
        <TableButton
          type="button"
          className={classNames("btn-light-primary px-0 ms-3 w-100", !selectedDate ? 'disabled' : '')}
          onClick={onClickAllDates}
          title={t('all_days')}
        />}
    </>
  );

  return (
    <>
      <div className="mb-5 border-0 align-items-center">
        <span className='fs-2'>
          {staffNameTitle}
          {selectedDate && ` ${t('chronicle_of')} ${selectedDate}`}
        </span>
      </div>
      <Table tableToolbar={tableToolbar} staffId={staffId} filters={filters} day={selectedDate} onApplyFilter={handleApplyFilter} />
    </>
  );
};

export default ChroniclesStaffPerDay;
