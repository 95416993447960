import React from "react";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../ToggleSwitch";

const StaffToggleInput = ({ active, translation, handleToggle, attribute, staffId }) => {
  const { t } = useTranslation()
  const label = () => {
    if (attribute === "payroll_not_needed") {
      return active ? t('fixed_salary') : t('hourly_salary')
    } else {
      return active ? t('yes') : t('no')
    }
  };

  return (
    <>
      <div className="align-items-center">
        <div className="fw-bold mb-4 me-5">{translation}</div>
        <div className="text-gray-600">
          <ToggleSwitch
            id={staffId}
            handleToggle={handleToggle}
            label={label()}
            attribute={attribute}
            active={active}
          />
        </div>
      </div>
      <span className="separator my-5"></span>
    </>
  );
}

export default StaffToggleInput;
