import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import {PlusIcon} from '../Icons';
import Modal from '../Modal';
import PlannedArticlesTable from './PlanedArticlesTable';
import CreatePlannedArticle from './CreatePlannedArticle';
import CreateConsumedArticle from './CreateConsumedArticle';
import ConsumedArticlesTable from './ConsumedArticlesTable';

const Article = ({order}) => {
  const { t } = useTranslation()
  const [planedArticleModalOpen, setPlanedArticleModalOpen] = useState(false)
  const [planedArticleTrigger, setPlanedArticleTrigger] = useState(false)
  const [articleConsumptionModalOpen, setArticleConsumptionModalOpen] = useState(false)
  const [articleConsumptionTrigger, setArticleConsumptionTrigger] = useState(false)

  const plannedArticleTableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setPlanedArticleModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }

  const articleConsumptionTableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setArticleConsumptionModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }


  return (
    <div className="d-flex flex-row gap-5 align-items-start">
      <div className="flex-grow-1 card pt-4 mb-6 mb-xl-9">
        <Modal className="modal-lg" open={planedArticleModalOpen} onClose={() => setPlanedArticleModalOpen(false)} title={t('planned_article')}>
          <CreatePlannedArticle onClose={() => setPlanedArticleModalOpen(false)} setTrigger={setPlanedArticleTrigger} orderId={order.id} />
        </Modal>
        <PlannedArticlesTable orderId={order.id} tableToolbar={plannedArticleTableToolbar} setTrigger={setPlanedArticleTrigger} trigger={planedArticleTrigger} />
      </div>
      <div className="flex-grow-1 card pt-4 mb-6 mb-xl-9">
        <Modal className="modal-lg" open={articleConsumptionModalOpen} onClose={() => setArticleConsumptionModalOpen(false)} title={t('planned_article')}>
          <CreateConsumedArticle onClose={() => setArticleConsumptionModalOpen(false)} setTrigger={setArticleConsumptionTrigger} orderId={order.id} />
        </Modal>
        <ConsumedArticlesTable orderId={order.id} tableToolbar={articleConsumptionTableToolbar} setTrigger={setArticleConsumptionTrigger} trigger={articleConsumptionTrigger} />
      </div>
    </div>
  );
};

export default Article;
