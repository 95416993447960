import React from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { Form, Field } from 'react-final-form'
import ErrorField from '../Form/ErrorField';
import {axiosInstance, routes} from '../../utils/api_base';
import Toastr from '../Toastr';
import {mapValues} from 'lodash';
import { FORM_ERROR } from 'final-form'
import {composeValidators, isRequired, minLength} from '../Form/validations';

const CreateComment = ({onClose, setTrigger, resourceId }) => {
  const { t } = useTranslation();
  const appStore = ReactOnRails.getStore("appStore");
  const { currentUser, currentCustomizing } = appStore.getState()?.appStore || {};

  const onSubmit = async values => {
    const data = {
      machine_id: resourceId,
      staff_id: currentUser.staff_id,
      comment: values.comment,
    };

    axiosInstance.post(routes.machineComments(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: "message here",
          options: { closeButton: true }
        })

        onClose()
        setTrigger(true)
      })
      .catch(error => {
        const errorData = error.response.data

        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        let errors = {}
        errors.comment = composeValidators(isRequired, minLength(3))(values.comment)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('comment')}</label>
                <Field
                  name="comment"
                  component="input"
                  type="text"
                  className="form-control"
                  autoFocus
                />
                <ErrorField name="comment" />
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </form>
        </>
      )}
    />
  );
};

export default CreateComment;
