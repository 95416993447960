import React from 'react'

import StaffOrderForm from "./Form";
import ShowStaffOrder from "./show";

const StaffOrder = ({staffOrder = null, staffOrdersRef}) => {
    const [onEdit, setOnEdit] = React.useState(true)

    const selectedStaffOrder = staffOrdersRef.current.find(item => item.id === staffOrder.id)

    return onEdit ? <StaffOrderForm staffOrder={selectedStaffOrder} staffOrdersRef={staffOrdersRef}
                                    onSave={() => setOnEdit(false)}/> :
        <ShowStaffOrder staffOrder={selectedStaffOrder}
                        onEdit={() => setOnEdit(true)}/>
}

export default StaffOrder
