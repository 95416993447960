import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import Toastr from '../../Toastr';
import { axiosInstance, routes } from '../../../utils/api_base';
import { Form, Field } from 'react-final-form'
import TableButton from '../../Table/TableButton';
import ErrorField from '../../Form/ErrorField';
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'

const Create = ({ onClose, setTrigger, currentStaffId, businessPartnerId }) => {
  const { t } = useTranslation();
  const areaOptions = [{ id: 'comment_invoice', text: t('comment_invoice') }, { id: 'comment_order', text: t('comment_order') }]

  const onSubmit = async values => {
    const data = {
      business_partner_comment: {
        comment_area: values.comment_area,
        comment: values.comment,
        display_comment: values.display_comment,
        staff_id: currentStaffId,
        business_partner_id: businessPartnerId,
      }
    }

    axiosInstance.post(routes.businessPartnerComments(), data)
      .then(response => {
        Toastr({
          title: t('success'),
          message: response.data.message,
          options: { closeButton: true }
        })
        onClose()
        setTrigger(true)
      })
      .catch(error => {
        const errorData = error.response.data
        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        comment_area: '',
        comment: '',
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('comment')}</label>
                <Field
                  name="comment"
                  component="input"
                  placeholder={t('comment')}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('comment_area')}</label>
                <Field
                  name="comment_area"
                  render={({ input }) => (
                    <Select
                      options={areaOptions}
                      placeholder={t('comment_area')}
                      onChange={input.onChange}
                      allowBlank
                    />
                  )}
                />
                <ErrorField name="comment_area" />
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <Field
                  name="display_comment"
                  component={Checkbox}
                  type="text"
                  size="sm"
                  className="form-control"
                  label={t('display_comment')}
                />
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </form>
        </>
      )}
    />
  )
}

export default Create;
