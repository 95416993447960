import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import Select from 'react-select';
import Toastr from '../Toastr';
import { Form, Field } from 'react-final-form'
import DatePicker from '../DatePicker';
import { isRequired, composeValidators } from '../Form/validations';
import ErrorField from '../Form/ErrorField';
import { mapValues } from 'lodash';
import TableButton from '../Table/TableButton';
import { FORM_ERROR } from 'final-form'

const Create = ({ onClose, businessPartnerId, setTrigger}) => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    getActiveCustomers();
  }, []);

  async function getActiveCustomers() {
    const res = await axiosInstance.get(routes.businessPartners(), { active: true });

    const parsedCustomers = res?.data.data.map(customer => ({
      value: customer.attributes.id,
      label: customer.attributes.long_name
    }))

    setCustomers(parsedCustomers);
  };

  function handleSubmit(values) {
    const date = new Date(values.date);

    const data = {
      offer: {
        business_partner_id: businessPartnerId || values.businessPartnerId?.value,
        date: date,
      }
    };

    axiosInstance
      .post(
        routes.offers(),
        data
      )
      .then(response => {
        Toastr({
          title: `${t('success')}!`,
          message: response.data.message,
          options: { closeButton: true }
        })
        setTrigger && setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
        onClose();
      })
      .catch(error => {
        const errorData = error.response.data
        if (errorData.error) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  };

  const ReactSelectAdapter = ({ input, ...rest }) => {
    return <Select
      placeholder={t("please_select")}
      {...input}
      {...rest}
      searchable />
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={values => {
        let errors = {}
        errors.businessPartnerId = isRequired(businessPartnerId || values?.businessPartnerId)
        errors.date = composeValidators(isRequired)(values.date)

        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="container">
            {!businessPartnerId &&
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('customer')}</label>
                  <Field
                    name="businessPartnerId"
                    component={ReactSelectAdapter}
                    options={customers}
                  >
                  </Field>
                  <ErrorField name="businessPartnerId" />
                </div>
              </div>
            }
            <div className="row mb-3">
              <div className="col">
                <label className='required'>{t('date')}</label>
                <Field
                  name="date"
                  render={({ input }) => (
                    <DatePicker
                      options={{
                        onChange: input.onChange
                      }}
                    />
                  )}
                >
                </Field>
                <ErrorField name="date" />
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                disabled={submitting || pristine}
                title={t('create')}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default Create;
