import Immutable from 'immutable';
import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import loggerMiddleware from '../utils/loggerMiddleware';
import reducers, { initialStates } from '../reducers';

export default (props, railsContext) => {
  const initialCurrentUser = props.current_user || {};
  const initialCustomizing = props.current_customizing || {};
  const initialStatuses = props.statuses || [];
  const { appState } = initialStates;

  const initialState = {
    appStore: {...appState, currentUser: initialCurrentUser, currentCustomizing: initialCustomizing, statuses: initialStatuses},
    railsContext,
  };

  const reducer = combineReducers(reducers);
  const composedStore = compose(applyMiddleware(thunk, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
