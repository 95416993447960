import BaseTable from '../../../components/Table';
import DOMPurify from 'dompurify';
import React, { useState, useMemo } from 'react'
import TableButton from '../../Table/TableButton';
import Toastr from '../../Toastr';
import { axiosInstance, routes } from '../../../utils/api_base';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const OrderPlantWasteTable = ({businessPartnerId}) => {
  const { t, i18n} = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [trigger, setTrigger] = useState(false)

  const getData = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: 3, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.orderPlantWastes(), { params: { ...params, business_partner_id: businessPartnerId } })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const htmlSafe = (value) => {
    const sanitizedHtml = DOMPurify.sanitize(value);

    return (
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    )
  }

  const idHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.id}
      />
    )
  }

  const orderHtml = (row) => {
    return (
      <a href={row?.original?.attributes?.order_link}>{row?.original?.attributes?.order_name}</a>
    )
  }

  const updateOrderPlantWaste = (id, params) => {
    axiosInstance.put(routes.orderPlantWaste(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const giverHtml = (row) => {
    return (
      <a href={row?.original?.attributes?.giver_link}>{row?.original?.attributes?.giver_long_name}</a>
    )
  }

  const signedHtml = (row) => {
    return (
      row?.original?.attributes?.signed ?
        <span>{t('yes')}</span>
        :
        <TableButton
          target="_blank"
          onClick={() => updateOrderPlantWaste(row.original.id, { signed: true })}
          className="btn-light-primary me-3"
          title={t('mark_now')}
        />
    )
  }

  const takerHtml = (row) => {
    return (
      <a href={row?.original?.attributes?.taker_link}>{row?.original?.attributes?.taker_long_name}</a>
    )
  }

  const tableToolbar = () => {
    return (
      <></>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'id',
        header: t('shared.delivery_slip'),
        size: 70,
        minSize: 70,
        enableSorting: true,
        accessorFn: row => row?.attributes?.id,
        cell: ({ row }) => idHtml(row),
      },
      {
        id: 'order',
        accessorFn: row => row?.attributes?.order_name,
        header: t('order'),
        cell: ({ row }) => orderHtml(row),
      },
      {
        id: 'dispenser',
        accessorFn: row => row?.attributes?.giver_long_name,
        header: t('dispenser'),
        cell: ({ row }) => giverHtml(row),

      },
      {
        id: 'recipient',
        accessorFn: row => row?.attributes?.taker_long_name,
        header: t('recipient'),
        cell: ({ row }) => takerHtml(row),
      },
      {
        id: 'date_from',
        accessorFn: row => row?.attributes?.give_date_from,
        header: t('shared.from'),
        cell: row => row.getValue(),
      },
      {
        id: 'date_to',
        accessorFn: row => row?.attributes?.give_date_to,
        header: t('shared.until'),
        cell: row => row.getValue(),
      },
      {
        id: 'quantity',
        accessorFn: row => row?.attributes?.quantity_str,
        header: t('quantity'),
        cell: row => htmlSafe(row.getValue()),
      },
      {
        id: 'signed',
        accessorFn: row => row?.attributes?.signed,
        header: t('signed'),
        cell: ({ row }) => signedHtml(row),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getData}
      tableToolbar={tableToolbar}
      tableName={'OrderPlantWasteTable'}
      trigger={trigger}
    />
  )
}

export default OrderPlantWasteTable
