import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import { PlusIcon } from '../../components/Icons';
import Table from './Table';
import { axiosInstance, routes } from '../../utils/api_base';
import Toastr from '../../components/Toastr';

const DocumentOfBusinessPartners = ({ business_partner_id, activeTab, storeParams, treaty }) => {
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const newFile = event.target.files[0]
    const formData = new FormData();
    formData.append('document_of_business_partner[document]', newFile);
    formData.append('document_of_business_partner[business_partner_id]', business_partner_id);
    formData.append('document_of_business_partner[treaty]', treaty);
    axiosInstance.post(routes.documentOfBusinessPartners(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(resp => {
      Toastr({ message: t('success'), options: { showDuration: 2000 } });
      setTrigger(Math.floor(Date.now() / 1000));
    })
    .catch(error => {
      Toastr({
        type: 'error',
        message: error.response?.data?.error || t('critical_error'),
      });
    });
  };

  const tableToolbar = () => {
    return (
      <>
        <TableButton
          className="btn-primary"
          onClick={handleButtonClick}
          icon={<PlusIcon />}
          title={t('shared.add_to')}
        />
        <input
          type="file"
          ref={fileInputRef}
          className='d-none'
          onChange={handleFileUpload}
        />
      </>
    );
  };

  return (
    <Table tableToolbar={tableToolbar} businessPartnerId={business_partner_id} trigger={trigger} activeTab={activeTab} storeParams={storeParams} treaty={treaty}/>
  );
};

export default DocumentOfBusinessPartners;
