import React, { useMemo, useState } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import Toastr from '../../components/Toastr';
import EditableDatePickerInput from '../../components/Table/EditableDatePickerInput';
import EditableSelectInput from '../../components/Table/EditableSelectInput';
import EditableTextInput from '../../components/Table/EditableTextInput';

const Table = ({ filters, ressource, tableToolbar }) => {
  const { t, i18n } = useTranslation()
  const [trigger, setTrigger] = useState(false)

  const getRessourceLocationHistories = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map(attr =>  attr.desc ? `${attr.id} desc` : attr.id)
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], ressource: ressource }
    if (globalFilter) params.query = globalFilter
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.ressourceLocationHistoriesRessource(), { params: params })
    pushToHistory(params)

    return { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
  }

  const getLocations = (inputText) => {
    let params = { page: 1, limit: 20, query: inputText }

    return axiosInstance.get(routes.locations(), { params: params })
      .then((response) => {
        return response.data.data.map((location) => ({
          value: location.id,
          label: location.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const updateRessourceLocationHistory = (id, params) => {
    axiosInstance.patch(routes.ressourceLocationHistory(id), params)
      .then(resp => {
        Toastr({message: t('update_success')})
        setTrigger(Math.floor(Date.now() / 1000)) // FIXME: Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const columns = useMemo(
    () => [
      {
        id: 'formatted_start_date',
        header: t('start_date'),
        accessorFn: row => row?.attributes?.formatted_start_date,
        enableSorting: true,
        cell: ({ row }) => <EditableDatePickerInput
          id={row.original.id}
          attribute='start_date'
          initialValue={row.original.attributes.formatted_start_date}
          handleSubmit={updateRessourceLocationHistory}
        />
      },
      {
        id: 'location_name',
        header: t('shared.location'),
        accessorFn: row => row?.attributes?.location_id,
        enableSorting: false,
        cell: ({ row }) => {
          const locationInitialValue = {
            value: row.original.attributes?.location_id,
            label: row.original.attributes?.location_name
          }
          return <EditableSelectInput
            id={row.original.id}
            attribute='location_id'
            initialValue={locationInitialValue}
            handleSubmit={updateRessourceLocationHistory}
            getEntities={getLocations}
          />
        }
      },
      {
        id: 'comment',
        header: t('shared.comment'),
        accessorFn: row => row?.attributes?.comment,
        enableSorting: true,
        cell: ({ row }) => <EditableTextInput
          id={row.original.id}
          attribute='comment'
          initialValue={row.original.attributes.comment}
          handleSubmit={updateRessourceLocationHistory}
        />
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getRessourceLocationHistories}
      initialSortDirection="asc"
      tableToolbar={tableToolbar}
      filters={filters}
      showSearchInput={false}
      trigger={trigger}
    />
  )
}

export default Table;
