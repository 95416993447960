import React, { useEffect, useCallback } from 'react';
import moment from "moment";
import { debounce, mapValues } from 'lodash';
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { useTranslation } from 'react-i18next';

import { axiosInstance, routes } from '../../utils/api_base';
import AsyncSelectAdapter from '../../components/Form/AsyncSelectAdapter';
import DatePicker from '../../components/DatePicker';
import ErrorField from '../../components/Form/ErrorField';
import TableButton from '../../components/Table/TableButton';
import Toastr from '../../components/Toastr';
import { isRequired, composeValidators } from '../../components/Form/validations';

const Create = ({ onClose, ressource }) => {
  const currentDate = moment().toDate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  const onSubmit = async values => {
    const data = {
      ressource_location_history: {
        start_date: moment.parseZone(values.startDate[0]).format('YYYY-MM-DD'),
        comment: values.comment,
        location_id: values.locationId?.value,
        ressource_id: ressource
      }
    };

    return axiosInstance.post(routes.ressourceLocationHistories(), data)
      .then(response => {
        Toastr({
          title: t('shared.success'),
          message: response.data.message,
          options: { closeButton: true }
        })

        if (response.data.redirect_url) window.location.href = response.data.redirect_url;
        onClose()
      }).catch(error => {
        const errorData = error.response.data

        if (errorData.error) {
          console.log(errorData.error)
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const fetchData = (url, params) => {
    return axiosInstance.get(url, { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.name,
      })))
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadLocations = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, query: inputText };

    return fetchData(routes.locations(), params);
  });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        startDate: currentDate,
        locationId: '',
        comment: ''
      }}
      validate={values => ({locationId: composeValidators(isRequired)(values.locationId)})}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <label>{t('start_date')}</label>
                <Field
                  name="startDate"
                  render={({ input }) => (
                    <DatePicker
                      options={{
                        defaultDate: currentDate,
                        dateFormat: (i18n.language === "de" ? 'd.m.Y' : 'd-m-Y'),
                        onChange: input.onChange
                      }}
                    />
                  )}
                />
                <ErrorField name="startDate" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label className='required'>{t('shared.location')}</label>
                <Field
                  name='locationId'
                  component={AsyncSelectAdapter}
                  loadOptions={loadLocations}
                  placeholder="Select"
                  isClearable={true}
                >
                </Field>
                <ErrorField name="locationId" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>{t('shared.comment')}</label>
                <Field
                  name="comment"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
          </div>
          <div className="modal-footer">
            <TableButton
              className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
              type="button"
              onClick={onClose}
              title={t('cancel')}
            />
            <TableButton
              className="btn btn-primary fw-semibold px-6"
              type="submit"
              title={t('create')}
              disabled={submitting || pristine}
            />
          </div>
        </form>
      )}
    />
  )
}

export default Create;
