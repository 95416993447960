import React, {useCallback, useRef, useState, useEffect} from 'react'
import {axiosInstance, routes} from "../../utils/api_base";
import {useTranslation} from 'react-i18next';
import {Form, Field, FormSpy} from 'react-final-form'
import ErrorField from '../Form/ErrorField';
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';
import TableButton from '../Table/TableButton';
import debounce from 'lodash/debounce';
import FieldMultiSelect from "./Form/FieldMultiSelect";
import StaffOrders from "./StaffOrders";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DateRangePickerAdapter from "../Form/DateRangePickerAdapter";
import {isRequired} from '../Form/validations';
import {isEmpty} from 'lodash';
import moment from 'moment';
import {NavArrowRight, NavArrowLeft} from '../Icons';
import ModalContent from "../Modal/ModalContent";

const CreateOrderModal = ({
                              onClose, time = moment(),
                              orderId = undefined,
                              onCreate = (order) => {
                              },
                              staff = undefined
                          }) => {
    const {t, i18n} = useTranslation()
    const businessPartnerRef = useRef(null)
    const orderRef = useRef({})
    const [activeTab, setActiveTab] = useState('fields')
    const fieldsRef = useRef([]);
    const staffOrdersRef = useRef([])


    useEffect(() => {
        if (!staff) return

        staffOrdersRef.current = [{
            id: 1,
            staff: {value: staff.id, label: staff.attributes.long_name},
            period: {from: moment(time), to: moment(time).add(3, "hours")}
        }]
    }, []);

    const getServices = (inputText) => {
        let params = {page: 1, limit: 20, active: true, serialize: 'short', query: inputText}
        return axiosInstance.get(routes.services(), {params: params})
            .then((response) => {
                return response.data.data.map((service) => ({
                    value: service.id,
                    label: service.attributes.name,
                }));
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
    };

    const getBusinessPartners = (inputText) => {
        let params = {page: 1, limit: 20, active: true, query: inputText}

        return axiosInstance.get(routes.businessPartners(), {params: params})
            .then((response) => {
                return response.data.data.map((bp) => ({
                    value: bp.id,
                    label: bp.attributes.long_name,
                }));
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
    };

    const fetchData = (url, params) => {
        return axiosInstance.get(url, {params})
            .then(response => response.data.data.map(item => ({
                value: item.id,
                label: item.attributes.name,
            })))
            .catch(error => {
                console.log('ERROR', error);
                return [];
            });
    };

    const createLoadOptions = (fetchFunction) =>
        useCallback(
            debounce((inputText, callback) => {
                fetchFunction(inputText).then(options => callback(options));
            }, 1000),
            []
        );

    const loadBPOptions = useCallback(
        debounce((inputText, callback) => {
            getBusinessPartners(inputText).then((options) => callback(options));
        }, 1000), [])

    const loadServiceOptions = useCallback(
        debounce((inputText, callback) => {
            getServices(inputText).then((options) => callback(options));
        }, 1000), [])

    const loadUnitOptions = createLoadOptions((inputText) => {
        const params = {page: 1, limit: 20, sort: 'name', query: inputText};
        return fetchData(routes.units(), params);
    });

    const onSubmit = async values => {
        const params = {
            business_partner_id: values.business_partner.value,
            comment: values.comment,
            quantity_ordered: values.quantity,
            service_ids: values.services.map(service => service.value),
            field_ids: fieldsRef.current.map(field => field.value),
            quantity_ordered_unit_id: values.unitId.value,
            start_time: values.period.from.toISOString(),
            end_time: values.period.to.toISOString(),
            staff_orders: staffOrdersRef.current.map(staffOrder => ({
                combination_id: staffOrder.combination.value,
                geraet1_id: staffOrder.geraet1_id?.value,
                geraet2_id: staffOrder.geraet2_id?.value,
                fahrzeug_id: staffOrder.machine.value,
                start_time: staffOrder.period.from.toISOString(),
                end_time: staffOrder.period.to.toISOString(),
                service_id: staffOrder.serviceId.value,
                staff_id: staffOrder.staff.value
            }))
        }

        axiosInstance.post(routes.dispositions(), params).then((response) => {
            onCreate(response.data.data)
        })
    }

    const initialValues = {
        ...orderRef.current,
        period: orderRef.current.period || {
            from: moment(time),
            to: moment(time).clone().add(1, "day")
        }
    }

    return (
        <ModalContent>
            <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true">
                <Form
                    onSubmit={onSubmit}
                    initialValues={{}}
                    validate={values => {
                        let errors = {}
                        errors.business_partner = isRequired(values.business_partner)
                        errors.services = isRequired(values.services)
                        errors.unitId = isRequired(values.unitId)
                        errors.quantity = isRequired(values.quantity)
                        return errors
                    }}
                    render={({submitError, handleSubmit, submitting, pristine, values, errors}) => (
                        <form onSubmit={handleSubmit} className={"container"}>
                            <div className="row">
                                <Tabs
                                    onSelect={(k) => {
                                        setActiveTab(k)
                                    }}
                                    activeKey={activeTab}
                                    id="disposition-tabs"
                                    className="mb-3"
                                >
                                    <Tab eventKey="information" title={t('information')} mountOnEnter={true} disabled>
                                        <div className="col-md-12 fv-row">
                                            <h3>{t('information')}</h3>

                                            <div className={"col-12 fv-row"}>
                                                <label
                                                    className="required fs-5 fw-semibold mb-2">{t('business_partner')}</label>
                                                <Field
                                                    name='business_partner'
                                                    component={AsyncSelectAdapter}
                                                    loadOptions={loadBPOptions}
                                                    placeholder={t('business_partner')}
                                                    isClearable={true}
                                                >
                                                </Field>
                                                <ErrorField name="business_partner"/>
                                            </div>
                                            <div className={"col-12 fv-row"}>
                                                <label className="fs-5 fw-semibold mb-2">{t('period')}</label>
                                                <Field
                                                    name='period'
                                                    component={DateRangePickerAdapter}
                                                    isClearable={true}
                                                />
                                            </div>
                                            <div className={"col-12 fv-row"}>
                                                <label
                                                    className="required fs-5 fw-semibold mb-2">{t('services')}</label>
                                                <Field
                                                    name='services'
                                                    component={AsyncSelectAdapter}
                                                    loadOptions={loadServiceOptions}
                                                    placeholder={t('services')}
                                                    isClearable={true}
                                                    isMulti={true}
                                                >
                                                </Field>
                                                <ErrorField name="services"/>
                                            </div>
                                            <div className={"col-12 fv-row"}>
                                                <label
                                                    className="required fs-5 fw-semibold mb-2">{t('quantity')}</label>
                                                <Field
                                                    name="quantity"
                                                    component="input"
                                                    type="number"
                                                    className="form-control"
                                                />
                                                <ErrorField name="quantity"/>
                                            </div>
                                            <div className={"col-12 fv-row"}>
                                                <label className="required fs-5 fw-semibold mb-2">{t('unit')}</label>
                                                <Field
                                                    name='unitId'
                                                    component={AsyncSelectAdapter}
                                                    loadOptions={loadUnitOptions}
                                                    placeholder="Select"
                                                    isClearable={true}
                                                >
                                                </Field>
                                                <ErrorField name="unitId"/>
                                            </div>

                                            <div className={"col-12 fv-row"}>
                                                <label className="fs-5 fw-semibold mb-2">{t('comment')}</label>
                                                <Field
                                                    name='comment'
                                                    component="textarea"
                                                    placeholder="Comment"
                                                    className="form-control"
                                                >
                                                </Field>
                                                <ErrorField name="comment"/>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-stack pt-10">
                                            <div/>
                                            <div>
                                                <TableButton
                                                    icon={<NavArrowRight/>}
                                                    leftIcon={false}
                                                    className="btn btn-lg btn-primary"
                                                    type="button"
                                                    title={t('continue')}
                                                    onClick={() => setActiveTab("fields")}
                                                    disabled={!isEmpty(errors)}
                                                />
                                            </div>
                                        </div>
                                    </Tab>


                                    <Tab eventKey="fields" title={t('fields')} mountOnEnter={true} disabled>
                                        <div className={"col-12 fv-row"}>
                                            <label className="required fs-5 fw-semibold mb-2">{t('fields')}</label>
                                            <FieldMultiSelect businessPartnerRef={businessPartnerRef}
                                                              fieldsRef={fieldsRef}/>
                                            <ErrorField name="fields"/>
                                        </div>
                                        <div className="d-flex flex-stack pt-10">
                                            <div>
                                                <TableButton
                                                    icon={<NavArrowLeft/>}
                                                    leftIcon={true}
                                                    className="btn btn-lg btn-light-primary me-3"
                                                    type="button"
                                                    title={t('back')}
                                                    onClick={() => setActiveTab("information")}
                                                />
                                            </div>
                                            <div>
                                                <TableButton
                                                    icon={<NavArrowRight/>}
                                                    leftIcon={false}
                                                    className="btn btn-lg btn-primary"
                                                    type="button"
                                                    title={t('continue')}
                                                    onClick={() => setActiveTab("staff_orders")}
                                                    disabled={!isEmpty(errors)}
                                                />
                                            </div>
                                        </div>
                                    </Tab>



                                    <Tab eventKey="staff_orders" title={t('staff_orders')} mountOnEnter={true} disabled>
                                        <div className="col-md-12 fv-row">
                                            <StaffOrders staffOrdersRef={staffOrdersRef}/>
                                        </div>
                                        <div className="d-flex flex-stack pt-10">
                                            <div>
                                                <TableButton
                                                    icon={<NavArrowLeft/>}
                                                    leftIcon={true}
                                                    className="btn btn-lg btn-light-primary me-3"
                                                    type="button"
                                                    title={t('back')}
                                                    onClick={() => setActiveTab("fields")}
                                                />
                                            </div>
                                            <div/>
                                        </div>
                                        <div className="modal-footer">
                                            <TableButton
                                                className="btn btn-primary fw-semibold px-6"
                                                type="submit"
                                                title={"Create"}
                                                disabled={submitting || pristine}
                                            />
                                            <TableButton
                                                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                                                type="button"
                                                onClick={onClose}
                                                title={"Cancel"}
                                            />
                                        </div>
                                    </Tab>
                                </Tabs>
                                {submitError && <div className="form-error-message">{submitError}</div>}
                            </div>
                            <FormSpy
                                subscription={{values: true}}
                                onChange={change => {
                                    if (change.values.business_partner) {
                                        businessPartnerRef.current = change.values.business_partner.value
                                    }

                                    orderRef.current = { ...change.values, business_partner: businessPartnerRef.current }
                                }}
                            />
                        </form>
                    )}
                />
            </div>
        </ModalContent>
    )
}

export default CreateOrderModal
