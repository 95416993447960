import React from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import EditableTextInput from '../Table/EditableTextInput';

const CostCalculationTable = ({machine, updateMachine}) => {
  const { t } = useTranslation()

  return (
    <div className="card card-flush">
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          Cost calculation
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="row mt-2 justify-content-md-center">
          <div className="col-12 table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
              <thead>
              <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th colSpan="1">
                  <div>
                    {machine.rental ? t('machine_new_value') : t('purchase_price')}
                    <br/>
                    {machine.acquisition_date && <small>{moment.parseZone(machine.acquisition_date).format('YYYY-MM-DD')}</small> }
                  </div>
                </th>
                {
                  machine.diff_between_travel_and_work &&
                  <th colSpan="1">
                    <div>{t('driving_hours')}</div>
                  </th>
                }
                <th colSpan="1">
                  <div>{t('usage_hours')}</div>
                </th>
                <th colSpan="1">
                  <div>{t('useful_life')}</div>
                </th>
                {
                  !machine.calc_costs_as_leasing &&
                  <th colSpan="1">
                    <div>{t('residual_value')}</div>
                  </th>
                }
                <th colSpan="1">
                  <div>{t('residual_value_perc')}</div>
                </th>
              </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
              <tr>
                <td><EditableTextInput id={machine.id} attribute='purchase_price' initialValue={machine.purchase_price} handleSubmit={updateMachine} /></td>
                {
                  machine.diff_between_travel_and_work &&
                  <td><EditableTextInput id={machine.id} attribute='plan_travel_hours_per_year' initialValue={machine.plan_travel_hours_per_year} handleSubmit={updateMachine} /> Std.</td>
                }
                <td><EditableTextInput id={machine.id} attribute='plan_work_hours_per_year' initialValue={machine.plan_work_hours_per_year} handleSubmit={updateMachine} /> {t('hours_abbreviation')}</td>
                <td><EditableTextInput id={machine.id} attribute='plan_years' initialValue={machine.plan_years} handleSubmit={updateMachine} /> {t('years')}</td>
                <td><EditableTextInput id={machine.id} attribute='residual_value' initialValue={machine.residual_value} handleSubmit={updateMachine} /> </td>
                <td><EditableTextInput id={machine.id} attribute='residual_value_perc' initialValue={machine.residual_value_perc} handleSubmit={updateMachine} /> %</td>
              </tr>
              </tbody>
              <tfoot className="fw-bold text-black">
              <tr>
                <th/>
                {machine.diff_between_travel_and_work && <th/> }
                <th/>
                <th/>
                { !machine.calc_costs_as_leasing && <th/> }
                <th/>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostCalculationTable;
