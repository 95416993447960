import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import {composeValidators, isRequired, minLength} from '../../components/Form/validations';
import TableButton from '../../components/Table/TableButton';
import ErrorField from '../../components/Form/ErrorField';
import Checkbox from '../../components/Checkbox';
import {mapValues} from 'lodash';

const Create = ({ onClose }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const data = {
      name: values.name,
      vat_perc: values.vat_perc,
      article: !!values.article
    };

    axiosInstance.post(routes.revenueCodes(), data)
      .then(response => {
        Toastr({
          title: t('success'),
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
      })
      .catch(error => {
        const errorData = error.response.data
        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        let errors = {}
        errors.name = composeValidators(isRequired, minLength(5))(values.name)
        errors.vat_perc = isRequired(values.vat_perc)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <>
            <div className="container">
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('name')}</label>
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <ErrorField name="name" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('value_added_tax')}</label>
                  <Field
                    name="vat_perc"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <ErrorField name="vat_perc" />
                </div>
              </div>
              <div className="mb-3">
                <Field
                  name="article"
                  component={Checkbox}
                  type="checkbox"
                  size="sm"
                  label={t('used_for_goods_question')}
                />
              </div>
              {submitError && <div className="form-error-message">{submitError}</div>}
              <div className="modal-footer">
                <TableButton
                  className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                  type="button"
                  onClick={onClose}
                  title={t('cancel')}
                />
                <TableButton
                  className="btn btn-primary fw-semibold px-6"
                  type="submit"
                  disabled={submitting || pristine}
                  title={t('create')}
                />
              </div>
            </div>
          </>
        </form>
      )}
    />
  )
}

export default Create;
