import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import StaffTextInput from "../StaffTextInput";
import StaffDatePickerInput from "../StaffDatePickerInput";
import StaffSelectInput from "../StaffSelectInput";
import { axiosInstance, routes } from '../../../utils/api_base';
import Toastr from "../../Toastr";
import StaffToggleInput from "../StaffToggleInput";
import QuestionMark from '../../Icons/QuestionMark';
import { WrenchIcon } from '../../Icons';
import { Tooltip } from 'bootstrap'
import Button from "../../Button";
import EditableSelectInput from "../../Table/EditableSelectInput";

const PersonalDetails = ({ staff, customizing, updateStaff, setStaff }) => {
  const { t } = useTranslation()

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, []);

  const getCostCentres = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.costCenters(), { params: params })
      .then((response) => {
        return response.data.data.map((centre) => ({
          value: centre.attributes.id,
          label: centre.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getSchoolEducations = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.schoolEducations(), { params: params })
      .then((response) => {
        return response.data.data.map((education) => ({
          value: education.attributes.id,
          label: education.attributes.education_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getApprenticeships = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.apprenticeships(), { params: params })
      .then((response) => {
        return response.data.data.map((apprenticeship) => ({
          value: apprenticeship.attributes.id,
          label: apprenticeship.attributes.apprenticeship_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getRoles = (inputText) => {
    let params = {
      id: staff.id,
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.staffRoles(), { params: params })
      .then((response) => {
        return response.data.map((role) => ({
          value: role.id,
          label: role.label,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getAdditionalRoles = (inputText) => {
    let params = {
      id: staff.id,
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.staffAdditionalRoles(), { params: params })
      .then((response) => {
        return response.data.map((role) => ({
          value: role.id,
          label: role.label,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const addAdditionalStaffRoles = (id, params) => {
    const data = {
      id: staff.id,
      additional_staff_roles: params.selected
    }

    axiosInstance.post(routes.staffAddAdditionalRoles(), data)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setStaff(prevState => ({
          ...prevState,
          ...resp.data.data.attributes
        }))
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  return (
    <>
      <div className="d-flex row">
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                <StaffDatePickerInput
                  staffId={staff.id}
                  initialValue={staff.entry_date}
                  translation={t("entry_date")}
                  attribute={"entry_date"}
                  updateStaff={updateStaff}
                />
                <StaffDatePickerInput
                  staffId={staff.id}
                  initialValue={staff.termination_date}
                  defaultInitialValue={"-"}
                  translation={t("exit_date")}
                  attribute={"termination_date"}
                  updateStaff={updateStaff}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.termination_reason}
                  translation={t("reason_for_leaving")}
                  attribute={"termination_reason"}
                  updateStaff={updateStaff}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.health_insurance}
                  translation={t("health_insurance")}
                  attribute={"health_insurance"}
                  updateStaff={updateStaff}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.tax_number}
                  translation={t("tax_identification_number")}
                  attribute={"tax_number"}
                  updateStaff={updateStaff}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.social_security_number}
                  translation={t("social_security_number")}
                  attribute={"social_security_number"}
                  updateStaff={updateStaff}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.disabled}
                  translation={t("degree_of_disability")}
                  attribute={"disabled"}
                  updateStaff={updateStaff}
                />
                {
                  customizing.use_cost_centers && <>
                    <div className="fw-bold">
                      {t("cost_center")}
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-custom-class="tooltip-inverse"
                        data-bs-placement="top"
                        title={t('cost_centre_title')}
                        className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                      >
                        <QuestionMark />
                      </span>
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-custom-class="tooltip-inverse"
                        data-bs-placement="top"
                        title={t('cost_centre_definition')}
                        className='btn btn-sm mb-1 pb-1 ps-0'
                      >
                        <a href={'/cost_centers'}>
                          <WrenchIcon className="fs-2 cursor-pointer text-success" />
                        </a>
                      </span>
                    </div>
                    <StaffSelectInput
                      staffId={staff.id}
                      initialValue={{ value: staff.cost_center_id, label: staff.cost_center_name }}
                      attribute={"cost_center"}
                      updateStaff={updateStaff}
                      getEntities={getCostCentres}
                    />
                  </>
                }
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.specific_target_time}
                  translation={t("target_hours_per_day")}
                  attribute={"specific_target_time"}
                  updateStaff={updateStaff}
                />
                <div className="fw-bold">
                  {t("use_supplements")}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('use_supplements_title')}
                    className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                  >
                    <QuestionMark />
                  </span>
                </div>
                <StaffToggleInput
                  staffId={staff.id}
                  active={staff.use_wage_benefit}
                  attribute={"use_wage_benefit"}
                  handleToggle={updateStaff}
                />
                {
                  customizing.use_vacation_days_in_payrolls && <StaffTextInput
                    staffId={staff.id}
                    initialValue={staff.vacation_days}
                    translation={t("holidays")}
                    attribute={"vacation_days"}
                    updateStaff={updateStaff}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.bic}
                  translation={t("bic")}
                  attribute={"bic"}
                  updateStaff={updateStaff}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.iban}
                  translation={t("iban")}
                  attribute={"iban"}
                  updateStaff={updateStaff}
                />
                <div className="fw-bold">
                  {t("temporary_staff")}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('temporary_staff_title')}
                    className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                  >
                    <QuestionMark />
                  </span>
                </div>
                <StaffToggleInput
                  staffId={staff.id}
                  active={staff.temporary_staff}
                  attribute={"temporary_staff"}
                  handleToggle={updateStaff}
                />
                <div className="fw-bold">
                  {t("wage_or_salary")}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('wage_or_salary_title')}
                    className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                  >
                    <QuestionMark />
                  </span>
                </div>
                <StaffToggleInput
                  staffId={staff.id}
                  active={staff.payroll_not_needed}
                  attribute={"payroll_not_needed"}
                  handleToggle={updateStaff}
                />
                <div className="fw-bold">
                  {t("highest_general_education")}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('highest_education_title')}
                    className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                  >
                    <QuestionMark />
                  </span>
                </div>
                <StaffSelectInput
                  staffId={staff.id}
                  initialValue={{ value: staff.school_education_id, label: staff.school_education }}
                  attribute={"school_education_id"}
                  updateStaff={updateStaff}
                  getEntities={getSchoolEducations}
                />
                <div className="fw-bold">
                  {t("highest_proffesional_education")}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('highest_education_title')}
                    className='btn btn-sm mb-1 pb-2 ps-1 pe-0'
                  >
                    <QuestionMark />
                  </span>
                </div>
                <StaffSelectInput
                  staffId={staff.id}
                  initialValue={{ value: staff.apprenticeship_id, label: staff.apprenticeship_name }}
                  attribute={"school_education_id"}
                  updateStaff={updateStaff}
                  getEntities={getApprenticeships}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.tax_class}
                  translation={t("tax_class")}
                  attribute={"tax_class"}
                  updateStaff={updateStaff}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.child_relief_factor}
                  translation={t("child_allowance")}
                  attribute={"child_relief_factor"}
                  updateStaff={updateStaff}
                />
                <StaffTextInput
                  staffId={staff.id}
                  initialValue={staff.church_tax}
                  translation={t("church_tax")}
                  attribute={"church_tax"}
                  updateStaff={updateStaff}
                />
                <StaffSelectInput
                  staffId={staff.id}
                  initialValue={{ value: staff.citizenship_id, label: staff.citizenship_name }}
                  translation={t("nationality")}
                  attribute={"citizenship_id"}
                  updateStaff={updateStaff}
                  getEntities={getApprenticeships}
                />
              </div>
            </div>
          </div>
        </div>
      </div >
      <div className="d-flex row">
        <div className="col-12 col-lg-6">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-2">
              <div className="py-2 fs-6">
                <div className="d-flex justify-content-between align-items-baseline mb-6">
                  <a href={'/staffs/roles'} style={{ color: '#ffc107' }}>
                    {t('roles_and_additional_releases').toUpperCase()}
                  </a>
                  <Button>
                    <a
                      href={staff.documentation_link}
                      style={{ color: 'black' }}
                    >{t('documentation')}</a>
                  </Button>
                </div>
                <StaffSelectInput
                  staffId={staff.id}
                  initialValue={{ value: staff.role, label: staff.role_name }}
                  translation={t("base_role")}
                  attribute={"role"}
                  updateStaff={updateStaff}
                  getEntities={getRoles}
                />
                <div className="fw-bold">{t("additional_roles")}</div>
                <div className="text-gray-600">
                  <EditableSelectInput
                    id={staff.id}
                    isMulti={true}
                    attribute={"additional_staff_roles"}
                    initialValue={staff.additional_roles}
                    handleSubmit={addAdditionalStaffRoles}
                    getEntities={getAdditionalRoles}
                    fieldStyle="w-50"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default PersonalDetails;
