import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import { PlusIcon } from '../../components/Icons';
import Table from './Table';
import Create from './Create';
import Modal from '../../components/Modal';

const RevenueCodes = () => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <>
        <TableButton
          className="btn-primary"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('shared.new')}
        />
      </>
    )
  }

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('create_revenue_code')}>
        <Create onClose={() => setModalOpen(false)} />
      </Modal>
      <Table tableToolbar={tableToolbar} />
    </>
  )
}

export default RevenueCodes
