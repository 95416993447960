import React from 'react';

const Map = ({tab}) => {
  return (
    <div>
      <h1>Map</h1>
    </div>
  );
};

export default Map;
