import React from "react";
import StaffOrder from "../StaffOrder";
import Button from "../../Button";


const StaffOrders = React.memo(({staffOrdersRef}) => {
    const [staffOrders, setStaffOrders] = React.useState(staffOrdersRef.current)

    return <>
        <h3>Fahreraufträge</h3>
        <div className="mh-375px scroll-y me-n7 pe-7 list-group">
            {staffOrders.map(staffOrder => <StaffOrder staffOrder={staffOrder} onEdit={console.log} staffOrdersRef={staffOrdersRef}/>)}
        </div>
        <div className="row mt-5">
            <Button onClick={() => {
                setStaffOrders(staffOrders => [...staffOrders, {id: staffOrders.length + 1}])
                staffOrdersRef.current.push({id: staffOrders.length + 1})
            }}>
              Fahrerauftrag hinzufügen
            </Button>
        </div>
    </>
}, (prevProps, nextProps) => prevProps.orderId === nextProps.orderId)

export default StaffOrders
