import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';

const Table = ({ filters, tableToolbar, startDate, endDate }) => {
  const { t, i18n } = useTranslation()

  const getChronicles = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { start_date: startDate, end_date: endDate }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    if (params.staff_id === 'All' || params.staff_id === '0') {
      delete params.staff_id
    }
    const res = await axiosInstance.get(routes.licenseChronicles(), { params: params })
    pushToHistory(params)

    const groupedData = res.data.data.reduce((acc, item) => {
      const date = item?.attributes?.day;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    const rows = Object.entries(groupedData).flatMap(([date, items]) => [
      { isDateHeader: true, date },
      ...items
    ]);

    return (
      { rows: rows }
    )
  }

  const staffHtml = (row) => {
    return (
      <a href={row?.original?.attributes?.staff_link}>
        {row?.original?.attributes?.staff_name}
      </a>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'time',
        header: t('time_expressions.time'),
        size: 40,
        minSize: 40,
        enableSorting: false,
        accessorFn: row => row.isDateHeader ? '' : row?.attributes?.date,
        cell: row => {
          const value = row.getValue();
          return row.row.original.isDateHeader ? <strong className='fs-1'>{value}</strong> : value;
        },
      },
      {
        id: 'staff',
        header: t('shared.employees'),
        minSize: 40,
        size: 40,
        enableSorting: false,
        accessorFn: row => row?.attributes?.staff_name,
        cell: ({ row }) => staffHtml(row)
      },
      {
        id: 'license_changes',
        header: t('change'),
        enableSorting: false,
        accessorFn: row => row.isDateHeader ? row?.date : row?.attributes?.license_changes,
        cell: row => {
          const value = row.getValue();
          return row.row.original.isDateHeader ? <strong className='fs-1'>{value}</strong> : value;
        },
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getChronicles}
      tableToolbar={tableToolbar}
      filters={filters}
      enablePagination={false}
      showSearchInput={false}
      trigger={startDate + endDate}
    />
  )
}

export default Table;
