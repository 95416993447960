import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from './Table';
import DatePicker from '../../components/DatePicker';

const BookingResults = ({ filters, staff_id, staff_order_id }) => {
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get('day') ?? new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState({ date: date })

  const onChangeDatePicker = (selectedDates, dateStr) => {
    setSelectedDate({ date: dateStr })
  }

  const tableToolbar = () => {
    return (
      <DatePicker options={{
        defaultDate: date,
        dateFormat: 'Y-m-d',
        onChange: onChangeDatePicker
      }} />
    )
  }

  return (
    <Table
      filters={filters}
      staff_id={staff_id}
      staff_order_id={staff_order_id}
      date={selectedDate.date}
      tableToolbar={tableToolbar}
    />
  )
}

export default BookingResults;
