import React, { useState, useEffect } from 'react';
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import EditableTextInput from '../../components/Table/EditableTextInput';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import EditableSelectInput from "../../components/Table/EditableSelectInput";
import { QuestionIcon } from '../Icons';
import moment from "moment";

const UserProfile = ({ creatorName, businessPartner, currentLocation, salesForce, group, businessAreas }) => {
  const { t, i18n } = useTranslation()
  const [label, setLabel] = useState('')
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};
  const dateFormat = i18n.language === "de" ? "YYYY.MM.DD" : "YYYY-MM-DD";
  const [businessPartnerDetails, setBusinessPartnerDetails] = useState({
    active: businessPartner.active,
    client_specific_number: businessPartner.client_specific_number,
    group: { value: group?.id || '', label: group?.name || t('no_selection') },
    location: { value: currentLocation?.id, label: currentLocation?.name },
    name: businessPartner.name,
    official_name: businessPartner.official_name,
    sales_force: { value: salesForce?.id || '', label: salesForce?.name || t('no_selection') },
    ustg13b: businessPartner.ustg13b,
    business_area: businessAreas
  });

  useEffect(() => {
    if (businessPartner.default_company) {
      setLabel(t('own_main_company'))
    } else if (businessPartner.company) {
      setLabel(t('own_company'))
    } else {
      setLabel(false)
    }
  }, [businessPartner])

  const formatedDate = (date) => {
    return moment(date).format(dateFormat);
  }

  const updateAreaRelations = (id, attrs) => {
    const params = {
      business_area_ids: attrs.business_area.map(item => item.value)
    }

    axiosInstance.put(routes.businessPartnerAreaRelations(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setBusinessPartnerDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const toggleHtml = (attribute) => {
    const isActive = businessPartnerDetails[attribute]
    const label = isActive ? t('yes') : t('no')
    return (
      <Form.Check
        onChange={() => updateBusinessPartner(businessPartner.id, { [attribute]: !isActive })}
        checked={isActive}
        className="cursor-pointer"
        type="switch"
        id={attribute}
        label={label}
      />
    )
  }

  const updateBusinessPartner = (id, attrs) => {
    const params = {
      business_partner: {
        active: attrs.active,
        client_specific_number: attrs.client_specific_number,
        group_id: attrs.group?.value,
        location_id: attrs.location?.value,
        name: attrs.name,
        official_name: attrs.official_name,
        sales_force_id: attrs.sales_force?.value,
        ustg13b: attrs.ustg13b
      }
    }
    axiosInstance.put(routes.businessPartner(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setBusinessPartnerDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const getLocations = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      scope_name: 'default'
    }

    return axiosInstance.get(routes.locations(), { params: params })
      .then((response) => {
        return response.data.data.map((locations) => ({
          value: locations.attributes.id,
          label: locations.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getBusinesAreas = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      scope_name: 'default'
    }

    return axiosInstance.get(routes.businessAreas(), { params: params })
      .then((response) => {
        return response.data.data.map((area) => ({
          value: area.attributes.id,
          label: area.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getStaffs = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText,
      active: true,
      without_admin_farmer_subcontractor: true,
      sales_force: true
    }

    return axiosInstance.get(routes.staffs(), { params: params })
      .then((response) => {
        let options = response.data.data.map((staff) => ({
          value: staff.attributes.id,
          label: staff.attributes.name,
        }))
        options.push({
          value: '',
          label: t('no_selection')
        })
        return options
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getGroups = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.businessPartnerGroups(), { params: params })
      .then((response) => {
        let options = response.data.data.map((groupItem) => ({
          value: groupItem.attributes.id,
          label: groupItem.attributes.long_name,
        }))
        options.push({
          value: '',
          label: t('no_selection')
        })

        return options
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const ribbonLabel = () => {
    return (
      <>
        {label &&
          <div className="ribbon-label">
            {label}
            <span className="ribbon-inner bg-primary"></span>
          </div>}
      </>
    )
  }

  return (
    <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8 top-0 position-md-sticky">
        <div className="card-header ribbon ribbon-end ribbon-clip">
          {ribbonLabel()}
          <span className="align-content-center fs-3 text-gray-800 fw-bold">
            {businessPartner.name}
          </span>
          {creatorName && currentCustomizing.show_creator && (
            <span className="w-175px d-flex align-items-center text-gray-500 ">
              {t('resource_created_by_html', { user_name: creatorName, date: formatedDate(businessPartner.created_at)})}
            </span>
          )}
        </div>
        <div className="card-body">
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold align-content-center" >
              {t('details')}
            </div>
          </div>
          <div className="separator"></div>
          <div id="kt_user_view_details" className="collapse show">
            <div className="pb-5 fs-6">
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center">
                  {t("active")}
                </div>
                <div className="text-gray-600 cursor-pointer">
                  {toggleHtml('active')}
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4 flex-stack'>
                <div className="fw-bold align-items-center d-flex">
                  {t('official_name')}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('short_name_in_business_partner_title')}
                    className="btn p-0 text-hover-primary"
                  >
                    <QuestionIcon className={"fs-4"} />
                  </span>
                </div>

                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="official_name"
                    placeholder={t('official_name')}
                    initialValue={businessPartnerDetails.official_name}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-items-center d-flex">
                  {t('short_name')}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('nickname_used_message')}
                    className="btn p-0 text-hover-primary"
                  >
                    <QuestionIcon className={"fs-4"} />
                  </span>

                  </div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="name"
                    placeholder={t('short_name')}
                    initialValue={businessPartnerDetails.name}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center ">{t('shared.number')}</div>
                <div className="text-gray-600">
                  <EditableTextInput
                    id={businessPartner.id}
                    attribute="client_specific_number"
                    placeholder={t('shared.number')}
                    initialValue={businessPartnerDetails.client_specific_number}
                    handleSubmit={updateBusinessPartner}
                  />
                </div>
              </div>
              {currentCustomizing.use_business_partner_groups && (
                <>
                  <div className="separator"></div>
                  <div className='d-flex justify-content-between py-4'>
                    <div className="fw-bold align-content-center">{t('shared.group')}</div>
                    <div className="text-gray-600">
                      <EditableSelectInput
                        id={businessPartner.id}
                        attribute={'group'}
                        placeholder={t('shared.group')}
                        returnObject
                        initialValue={{ value: businessPartnerDetails.group.value, label: businessPartnerDetails.group.label }}
                        handleSubmit={updateBusinessPartner}
                        getEntities={getGroups}
                        isClearable={false}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center">{t('business_segments')}</div>
                <div className="text-gray-600">
                  <EditableSelectInput
                    id={businessPartner.id}
                    attribute={'business_area'}
                    placeholder={t('business_segments')}
                    returnObject
                    isMulti
                    initialValue={businessPartnerDetails?.business_area}
                    handleSubmit={updateAreaRelations}
                    getEntities={getBusinesAreas}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center ">UStG §13b</div>
                <div className="text-gray-600">
                  {toggleHtml('ustg13b')}
                </div>
              </div>
              <div className="separator"></div>
              <div className='d-flex justify-content-between py-4'>
                <div className="fw-bold align-content-center">{t('location')}</div>
                <div className="text-gray-600">
                  <EditableSelectInput
                    id={businessPartner.id}
                    attribute={'location'}
                    returnObject
                    placeholder={t('location')}
                    initialValue={{ value: businessPartnerDetails.location.value, label: businessPartnerDetails.location.label }}
                    handleSubmit={updateBusinessPartner}
                    getEntities={getLocations}
                  />
                </div>
              </div>
              <div className="separator"></div>
              {currentCustomizing.sales_force && (
                <>
                  <div className='d-flex justify-content-between py-4'>
                    <div className="fw-bold align-content-center">{t('field_service')}</div>
                    <div className="text-gray-600">
                      <EditableSelectInput
                        id={businessPartner.id}
                        attribute={'sales_force'}
                        returnObject
                        placeholder={t('field_service')}
                        initialValue={{ value: businessPartnerDetails.sales_force.value, label: businessPartnerDetails.sales_force.label }}
                        handleSubmit={updateBusinessPartner}
                        getEntities={getStaffs}
                        isClearable={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
