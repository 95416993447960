import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { CheckSquareIcon, CrossSquareIcon, EditNotepadIcon } from '../../Icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ErrorField from '../../Form/ErrorField';
import TimePickerAdapter from '../../Form/TimePickerAdapter';
import * as style from './index.module.css';

const TimePickerInput = ({ attributes, prevEndTime, nextStartTime, initialStartTimeValue, initialEndTimeValue, handleSubmit }) => {
  const { t } = useTranslation()
  const [editable, setEditable] = useState(false)
  const [isHovered, setIsHovered] = useState(false);

  const onSubmit = async values => {
    setEditable(false)

    if (typeof values.start_time === "object") {
      const startTime = `${values.start_time.getHours()}:${values.start_time.getMinutes()}`;

      values.start_time = startTime
    }

    if (typeof values.end_time === "object") {
      const endTime = `${values.end_time.getHours()}:${values.end_time.getMinutes()}`;

      values.end_time = endTime
    }

    handleSubmit(attributes.id, values)
  }

  const parseTime = (time) => {
    let hours;
    let minutes;

    if (typeof time === 'string') {
      [hours, minutes] = time.split(':').map(Number);
    } else {
      hours = time.getHours();
      minutes = time.getMinutes();
    }

    return [hours, minutes]
  };

  const timeValidator = (changedTime, attribute, times) => {
    const [hours, minutes] = parseTime(changedTime)

    if (attribute === "start_time") {
      const [initialEndTimeHour, initialEndTimeMinute] = times.end_time ? parseTime(times.end_time) : parseTime(initialEndTimeValue);

      if (hours > initialEndTimeHour || (hours === initialEndTimeHour && minutes > initialEndTimeMinute)) {
        return t("time_validation_after_end");
      }
    }

    if (attribute === "end_time") {
      const [initialStartTimeHour, initialStartTimeMinute] = times.start_time ? parseTime(times.start_time) : parseTime(initialStartTimeValue);

      if (hours < initialStartTimeHour || (hours === initialStartTimeHour && minutes < initialStartTimeMinute)) {
        return t("time_validation_start_end");
      }
    }

    return undefined;
  }

  const inputHtml = () => {
    return (
      <Form
        onSubmit={onSubmit}
        validate={values => {
          let errors = {}
          errors.start_time = timeValidator(values.start_time, "start_time", values)
          errors.end_time = timeValidator(values.end_time, "end_time", values)

          return errors
        }}
        initialValues={{
          start_time: initialStartTimeValue,
          end_time: initialEndTimeValue
        }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={style["form-time-picker"]}>
              <Field
                name='start_time'
                minTime={prevEndTime}
                maxTime={nextStartTime}
                component={TimePickerAdapter}
                clearIcon={false}
                disableClock={true}
                required={true}
              >
              </Field>
              <span className='me-2 ms-2 mt-3'>{" - "}</span>
              <Field
                name='end_time'
                minTime={prevEndTime}
                maxTime={nextStartTime}
                component={TimePickerAdapter}
                clearIcon={false}
                disableClock={true}
                required={true}
              >
              </Field>
              <div className={`mt-4 ms-2 ${style['form-buttons']}`}>
                <button type="submit" disabled={submitting || pristine}>
                  <CheckSquareIcon className={classNames("fs-3 text-success", { "text-gray-500": submitting || pristine })} />
                </button>
                <button
                  type="button"
                  onClick={() => setEditable(false)}
                  disabled={submitting}
                >
                  <CrossSquareIcon className="fs-3 text-danger" />
                </button>
              </div>
            </div>
            <ErrorField name="start_time" />
            <ErrorField name="end_time" />
          </form>
        )}
      />
    )
  }

  const viewHtml = () => {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setEditable(true)}
      >
        <span>
          {attributes.hour_and_min}
        </span>
        {isHovered && <EditNotepadIcon />}
      </div>
    )
  }
  return (
    <>
      {editable ? inputHtml() : viewHtml()}
    </>
  )
}

export default TimePickerInput;
