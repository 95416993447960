import React from 'react';
import { axiosInstance, routes } from "../../utils/api_base";
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';

const StaffAsyncSelector = ({ staffLongName, activeTab, day }) => {
  const getStaffs = (inputText) => {
    let params = { page: 1, limit: 20, active: true, serialize: "full", query: inputText }

    return axiosInstance.get(routes.staffs(), { params: params })
      .then((response) => {
        return response.data.data.map((staff) => ({
          value: staff.id,
          label: staff.attributes.long_name,
          link: staff.attributes.link
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      const params = new URLSearchParams({
        staff_page: activeTab,
        day: day
      });

      const link = `${selectedOption.link}?${params.toString()}`;

      window.location.href = link;
    }
  };

  return (
    <div>
      <AsyncSelectAdapter
        input={{ onChange: handleSelectChange }}
        loadOptions={getStaffs}
        placeholder={staffLongName}
      />
    </div>
  );
};

export default StaffAsyncSelector;
