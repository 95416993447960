import React, { useMemo, Fragment, useState, useEffect } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import JobProgressBar from '../../components/JobProgressBar';
import TableButton from '../../components/Table/TableButton';
import { isEmpty } from 'lodash';
import pluralize from 'pluralize';
import classNames from 'classnames'

const Table = ({tableToolbar, businessPartnerId, prevJobId}) => {
  const { t, i18n } = useTranslation()
  const [selectedRows, setSelectedRows] = useState({});
  const [buttonTitle, setButtonTitle] = useState('');
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [jobId, setJobId] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableCheckBoxes, setDisableCheckBoxes] = useState(false);
  const [dataRows, setDataRows] = useState([]);

  useEffect(() => {
    if (prevJobId) {
      setShowProgressBar(true)
      setShowSubmitButton(false)
      setJobId(prevJobId)
      setDisableCheckBoxes(true)
    }
  }, []);

  useEffect(() => {
    const allChecked = dataRows.reduce((acc, row) => {
      acc[row.id] = row.id;
      return acc;
    }, {});
    setSelectedRows(allChecked);
  }, [dataRows]);

  useEffect(() => {
    correctTextForButton()
  }, [selectedRows]);

  const getDunnings = async (pagination, sorting, globalFilter, customFilters) => {
    let params = { business_partner: businessPartnerId }

    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }

    const res = await axiosInstance.get(routes.ordersWithoutInvoices(), { params: { ...params } })
    pushToHistory(params)
    setDataRows(res.data.data);

    return (
      { rows: res.data.data }
    )
  }

  const handleCheckboxChange = (id) => {
    setSelectedRows(prev => {
      const newSelectedRows = { ...prev };
      if (newSelectedRows[id]) {
        delete newSelectedRows[id];
      } else {
        newSelectedRows[id] = id;
      }
      return newSelectedRows;
    })
  }

  const correctTextForButton = () => {
    const selectedCount = Object.keys(selectedRows).length;

    if (selectedCount >= 1 ) {
      const dunningText = t('draft_invoice_for', {orders_size: selectedCount });
      const invoiceText = pluralize(t('orders'), selectedCount);
      setButtonTitle(`${dunningText} ${invoiceText}`)
    } else {
      setButtonTitle(t('no_orders'))
    }
  }

  const handleSubmit = async () => {
    setDisableSubmit(true)
    setShowSubmitButton(false)
    const selectedIds = Object.keys(selectedRows);

    try {
      const resp = await axiosInstance.post(routes.ordersWithoutInvoices(), { business_partner_id: businessPartnerId, orders: selectedIds });
      setJobId(resp.data.job_id)
      setShowProgressBar(true)
    } catch (error) {
      setShowProgressBar(false)
      console.error('Error submitting selected orders:', error);
    }
  }

  const orderLink = (row) => {
    return (
      <TableButton
        type="button"
        target="_blank"
        href={row?.original?.attributes?.link}
        title={row?.original?.attributes?.id}
        className="btn-light-primary"
      />
    )
  }

  const serviceRow = (row) => {
    const services = row?.original?.attributes?.services;

    return (
      <>
        {services?.map((service, index) => (
          <span key={index}>
            {service}
            <br />
          </span>
        ))}
      </>
    )
  }

  const checkBoxRow = (row) => {
    return (
      <div className="form-check form-check-solid me-10">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          disabled={disableCheckBoxes}
          id={row.original.id}
          name="orders[]"
          checked={!!selectedRows[row.original.id]}
          onChange={() => handleCheckboxChange(row.original.id)}
        />
        <label className="form-check-label"></label>
      </div>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'order',
        header: t('order'),
        size: 70,
        minSize: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.id,
        cell: ({ row }) => orderLink(row)
      },
      {
        id: 'date_of_invoice',
        header: t('shared.comment'),
        minSize: 70,
        size: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.comment,
        cell: row => row.getValue(),
      },
      {
        id: 'services',
        header: t('shared.services'),
        enableSorting: false,
        accessorFn: row => row?.attributes?.services,
        cell: ({ row }) => serviceRow(row),
      },
      {
        id: 'date_from',
        enableSorting: false,
        header: t('date_from'),
        accessorFn: row => row?.attributes?.date_from,
        cell: row => row.getValue(),
      },
      {
        id: 'date_to',
        header: t('date_until'),

        enableSorting: false,
        accessorFn: row => row?.attributes?.date_to,
        cell: row => row.getValue(),
      },
      {
        id: 'actions',
        header: t('create_draft_invoice'),
        size: 70,
        minSize: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.id,
        cell: ({ row }) => checkBoxRow(row),
      },
    ],
    [i18n.language, selectedRows]
  )

  return (
    <div>
      <BaseTable
        columns={columns}
        columnVisibility={{}}
        getDataFn={getDunnings}
        showSearchInput={false}
        enablePagination={false}
        enableSorting={false}
        tableToolbar={tableToolbar}
      />
      {showSubmitButton &&
        <TableButton
          type="button"
          onClick={handleSubmit}
          className={classNames("btn btn-primary mt-3", (isEmpty(selectedRows) || disableSubmit) ? 'disabled' : '')}
          title={buttonTitle}
        />
      }
      {showProgressBar && <JobProgressBar jobId={jobId}/>}
    </div>
  );
}

export default Table;
