import React, {useState, useEffect, useCallback, useRef} from 'react';
import CustomTimeline from "../../components/Disposition/CustomTimeline";
import "react-calendar-timeline/lib/Timeline.css";
import './index.css'
import CreateOrderModal from "../../components/Disposition/CreateOrderModal";
import Header from "../../components/Disposition/Header";
import OrderSidebar from "../../components/Disposition/OrderSidebar";
import DndProviderWrapper from "../../core/DndProvider";
import StaffSidebar from "../../components/Disposition/StaffSidebar";
import {axiosInstance, routes} from "../../utils/api_base";
import moment from 'moment'
import debounce from 'lodash/debounce';
import Modal from '../../components/Modal';

function Disposition() {
    const defaultTimeStart = moment()
        .startOf("week")
        .toDate();
    const defaultTimeEnd = moment()
        .endOf("week")
        .toDate();


    const [modalOpen, setModalOpen] = useState(false)
    const [viewType, setViewType] = useState('resource')
    const [timeType, setTimeType] = useState('week')
    const [state, setState] = useState({groups: [], items: [], groupIndex: null, time: null, staffId: null, orders: [], staffs: [], absences: [], machines: []})
    const [time, setTime] = useState({startTime: defaultTimeStart, endTime: defaultTimeEnd})
    const [loading, setLoading] = useState(true)

    const timeRef = useRef(undefined)

    const getStaffs = async (pagination) => {
        const {pageIndex, pageSize} = pagination
        const params = {
            page: pageIndex + 1,
            limit: pageSize,
            active: true,
            without_admin_and_farmer: true,
            show_in_dispo: true
        }
        const res = await axiosInstance.get(
            routes.staffs(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }


    const getMachines = async (pagination) => {
        const {pageIndex, pageSize} = pagination
        const params = {
            page: pageIndex + 1,
            limit: pageSize,
            active: true,
            serialize: 'short',
            show_in_dispo: true,
            fahrzeug: true
        }
        const res = await axiosInstance.get(
            routes.machines(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    const getOrders = async (pagination, sorting, startTime, endTime) => {
        const {pageIndex, pageSize} = pagination
        const params = {
            page: pageIndex + 1,
            limit: pageSize,
            start_time: startTime,
            end_time: endTime,
            vague_date: false,
            show_in_dispo: true
        }
        const res = await axiosInstance.get(
            routes.orders(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }
    const getAbsences = async (pagination, startDate, endDate) => {
        const {pageIndex, pageSize} = pagination
        const params = {
            page: pageIndex + 1,
            limit: pageSize,
            start_date: startDate,
            end_date: endDate
        }
        const res = await axiosInstance.get(
            routes.absences(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    const onTimeTypeChange = useCallback((newViewType) => {
        setTimeType(newViewType)

        // Berechne die Mitte zwischen den beiden Daten
        const middleDate = moment(time.startTime).add(moment(time.endTime).diff(time.startTime) / 2, 'milliseconds');

        let start, end;

        // Scope-basierte Berechnung von Anfang und Ende
        switch (newViewType) {
            case 'day':
                start = middleDate.clone().startOf('day');
                end = middleDate.clone().endOf('day');
                break;

            case 'week':
                start = middleDate.clone().startOf('week');
                end = middleDate.clone().endOf('week');
                break;

            case 'month':
                start = middleDate.clone().startOf('month');
                end = middleDate.clone().endOf('month');
                break;

            default:
                throw new Error('Ungültiger Scope! Verwende "day", "week" oder "month".');
        }

        setTime({startTime: start.toDate(), endTime: end.toDate()})
    }, [time])

    const onClickToday = useCallback(() => {
        setTime(() => {
            const start = moment().clone().startOf(timeType);
            const end = moment().clone().endOf(timeType);

            return {startTime: start.toDate(), endTime: end.toDate()}
        })

    }, [timeType])

    const onClickPrev = useCallback(() => {
        setTime(time => ({
            startTime: moment(time.startTime).add(-1, timeType).toDate(),
            endTime: moment(time.endTime).add(-1, timeType).toDate()
        }))
    }, [timeType])

    const onClickNext = useCallback(() => {
        setTime(time => ({
            startTime: moment(time.startTime).add(1, timeType).toDate(),
            endTime: moment(time.endTime).add(1, timeType).toDate()
        }))
    }, [timeType])

    const onDrop = () => {
    }

    useEffect(() => {
        Promise.all([
            getOrders({ pageIndex: 0, pageSize: 100000 }, undefined, defaultTimeStart, defaultTimeEnd),
            getStaffs({ pageIndex: 0, pageSize: 100000 }),
            getAbsences({ pageIndex: 0, pageSize: 10000 }, defaultTimeStart, defaultTimeEnd)
        ]).then(([ordersResult, staffsResult, absencesResult]) => {

            setState(state => ({
                ...state,
                orders: ordersResult.rows,
                staffs: staffsResult.rows,
                absences: absencesResult.rows
            }))

            getMachines({ pageIndex: 0, pageSize: 250 }).then(result => setState(state => ({ ...state, machines: result.rows })))

            setLoading(false);
        }).catch((error) => {
            console.error("Fehler bei der Datenabfrage:", error);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (viewType !== 'machines') return
        setLoading(true)

        getMachines({pageIndex: 0, pageSize: 1000}).then((result) => {
            setLoading(false)
            setState(state => ({ ...state, machines: result.rows }))
        })
    }, [viewType]);


    useEffect(() => {
        if (timeRef.current) clearTimeout(timeRef.current)

        timeRef.current = setTimeout(() => {
            const startTime = moment(time.startTime).toDate()
            const endTime = moment(time.endTime).toDate()
            setLoading(true)

            getOrders({
                pageIndex: 0,
                pageSize: 100000
            }, undefined, startTime, endTime).then((result) => {

                setState(state => {
                    loadAbsences({startTime, endTime}, (items) => setState(state => ({ ...state, absences: items })))
                    const newOrders = [...state.orders, ...result.rows];

                    const uniqueOrders = newOrders.reduce((acc, order) => {
                        acc[order.id] = order;
                        return acc;
                    }, {});

                    return { ...state, orders: Object.values(uniqueOrders) }
                })

                setLoading(false)
            })

        }, 1000)

    }, [time]);

    const buildStaffGroups = useCallback((staffs) => {
        return staffs.map(staff => ({
            id: staff.id + '',
            title: staff.attributes.long_name,
            rightTitle: '',
            bgColor: ''
        }))
    }, [])

    const buildMachineGroups = useCallback((machines) => {
        return machines.map(machine => ({
            id: machine.id + '',
            title: machine.attributes.long_name,
            rightTitle: '',
            bgColor: ''
        }))
    }, [])

    const buildOrderGroups = useCallback((orders) => {
        return orders.map(order => ({
            id: order.id + '',
            title: order.id + '',
            rightTitle: '',
            bgColor: ''
        }))
    }, [])

    const buildStaffOrderItems = useCallback((orders, staffs = true) => {
        let items = []

        orders.map(order => {
            const startDate = order.attributes.start_time
            const endDate = order.attributes.end_time
            const startValue = Math.floor(moment(startDate).valueOf() / 10000000) * 10000000
            const endValue = Math.floor(moment(endDate).valueOf() / 10000000) * 10000000

            order.attributes.staff_orders.map(staffOrder => {
                items.push({
                    id: staffOrder.id,
                    group: staffs ? staffOrder.attributes.staff_id + '' : staffOrder.attributes.fahrzeug_id + '',
                    title: staffOrder.attributes.name_without_comment,
                    start: startValue,
                    end: endValue,
                    status: staffOrder.attributes.status_code,
                    bgColor: staffOrder.attributes.service?.attributes?.service_color || '#ddd',
                    // canMove: startValue > new Date().getTime(),
                    // canResize: startValue > new Date().getTime() ? (endValue > new Date().getTime() ? 'both' : 'left') : (endValue > new Date().getTime() ? 'right' : false),
                    className: (moment(startDate).day() === 6 || moment(startDate).day() === 0) ? 'item-weekend' : '',
                    itemProps: {
                        'data-tip': order.attributes.business_partner_name
                    }
                })
            })
        })

        return items
    }, [])

    const buildAbsencesItems = useCallback((absences) => {
        let items = []

        absences.map(absence => {
            const startDate = absence.attributes.start_date
            const endDate = absence.attributes.end_date
            const startValue = Math.floor(moment(startDate).valueOf() / 10000000) * 10000000
            const endValue = Math.floor(moment(endDate).valueOf() / 10000000) * 10000000

            items.push({
                id: absence.id,
                group: absence.attributes.staff_id + '',
                title: absence.attributes.name,
                start: startValue,
                end: endValue,
                status: '',
                bgColor: '#A9A9A9',
                // canMove: startValue > new Date().getTime(),
                // canResize: startValue > new Date().getTime() ? (endValue > new Date().getTime() ? 'both' : 'left') : (endValue > new Date().getTime() ? 'right' : false),
                className: (moment(startDate).day() === 6 || moment(startDate).day() === 0) ? 'item-weekend' : '',
                itemProps: {
                    'data-tip': absence.attributes.name
                }
            })
        })

        return items
    }, [])


    const loadAbsences = useCallback(
        debounce((time, callback) => {
            getAbsences({pageIndex: 0, pageSize: 1000}, time.startTime, time.endTime)
                .then(response => {
                    callback(response.rows)
                })
        }, 1000), [])

    const buildOrderItems = useCallback((orders) => {
        let items = []

        orders.map(order => {
            const startDate = order.attributes.start_time
            const endDate = order.attributes.end_time
            const startValue = Math.floor(moment(startDate).valueOf() / 10000000) * 10000000
            const endValue = Math.floor(moment(endDate).valueOf() / 10000000) * 10000000

            items.push({
                id: order.id,
                group: order.id + '',
                title: order.attributes.business_partner_name,
                start: startValue,
                end: endValue,
                status: order.attributes.status_code,
                bgColor: '#dddddd',
                // canMove: startValue > new Date().getTime(),
                // canResize: startValue > new Date().getTime() ? (endValue > new Date().getTime() ? 'both' : 'left') : (endValue > new Date().getTime() ? 'right' : false),
                className: (moment(startDate).day() === 6 || moment(startDate).day() === 0) ? 'item-weekend' : '',
                itemProps: {
                    'data-tip': order.attributes.business_partner_name
                }
            })
        })

        return items
    }, [])


    useEffect(() => {
        if (state.staffs.length === 0) return

        if (viewType === 'orders') {
            const filteredOrders = state.orders.filter(order => {
                const startDate = new Date(order.attributes.start_time);
                const endDate = new Date(order.attributes.end_time);

                return startDate >= new Date(time.startTime) && endDate <= new Date(time.endTime);
            });

            const groups = buildOrderGroups(filteredOrders);

            const items = buildOrderItems(filteredOrders)


            if (groups.length === 0)
                groups.push({
                    id: '0',
                    title: '',
                })


            setState((state) => ({...state, items, groups}))
        } else if (viewType === 'machines') {
            const groups = buildMachineGroups(state.machines);
            const items = buildStaffOrderItems(state.orders, false)

            setState((state) => ({...state, items, groups}))
        } else {
            const groups = buildStaffGroups(state.staffs);
            const items = [...buildStaffOrderItems(state.orders), ...buildAbsencesItems(state.absences)]

            setState((state) => ({...state, items, groups}))
        }
    }, [viewType, state.staffs, state.orders, state.absences, state.machines]);

    const onCreate = (newOrder) => {
        setModalOpen(false)

        const items = buildStaffOrderItems([newOrder])

        setState((state) => ({...state, orders: [...state.orders, newOrder], items: [...state.items, ...items]}))
    }


    const sidebarWidth = 300

    const width = window.innerWidth - 320 - sidebarWidth
    const height = window.innerHeight - 200
    return (<div style={{marginTop: '-1.5rem', height: 'calc(100% + 3rem)', width}}>
            <Modal className="modal fade show p-9 modal-fullscreen" open={modalOpen} onClose={() => setModalOpen(false)}
                   title={"Neuer Auftrag"}>
                <CreateOrderModal onClose={() => setModalOpen(false)}
                                  time={state.time}
                                  staff={state.staffs.find(staff => staff.id === state.staffId)}
                                  onCreate={onCreate}/>
            </Modal>
            <Header onSelectViewType={setViewType}
                    viewType={viewType}
                    startTime={time.startTime}
                    endTime={time.endTime}
                    type={timeType}
                    loading={loading}
                    onClickToday={() => onClickToday(time)}
                    onClickPrev={() => onClickPrev(time)}
                    onClickNext={() => onClickNext(time)}
                    addOrder={() => setModalOpen(true)}
                    onChangeType={(type) => onTimeTypeChange(type)}/>

            <DndProviderWrapper>
                <div className={"card shadow-md"} style={{width, height, overflowY: 'scroll'}}>
                    {state.groups.length > 0 && <CustomTimeline onAdd={(groupIndex, time) => {
                        setState((state) => ({...state, staffId: groupIndex, time}))
                        setModalOpen(true)
                    }}
                                                                scrollable={false}
                                                                onDrop={onDrop}
                                                                groups={state.groups}
                                                                startTime={time.startTime}
                                                                endTime={time.endTime}
                                                                onTimeChange={(startTime, endTime) => setTime({
                                                                    startTime,
                                                                    endTime
                                                                })}
                                                                items={state.items}/>}
                </div>

                {viewType === 'orders' ? <StaffSidebar time={time}/> : <OrderSidebar/>}
            </DndProviderWrapper>
        </div>
    );
}

export default Disposition;
