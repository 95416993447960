import BaseTable from '../../../components/Table';
import React, { useMemo, useState } from 'react';
import StatusLabel from '../../../core/StatusLabel';
import TableButton from '../../Table/TableButton';
import { CheckCircleIcon, CrossSquareIcon, EditIcon, PlusIcon, WatchIcon } from '../../Icons';
import { axiosInstance, routes } from '../../../utils/api_base';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const Bills = ({ businessPartnerId }) => {
  const { t, i18n } = useTranslation()
  const [trigger, setTrigger] = useState(true)

  const getBills = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: 3, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }

    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.bills(), { params: { ...params, business_partner_id: businessPartnerId } })

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const tableToolbar = () => {
    return (
      <TableButton href='/bills' className="btn-primary" icon={<PlusIcon />} title={t('shared.add_to')}/>
    )
  }

  const letterLabel = (row) => {
    const status = row.original.attributes.letter?.attributes?.status
    switch (status) {
      case "sent":
        return <CheckCircleIcon className="fs-2x text-success" />
      case "draft":
        return <EditIcon className="fs-2x text-primary" />
      case "failed":
        return <CrossSquareIcon className="fs-2x text-danger" />
      case "sending":
        return <WatchIcon className="fs-2x text-warning" />
    }
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.name}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.bill'),
        accessorFn: row => row?.attributes?.name,
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'customer',
        accessorFn: row => row?.attributes?.business_partner_name,
        header: t('shared.customer'),
        cell: row => row.getValue(),
      },
      {
        accessorFn: row => <div dangerouslySetInnerHTML={{ __html: row?.attributes?.description }} />,
        header: t('shared.performance'),
        cell: row => row.getValue(),
      },
      {
        id: 'date',
        accessorFn: row => row?.attributes?.bill_date,
        header: t('shared.date'),
        cell: row => row.getValue(),
      },
      {
        id: 'status',
        accessorFn: row => row?.attributes?.status,
        header: t('shared.status'),
        cell: ({ row }) => {
          return <StatusLabel code={row.original.attributes?.status_code?.toLowerCase()} />
        },
      },
      {
        accessorFn: row => row?.attributes?.letter?.attributes?.id,
        header: t('bills_table.sent'),
        cell: ({ row }) => {
          return letterLabel(row)
        },
      },
      {
        id: 'gross',
        accessorFn: row => row?.attributes?.gross_amount,
        header: t('shared.gross'),
        cell: row => row.getValue(),
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getBills}
      tableToolbar={tableToolbar}
      trigger={trigger}
      tableName={'invoice'}
    />
  )
}

export default Bills
