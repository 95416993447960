import React from 'react';
import { axiosInstance, routes } from '../../../utils/api_base';
import EditableSelectInput from '../../Table/EditableSelectInput';

const ActivityColumnInput = ({ attributes, updateBooking }) => {
  const bookingCodeInitialValue = {
    value: attributes?.booking_code_id || "",
    label: attributes?.booking_code_name || "-"
  }
  const getBookingCodes = (inputText, absences = false) => {
    let params = {
      booking_id: attributes?.id,
      query: inputText,
      absences: absences
    }

    return axiosInstance.get(routes.bookingCodes(), { params: params })
      .then((response) => {
        return response.data.data.map((bookingCode) => ({
          value: bookingCode.id,
          label: bookingCode.attributes.booking_code_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  return (
    attributes?.is_absence_booking ?
      attributes?.can_edit_absence ?
        <EditableSelectInput
          id={attributes.id}
          attribute='booking_code_id'
          initialValue={bookingCodeInitialValue}
          handleSubmit={updateBooking}
          getEntities={(inputText) => getBookingCodes(inputText, true)}
        /> : attributes?.booking_code_name
      : attributes.can_edit_booking ?
        <EditableSelectInput
          id={attributes.id}
          attribute='booking_code_id'
          initialValue={bookingCodeInitialValue}
          handleSubmit={updateBooking}
          getEntities={getBookingCodes}
        /> : attributes?.booking_code_name
  )
};

export default ActivityColumnInput;
