import TableButton from '../../components/Table/TableButton';
import React, { useState } from 'react';
import classNames from 'classnames'
import { axiosInstance, routes } from '../../utils/api_base';
import Toastr from '../../components/Toastr';
import { useTranslation } from 'react-i18next';

const BusinessPartnersChooseCompany = ({active_business_partners, active_locations, current_location, enable_locations, current_company}) => {
  const { t } = useTranslation()
  const [currentLocation, setCurrentLocation] = useState(current_location)
  const [currentCompany, setCurrentCompany] = useState(current_company)
  const urlParams = new URLSearchParams(window.location.search)
  const backUrl = urlParams.get('back') || null

  const saveChanges = (type, url, object_id) => {
    const setState = {
      Company: setCurrentCompany,
      Location: setCurrentLocation,
    }

    axiosInstance.put(url)
      .then(response => {
        Toastr({
          title: t('success'),
          options: { closeButton: true }
        })
        setState[type](object_id)
        if (backUrl) window.location.href = backUrl
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: t('critical_error')
        })
      })
  }

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="card card-flush mb-xl-10">
            <div className="card-header pt-5">
              <h3 className="card-title text-gray-800 fw-bold">{t('change_company')}</h3>
            </div>
            <div className="card-body pt-5">
              {active_business_partners.map((company, index )=> (
                <>
                  {index != 0 && <div className="separator separator-dashed my-3"></div>}
                  <div key={company.id} className="d-flex flex-stack">
                    <a href={`/business_partners/${company.id}`} className="text-primary fw-semibold fs-6 me-2">({company.company_code}) {company.name}</a>
                    <TableButton
                      onClick={() => {
                        if (currentCompany != company.id) {
                          saveChanges('Company', routes.setCompany(company.id), company.id)
                        }
                      }}
                      className={classNames(currentCompany === company.id ? 'btn-success' : 'btn-primary')}
                      title={currentCompany === company.id ? t('current') : t('choose')}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        {enable_locations &&
          <div className="col-6">
            <div className="card card-flush mb-xl-10">
              <div className="card-header pt-5">
                <h3 className="card-title text-gray-800 fw-bold">{t('change_location')}</h3>
              </div>
              <div className="card-body pt-5">
                {active_locations.map((location, index) => (
                  <>
                    {index != 0 && <div className="separator separator-dashed my-3"></div>}
                    <div key={location.id} className="d-flex flex-stack">
                      <a href={`/locations/${location.id}`} className="text-primary fw-semibold fs-6 me-2">{location.name || location.id}</a>
                      <TableButton
                        onClick={() => {
                          if (currentLocation != location.id) {
                            saveChanges('Location', routes.setLocation(location.id), location.id)
                          }
                        }}
                        className={classNames(currentLocation === location.id ? 'btn-success' : 'btn-primary')}
                        title={currentLocation === location.id ? t('current') : t('choose')}
                      />
                    </div>
                  </>
                ))}
                <div className="separator separator-dashed my-3"></div>
                <div className="d-flex flex-stack">
                  <TableButton
                    onClick={() => saveChanges('Location', routes.resetLocation())}
                    className={'btn-primary'}
                    title={t('all_locations')}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default BusinessPartnersChooseCompany;
