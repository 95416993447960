import React from 'react';
import { useTranslation } from 'react-i18next';
import {axiosInstance, routes} from '../../utils/api_base';
import EditableTextInput from '../Table/EditableTextInput';
import EditableSelectInput from '../Table/EditableSelectInput';
import { statusLTE, statusLT } from '../../utils/status_helper';
import { PENDING } from '../../constants/statusCodes';

const DetailsPanel = ({order, updateOrder}) => {
  const appStore = ReactOnRails.getStore("appStore");
  const { statuses, currentCustomizing } = appStore.getState()?.appStore || {};
  const { t } = useTranslation()

  const shouldShowOffer = order.business_partner_offers_present && (order.offer || statusLT(statuses, order.status_id, PENDING)) || (order.farm_fertilizer && order.order_plant_waste_taker_offers_present)

  const getBusinessPartners = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      query: inputText
    }

    return axiosInstance.get(routes.businessPartners(), { params: params })
      .then((response) => {
        return response.data.data.map((bp) => ({
          value: bp.attributes.id,
          label: bp.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getSites = (inputText) => {
    let params = {
      business_partner_id: order.business_partner_id,
      page: 1,
      limit: 20,
      sort: 'name',
      serialize: 'short',
      query: inputText
    }

    return axiosInstance.get(routes.sites(), { params: params })
      .then((response) => {
        return response.data.data.map((site) => ({
          value: site.attributes.id,
          label: site.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  }

  const getOffers = (inputText) => {
    let params = {
      business_partner_id: order.business_partner_id,
      serialize: 'short',
      page: 1,
      limit: 20,
      sort: 'name',
      query: inputText
    }

    return axiosInstance.get(routes.offers(), { params: params })
      .then((response) => {
        return response.data.data.map((offer) => ({
          value: offer.attributes.id,
          label: offer.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  }

  return (
    <div className="flex-column flex-lg-row-auto w-100 w-xl-350px">
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold">Details</div>
          </div>
          <div className="fs-6">
            <div className="separator separator-dashed my-3"/>
            {
              order?.farm_fertilizer ? (
                <>
                  <div className="fw-bold mt-5">{t("farm_fertilizer")}</div>
                  <div className="text-gray-600">{order.farm_fertilizer}</div>
                </>
              ) : (
                <>
                  <div className="fw-bold mt-5">{t("customer")}</div>
                  <div className="text-gray-600">
                    <EditableSelectInput
                      id={order?.id}
                      attribute="business_partner_id"
                      initialValue={{ value: order?.business_partner_id, label: order?.business_partner_long_name }}
                      handleSubmit={updateOrder}
                      getEntities={getBusinessPartners}
                      editableAfterSubmit={false}
                    />
                  </div>
                </>
              )
            }
            {
              currentCustomizing.use_sites && (
                <>
                  <div className="separator separator-dashed my-3"/>
                  <div className="fw-bold mt-5">{t("site")}</div>
                  {
                    statusLTE(statuses, order.status_id, PENDING) ? (
                        <div className="text-gray-600">
                          <EditableSelectInput
                            id={order?.id}
                            attribute="site_id"
                            initialValue={{ value: order?.site_id, label: order?.site_name }}
                            handleSubmit={updateOrder}
                            getEntities={getSites}
                            editableAfterSubmit={false}
                          />
                        </div>
                    ) : (
                      <div className="text-gray-600">{order?.site_name}</div>
                    )
                  }
                </>
              )
            }
            {
              shouldShowOffer ? (
                <>
                  <div className="separator separator-dashed my-3"/>
                  <div className="fw-bold mt-5">{t("offer")}</div>
                  {order.business_partner_offers_present && (
                    statusLTE(statuses, order.status_id, PENDING) ? (
                      <div className="text-gray-600">
                        <EditableSelectInput
                          id={order?.id}
                          attribute="offer_id"
                          initialValue={{value: order?.offer_id, label: order?.offer_name}}
                          handleSubmit={updateOrder}
                          getEntities={getOffers}
                          editableAfterSubmit={false}
                        />
                      </div>
                    ) : (
                      <div className="text-gray-600">{`${t('provider')} ${order.offer_client_specific_number}`}</div>
                    )
                  )}
                </>
              ) : null
            }

            <div className="separator separator-dashed my-3"/>
            <div className="fw-bold mt-5">{t("created_by")}</div>
            <div className="text-gray-600">{order?.created_by}</div>

            {/*TODO: Add datetime range selector*/}
            <div className="separator separator-dashed my-3"/>
            <div className="fw-bold mt-5">{t("time_period")}</div>
            <div className="text-gray-600">{order?.created_by}</div>

            {/*TODO: Add volume selector*/}
            <div className="separator separator-dashed my-3"/>
            <div className="fw-bold mt-5">{t("volume")}</div>
            <div className="text-gray-600">{order?.created_by}</div>

            {/*TODO: Add services multiselect */}
            <div className="separator separator-dashed my-3"/>
            <div className="fw-bold mt-5">{t("services")}</div>
            <div className="text-gray-600">{order?.created_by}</div>


            <div className="separator separator-dashed my-3"/>
            <div className="fw-bold mt-5">{t("comment")}</div>
            <div className="text-gray-600">
              <EditableTextInput
                id={order?.id}
                attribute="comment"
                initialValue={order?.comment}
                handleSubmit={updateOrder}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPanel;
