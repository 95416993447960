import React, { useState } from 'react';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { TrashSquareIcon, PlusSquareIcon, EditNotepadIcon, CrossSquareIcon } from '../../Icons';
import Toastr from '../../Toastr';
import EditableSelectInput from '../../Table/EditableSelectInput';
import CreateMachineBookingModal from './CreateMachineBookingModal';
import * as style from './index.module.css';

const OrderColumnInputs = ({ attributes, setTrigger, deleteBooking, updateBooking, cancelBooking, staff_id, day }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectInputOpen, setSelectInputOpen] = useState(false);
  const { t } = useTranslation();
  const bookingMachines = attributes?.booking_machines
  const orderInitialValue = {
    value: attributes?.staff_order_id || "",
    label: attributes?.order_name || "-"
  }

  const getMachines = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      serialize: 'short',
      active: 'true',
      fahrzeug: 'true',
      query: inputText
    }

    return axiosInstance.get(routes.machines(), { params: params })
      .then((response) => {
        return response.data.data.map((machine) => ({
          value: machine.id,
          label: machine.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const createMachineBooking = (machine, leadingBookingId) => {
    const params = {
      id: leadingBookingId,
      machine_id: machine.machine.value,
      booking_type: "machine_booking"
    };

    axiosInstance.post(routes.bookings(), params)
      .then(resp => {
        Toastr({ message: t('booking_create_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const getStaffOrders = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      staff_id: staff_id,
      day: day,
      apply_status: "true",
      query: inputText,
    }

    return axiosInstance.get(routes.staffOrders(), { params: params })
      .then((response) => {
        return response.data.data.map((staffOrder) => ({
          value: staffOrder.id,
          label: staffOrder.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const EditSelectInput = () => {
    return (
      selectInputOpen && <EditableSelectInput
        id={attributes.id}
        attribute='staff_order_id'
        initialValue={orderInitialValue}
        handleSubmit={updateBooking}
        getEntities={getStaffOrders}
        editEnabled={true}
        closeSelectModal={setSelectInputOpen}
      />
    )
  };

  if (bookingMachines.length === 0 && !attributes?.can_add_machine) {
    return (
      <>
        <div>
          <a key={attributes.id} href={attributes.order_link}>{attributes.order_name}</a>
          {attributes.can_edit_booking ?
            <EditNotepadIcon
              onClick={() => { setSelectInputOpen(true) }}
            /> : null}
        </div>
        <EditSelectInput />
      </>
    )
  } else {
    return (
      <>
        <div className="d-flex py-3">
          <a key={attributes.id} href={attributes.order_link}>{attributes.order_name}</a>
          {attributes.can_edit_booking ?
            <EditNotepadIcon
              onClick={() => { setSelectInputOpen(true) }}
            /> : null}
          <div className="rotate collapsible" data-bs-toggle="collapse" href={`#booking_machines_${attributes.id}`} role="button">
            <span className="ms-2 rotate-180">
              <i className="ki-duotone ki-down fs-6"></i>
            </span>
          </div>
        </div>
        <EditSelectInput />
        <div className="my-3"></div>
        <div id={`booking_machines_${attributes.id}`} className="collapse">
          {bookingMachines.map((e) => (
            <small className='d-flex' key={e.booking_id}>
              <span>{e.machine_name}</span>
              {attributes.is_booked ? <div className={`ms-4 ${style['button-icon']}`} onClick={() => cancelBooking(e.booking_id)}>
                <CrossSquareIcon className="fs-2 text-danger" />
              </div> :
                <div className={`ms-4 ${style['button-icon']}`} onClick={() => deleteBooking(e.booking_id)}>
                  <TrashSquareIcon className="fs-2 text-danger" />
                </div>}
            </small>
          ))}
          <small>
            <CreateMachineBookingModal
              open={modalOpen}
              setOpen={setModalOpen}
              onClose={() => setModalOpen(false)}
              attribute={"machine"}
              getEntities={getMachines}
              handleSubmit={(machine) => { createMachineBooking(machine, attributes?.id) }}
            />
            <span className={`${style['add-button']}`} onClick={() => setModalOpen(true)}>
              <PlusSquareIcon className={`${style['add-button']} mt-2 fs-2 text-success`} onClick={() => setModalOpen(true)} />
            </span>
          </small>
        </div>
      </>
    )
  }
};

export default OrderColumnInputs;
