import BaseTable from '../../../components/Table';
import EditableTextInput from '../../../components/Table/EditableTextInput';
import Form from 'react-bootstrap/Form';
import React, { useMemo, useState, useEffect} from 'react';
import Toastr from '../../../components/Toastr';
import { TrashSquareIcon } from '../../../components/Icons';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../../utils/api_base';
import {isEmpty} from 'lodash';
import EditableSelectInput from "../../../components/Table/EditableSelectInput";

const Table = ({ tableToolbar, filters, columnVisibility, businessPartnerId, trigger, setTrigger, currentStaffId }) => {
  const { t, i18n } = useTranslation()
  const areaOptions = [{ value: 'comment_invoice', label: t('comment_invoice') }, { value: 'comment_order', label: t('comment_order') }]

  const getComments = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.businessPartnerComments(), { params: { ...params, business_partner_id: businessPartnerId } })

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateContact = (id, params) => {
    if (params.comment_area){ params.comment_area = params.comment_area.value }
    axiosInstance.put(routes.businessPartnerComment(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteComment = (id) => {
    axiosInstance.delete(routes.businessPartnerComment(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    return (
      currentStaffId == row.original?.attributes?.staff?.id &&
      <div onClick={() => deleteComment(row.original.id)}><TrashSquareIcon className="fs-2hx text-danger cursor-pointer" /></div>
    )
  }

  const commentHtml = (row) => {
    return (
      currentStaffId == row.original?.attributes?.staff?.id ?
        <EditableTextInput id={row.original.id} attribute='comment' initialValue={row.original?.attributes?.comment} handleSubmit={updateContact} />
        :
        row.original?.attributes?.comment
    )
  }

  const getAreaOptions = async () => {
   return await areaOptions
  }

  const findSelectedCommentArea = (comment_area) => {
    return areaOptions.find(item => item.value === comment_area) || {}
  }

  const commentAreaHtml = (row) => {
    return (
      <EditableSelectInput
        id={row.original.id}
        initialValue={findSelectedCommentArea(row.original?.attributes?.comment_area)}
        attribute={'comment_area'}
        handleSubmit={updateContact}
        getEntities={getAreaOptions}
      />
    )
  }

  const displayCommentHtml = (row) => {
    const isActive = row.original.attributes?.display_comment
    const label = isActive ? t('yes') : t('no')

    return (
      <Form.Check
        onChange={() => updateContact(row.original.id, { display_comment: !isActive })}
        checked={isActive}
        type="switch"
        id={row.original.id}
        label={label}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'author',
        header: t('author'),
        size: 120,
        minSize: 120,
        accessorFn: row => row?.attributes?.staff?.attributes?.name,
        cell: row => row.getValue()
      },
      {
        id: 'comment',
        header: t('comment'),
        size: 120,
        minSize: 120,
        accessorFn: row => row?.attributes?.comment,
        cell: ({ row }) => commentHtml(row)
      },
      {
        id: 'display_comment',
        header: t('display_comment'),
        accessorFn: row => row?.attributes?.display_comment,
        cell: ({ row }) => displayCommentHtml(row)
      },
      {
        id: 'area',
        header: t('comment_area'),
        accessorFn: row => row?.attributes?.comment_area,
        cell: ({ row }) => commentAreaHtml(row)
      },
      {
        id: 'date',
        header: t('date'),
        accessorFn: row => row?.attributes?.created_at,
        cell: row => row.getValue()
      },
      {
        size: 70,
        minSize: 70,
        header: t('actions'),
        id: 'action',
        cell: ({ row }) => deleteHtml(row)
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getComments}
      columns={columns}
      columnVisibility={columnVisibility}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection={'asc'}
      trigger={trigger}
    />
  )
}

export default Table;
