import React, { useMemo, useState } from 'react';
import { axiosInstance, routes } from '../../../utils/api_base'
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../../utils/base';
import BaseTable from '../../../components/Table';
import { isEmpty } from 'lodash';
import EditableTextInput from '../../../components/Table/EditableTextInput';
import Toastr from '../../../components/Toastr';
import Form from 'react-bootstrap/Form';
import { TrashSquareIcon } from '../../../components/Icons';
import EditableSelectInput from "../../../components/Table/EditableSelectInput";
import CurrencyInput from "../../../components/Table/EditableCurrencyInput";

const Table = ({ filters, tableToolbar, businessPartnerId, trigger, setTrigger }) => {
  const { t, i18n } = useTranslation()

  const getMachines = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.machines(), { params: { ...params, rental: false, serialize: 'full', sub_contractor_id: businessPartnerId } })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateMachine = (id, params) => {
    const data = {
      machine: {
        active: params.active,
        show_in_dispo: params.show_in_dispo,
        name: params.name,
        client_specific_number: params.client_specific_number,
        cost_per_unit: params.cost_per_unit
      },
      machine_detail: {
        machine_group_id: params.machine_group_id?.value
      }
    }

    axiosInstance.put(routes.machine(id), data)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const getMachineGroups = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      query: inputText
    }

    return axiosInstance.get(routes.machineGroups(), { params: params })
      .then((response) => {
        return response.data.data.map((staff) => ({
          value: staff.id,
          label: staff.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const deleteMachine = (id) => {
    axiosInstance.delete(routes.machine(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    return (
      <div onClick={() => deleteMachine(row.original.id)}><TrashSquareIcon className="fs-2hx text-danger cursor-pointer" /></div>
    )
  }

  const ActiveHtml = (row) => {
    const isActive = row.original.attributes?.active
    const label = isActive ? t('true') : t('false')

    return (
      <Form.Check
        onChange={() => updateMachine(row.original.id, { active: !isActive })}
        checked={isActive}
        type="switch"
        id={`active-machine-${row.original.id}`}
        label={label}
      />
    )
  }

  const InPlanningHtml = (row) => {
    const isActive = row.original.attributes?.show_in_dispo
    const label = isActive ? t('true') : t('false')

    return (
      <Form.Check
        onChange={() => updateMachine(row.original.id, { show_in_dispo: !isActive })}
        checked={isActive}
        type="switch"
        id={`show-in-dispo-machine-${row.original.id}`}
        label={label}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='client_specific_number' initialValue={row.original.attributes.client_specific_number} handleSubmit={updateMachine} />
      },
      {
        id: 'name',
        accessorFn: row => row?.attributes?.name,
        header: t('shared.name'),
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='name' initialValue={row.original.attributes.name} handleSubmit={updateMachine} />
      },
      {
        id: 'machine_group',
        accessorFn: row => row?.attributes?.group_name,
        header: t('shared.machine_group'),
        cell: ({ row }) => (
          <EditableSelectInput
            id={row.original.id}
            attribute='machine_group_id'
            getEntities={getMachineGroups}
            initialValue={{value: row.original.attributes?.machine_group_id, label: row.original.attributes?.group_name}}
            handleSubmit={updateMachine}
          />
        )
      },
      {
        id: 'cost_per_unit',
        accessorFn: row => row?.attributes?.cost_per_unit,
        header: t('shared.cost_rate'),
        cell: ({ row }) => (
          <CurrencyInput
            id={row.original.id}
            attribute="cost_per_unit"
            initialValue={row.original.attributes.cost_per_unit_cents / 10000}
            label={`${row.original.attributes.cost_per_unit_cents / 10000} €`}
            handleSubmit={updateMachine}
          />
        )
      },
      {
        id: 'active',
        header: t('active_question'),
        accessorFn: row => row?.attributes?.active,
        cell: ({ row }) => ActiveHtml(row)
      },
      {
        id: 'show_in_dispo',
        header: t('in_planning_question'),
        accessorFn: row => row?.attributes?.show_in_dispo,
        cell: ({ row }) => InPlanningHtml(row)

      },
      {
        id: 'actions',
        cell: ({ row }) => deleteHtml(row)
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getMachines}
      tableToolbar={tableToolbar}
      filters={filters}
      tableName={'Machines'}
      trigger={trigger}
      initialSortDirection={'asc'}
    />
  )
}

export default Table
