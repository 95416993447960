import React from "react";

const Trash = ({ className = null }) => {
  return <i className={`ki-duotone ki-trash ${className}`}>
    <span className="path1"></span>
    <span className="path2"></span>
    <span className="path3"></span>
    <span className="path4"></span>
    <span className="path5"></span>
  </i>
}

export default Trash;
