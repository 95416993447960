import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../../components/Table/EditableTextInput';
import { QuestionIcon } from '../Icons';
import EditableSelectInput from "../../components/Table/EditableSelectInput";
import { Tooltip } from 'bootstrap'
import TableButton from '../../components/Table/TableButton';

const CustomizingModal = ({ onClose, paymentTerms }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};
  const [customizingDetails, setCustomizingDetails] = useState();

  const findPaymentTerms = () => {
    const termItem = paymentTerms.find(item => item.value === currentCustomizing?.customer_payment_term_id)
    return (termItem)
  }

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [loading])

  useEffect(() => {
    currentCustomizing && setLoading(false)
    setCustomizingDetails(
      {
        use_short_name_in_business_partners: currentCustomizing.use_short_name_in_business_partners,
        use_business_partner_groups: currentCustomizing.use_business_partner_groups,
        use_solvency_in_business_partners: currentCustomizing.use_solvency_in_business_partners,
        use_order_limits: currentCustomizing.use_order_limits,
        use_yearly_treaties_in_business_partners: currentCustomizing.use_yearly_treaties_in_business_partners,
        use_bills_for_diesel_book: currentCustomizing?.use_bills_for_diesel_book,
        normative_from_bills_diesel_book: currentCustomizing.normative_from_bills_diesel_book,
        show_ust_id_in_bills: currentCustomizing.show_ust_id_in_bills,
        show_vvo_number_in_bills: currentCustomizing.show_vvo_number_in_bills,
        farmer_can_see_booking_results: currentCustomizing.farmer_can_see_booking_results,
        customer_payment_term: findPaymentTerms(),
        customer_net_days: currentCustomizing.customer_net_days,
        customer_cash_discount_days: currentCustomizing.customer_cash_discount_days,
        customer_cash_discount_perc: currentCustomizing.customer_cash_discount_perc
      }
    )
  }, [currentCustomizing]);

  const getPaymentTerms = async () => {
    return await paymentTerms
  }

  const updateCustomizing = (id, attrs) => {
    const params = {
      customizing: {
        use_short_name_in_business_partners: attrs.use_short_name_in_business_partners,
        use_business_partner_groups: attrs.use_business_partner_groups,
        use_solvency_in_business_partners: attrs.use_solvency_in_business_partners,
        use_order_limits: attrs.use_order_limits,
        use_yearly_treaties_in_business_partners: attrs.use_yearly_treaties_in_business_partners,
        use_bills_for_diesel_book: attrs.use_bills_for_diesel_book,
        normative_from_bills_diesel_book: attrs.normative_from_bills_diesel_book,
        show_ust_id_in_bills: attrs.show_ust_id_in_bills,
        show_vvo_number_in_bills: attrs.show_vvo_number_in_bills,
        farmer_can_see_booking_results: attrs.farmer_can_see_booking_results,
        customer_payment_term_id: attrs.customer_payment_term?.value,
        customer_net_days: attrs.customer_net_days,
        customer_cash_discount_days: attrs.customer_cash_discount_days,
        customer_cash_discount_perc: attrs.customer_cash_discount_perc
      }
    }

    axiosInstance.put(routes.customizing(currentCustomizing?.id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setCustomizingDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const toggleHtml = (attribute) => {
    const isActive = customizingDetails[attribute]
    const label = isActive ? 'true' : 'false'
    return (
      <Form.Check
        onChange={() => updateCustomizing(currentCustomizing.id, { [attribute]: !isActive })}
        checked={isActive}
        className="cursor-pointer"
        type="switch"
        id={attribute}
        label={t(label)}
      />
    )
  }

  return (
    <>
      {loading ? (
        <div className="text-center d-flex py-5 h-300px align-items-center justify-content-center">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          <div className="modal-body mh-600px overflow-auto">
            <h4 className='mb-4 text-gray-500' >{t('customizing_modal.customer_master_data')}</h4>
            <div className="py-2 fs-6">
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.customer_can_have_nicknames')}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('customizing_modal.customer_can_have_nicknames_titles')}
                    className="btn p-0 text-hover-primary"
                  >
                    <QuestionIcon className={"fs-4"} />
                  </span>
                </div>
                <div className="text-gray-600">
                  {toggleHtml('use_short_name_in_business_partners')}
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.customer_can_be_assigned_company_groups')}
                </div>
                <div className="text-gray-600 d-flex align-items-center">
                  <TableButton
                    href="/business_partner_groups"
                    className="btn-primary btn-sm py-1 px-2 me-3"
                    title={t('company_groups')}
                  />
                  {toggleHtml('use_business_partner_groups')}
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.creditworthiness_maintained_customer')}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    data-bs-html="true"
                    title={t('customizing_modal.creditworthiness_maintained_customer_title')}
                    className="btn p-0 text-hover-primary"
                  >
                    <QuestionIcon className={"fs-4"} />
                  </span>
                </div>
                <div className="text-gray-600">
                  {toggleHtml('use_solvency_in_business_partners')}
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.use_limits_for_contracting')}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    data-bs-html="true"
                    title={t('customizing_modal.use_limits_for_contracting_title')}
                    className="btn p-0 text-hover-primary"
                  >
                    <QuestionIcon className={"fs-4"} />
                  </span>
                </div>
                <div className="text-gray-600">
                  {toggleHtml('use_order_limits')}
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.contracts_with_respective_terms_stored_customer')}
                </div>
                <div className="text-gray-600">
                  {toggleHtml('use_yearly_treaties_in_business_partners')}
                </div>
              </div>
              <div className="separator my-3"></div>
              <h4 className='mb-4 mt-6 text-gray-500'>{t('diesel_book')}</h4>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.diesel_book_structured_question')}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    data-bs-html="true"
                    title={t('customizing_modal.diesel_book_structured_question_title')}
                    className="btn p-0 text-hover-primary"
                  >
                    <QuestionIcon className={"fs-4"} />
                  </span>
                </div>
                <div className="text-gray-600">
                  <div className='d-flex'>
                    <Form.Check
                      onChange={() => updateCustomizing(currentCustomizing.id, { use_bills_for_diesel_book: true })}
                      checked={customizingDetails.use_bills_for_diesel_book}
                      className="cursor-pointer me-3"
                      type="radio"
                      id={'use_bills_for_diesel_book_true'}
                      label={t('by_invoice')}
                    />
                    <Form.Check
                      onChange={() => updateCustomizing(currentCustomizing.id, { use_bills_for_diesel_book: false })}
                      checked={!customizingDetails.use_bills_for_diesel_book}
                      className="cursor-pointer"
                      type="radio"
                      id={'use_bills_for_diesel_book_false'}
                      label={t('by_order')}
                    />
                  </div>
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.diesel_according_to_normative_question')}
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('customizing_modal.diesel_according_to_normative_question_title')}
                    className="btn p-0 text-hover-primary"
                  >
                    <QuestionIcon className={"fs-4"} />
                  </span>
                </div>
                <div className="text-gray-600">
                  {toggleHtml('normative_from_bills_diesel_book')}
                </div>
              </div>
              <div className="separator my-3"></div>
              <h4 className='mb-4 mt-6 text-gray-500'>{t('payment_history')}</h4>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.default_payment_method')}
                </div>
                <div className="text-gray-600">
                  <EditableSelectInput
                    id={currentCustomizing.id}
                    attribute={'customer_payment_term'}
                    initialValue={{ value: customizingDetails.customer_payment_term?.value, label: customizingDetails.customer_payment_term?.label }}
                    handleSubmit={updateCustomizing}
                    getEntities={getPaymentTerms}
                  />
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.default_payment_term')}
                </div>
                <div className="text-gray-600 d-flex">
                  <EditableTextInput
                    id={customizingDetails.id}
                    attribute="customer_net_days"
                    initialValue={customizingDetails.customer_net_days}
                    handleSubmit={updateCustomizing}
                  />
                  <span className="ms-2">{t('time_expressions.days')}</span>
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.standard_discount_days')}
                </div>
                <div className="text-gray-600 d-flex">
                  <EditableTextInput
                    id={customizingDetails.id}
                    attribute="customer_cash_discount_days"
                    initialValue={customizingDetails.customer_cash_discount_days}
                    handleSubmit={updateCustomizing}
                  />
                  <span className="ms-2">{t('time_expressions.days')}</span>
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.default_discount_percentage')}
                </div>
                <div className="text-gray-600 d-flex">
                  <EditableTextInput
                    id={customizingDetails.id}
                    attribute="customer_cash_discount_perc"
                    initialValue={customizingDetails.customer_cash_discount_perc || 0}
                    handleSubmit={updateCustomizing}
                  />
                  <span className="ms-2">%</span>
                </div>
              </div>
              <div className="separator my-3"></div>
              <h4 className="mb-4 mt-6 text-gray-500">
                {t('customizing_modal.generation_of_the_invoice')}
              </h4>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.show_vat_id')}
                </div>
                <div className="text-gray-600">
                  {toggleHtml('show_ust_id_in_bills')}
                </div>
              </div>
              <div className="separator my-3"></div>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.show_vvo_number')}
                </div>
                <div className="text-gray-600">
                  {toggleHtml('show_vvo_number_in_bills')}
                </div>
              </div>
              <div className="separator my-3"></div>
              <h4 className='mb-4 mt-6 text-gray-500'>{t('other')}</h4>
              <div className='d-flex flex-stack'>
                <div className="fw-bold">
                  {t('customizing_modal.driver_can_see_driver_input_in_customer_login')}
                </div>
                <div className="text-gray-600">
                  {toggleHtml('farmer_can_see_booking_results')}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default CustomizingModal;
