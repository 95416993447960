import BaseTable from '../../Table';
import React, { useMemo } from 'react';
import TableButton from '../../Table/TableButton';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';

const Table = ({tableToolbar, businessPartnerId }) => {
  const { t, i18n } = useTranslation()

  const getData = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    const params = { page: pageIndex + 1, limit: 10, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.plantWasteAnalyses(), { params: { ...params, business_partner_id: businessPartnerId } })
    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.plant_waste_name}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'farm_manure_type',
        header: t('type_of_farm_manure'),
        accessorFn: row => row?.attributes?.id,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'date',
        header: t('analysis_date'),
        accessorFn: row => row?.attributes?.analysis_date,
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getData}
      tableToolbar={tableToolbar}
      tableName={'PlantWasteAnalyse'}
    />
  )
}

export default Table;
