import React from "react";
import { useTranslation } from 'react-i18next';
import StaffTextInput from "../StaffTextInput";
import StaffSelectInput from "../StaffSelectInput";
import { axiosInstance, routes } from '../../../utils/api_base';

const StaffDetails = ({ staff, updateStaff }) => {
  const { t } = useTranslation()

  const getCompanies = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      company: "true",
      query: inputText
    }

    return axiosInstance.get(routes.businessPartners(), { params: params })
      .then((response) => {
        return response.data.data.map((businessPartner) => ({
          value: businessPartner.attributes.id,
          label: businessPartner.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getLocations = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.locations(), { params: params })
      .then((response) => {
        return response.data.data.map((locations) => ({
          value: locations.attributes.id,
          label: locations.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getCountries = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.countries(), { params: params })
      .then((response) => {
        return response.data.data.map((country) => ({
          value: country.attributes.id,
          label: country.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  return (
    <div className="d-flex row">
      <div className="col-12 col-lg-6">
        <div className="card mb-5 mb-xl-8">
          <div className="card-body pt-2">
            <div className="py-2 fs-6">
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.position}
                translation={t("position")}
                attribute={"position"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.birth_location}
                translation={t("birthplace")}
                attribute={"birth_location"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.education}
                translation={t("training")}
                attribute={"education"}
                updateStaff={updateStaff}
              />
              <StaffSelectInput
                staffId={staff.id}
                initialValue={{ value: staff.company_id, label: staff.company_name }}
                translation={t("company")}
                attribute={"company"}
                updateStaff={updateStaff}
                getEntities={getCompanies}
              />
              <StaffSelectInput
                staffId={staff.id}
                initialValue={{ value: staff.location_id, label: staff.location_name }}
                translation={t("location")}
                attribute={"location"}
                updateStaff={updateStaff}
                getEntities={getLocations}
              />
              <StaffSelectInput
                staffId={staff.id}
                initialValue={{ value: staff.country_id, label: staff.country_name }}
                translation={t("country")}
                attribute={"country"}
                updateStaff={updateStaff}
                getEntities={getCountries}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.comment}
                translation={t("comment")}
                attribute={"comment"}
                updateStaff={updateStaff}
                tooltipText={t('comment_staff_question_title')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="card mb-5 mb-xl-8">
          <div className="card-body pt-2">
            <div className="py-2 fs-6">
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.fon_private}
                translation={t("phone_private")}
                attribute={"fon_private"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.mobile_private}
                translation={t("mobile_private")}
                attribute={"mobile_private"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.fon_company}
                translation={t("phone_work")}
                attribute={"fon_company"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.mobile_company}
                translation={t("mobile_work")}
                attribute={"mobile_company"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.fax_company}
                translation={t("fax_work")}
                attribute={"fax_company"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staff.invoice_email}
                translation={t("email_for_documents")}
                attribute={"invoice_email"}
                updateStaff={updateStaff}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default StaffDetails;
