import React from "react";
import { useTranslation } from 'react-i18next';
import StaffToggleInput from "../StaffToggleInput";
import StaffDatePickerInput from "../StaffDatePickerInput";
import Button from "../../Button";

const DriverLicenses = ({ staff, currentStaff, updateStaff }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#master-data" role="button">
          {t('driver_license_classes').toUpperCase()}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="master-data" className="collapse show">
        <div className="d-flex row">
          <div className="col-12 col-lg-6 mb-10">
            <div className="card mb-5 mb-xl-8">
              <div className="card-body pt-2">
                <div className="py-2 fs-6">
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.motorcycle_a}
                    translation={t("licenses.motorbike_a")}
                    attribute={"motorcycle_a"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.motorbike_a1}
                    translation={t("licenses.motorbike_a1")}
                    attribute={"motorcycle_a1"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.motorbike_m}
                    translation={t("licenses.motorbike_m")}
                    attribute={"motorcycle_m"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.passenger_car_b}
                    translation={t("licenses.pkw_b")}
                    attribute={"passenger_car_b"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.passenger_car_be}
                    translation={t("licenses.pkw_be")}
                    attribute={"passenger_car_be"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.passenger_car_s}
                    translation={t("licenses.pkw_s")}
                    attribute={"passenger_car_s"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.special_class_t}
                    translation={t("licenses.special_class_t")}
                    attribute={"special_class_t"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.special_class_l}
                    translation={t("licenses.special_class_l")}
                    attribute={"special_class_l"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.pest_management}
                    translation={t("licenses.crop_protection")}
                    attribute={"pest_management"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.chopper}
                    translation={t("licenses.chopper")}
                    attribute={"chopper"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.combine_harvester}
                    translation={t("licenses.harvester")}
                    attribute={"combine_harvester"}
                    handleToggle={updateStaff}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-10">
            <div className="card mb-5 mb-xl-8">
              <div className="card-body pt-2">
                <div className="py-2 fs-6">
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.truck_c}
                    translation={t("licenses.lkw_c")}
                    attribute={"truck_c"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.truck_ce}
                    translation={t("licenses.lkw_ce")}
                    attribute={"truck_ce"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.truck_c1}
                    translation={t("licenses.lkw_c1")}
                    attribute={"truck_c1"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.truck_c1e}
                    translation={t("licenses.lkw_c1e")}
                    attribute={"truck_c1e"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.bus_d}
                    translation={t("licenses.bus_d")}
                    attribute={"bus_d"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.bus_de}
                    translation={t("licenses.bus_de")}
                    attribute={"bus_de"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.bus_d1}
                    translation={t("licenses.bus_d1")}
                    attribute={"bus_d1"}
                    handleToggle={updateStaff}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    active={staff.bus_d1e}
                    translation={t("licenses.bus_d1e")}
                    attribute={"bus_d1e"}
                    handleToggle={updateStaff}
                  />
                  <StaffDatePickerInput
                    staffId={staff.id}
                    initialValue={staff.truck_end}
                    translation={t("licenses.expiry_date_truck")}
                    attribute={"truck_end"}
                    updateStaff={updateStaff}
                  />
                  <Button
                    onClick={() => {
                      const attrs = {
                        "driver_licence_controlled_at": new Date().toISOString().split('T')[0],
                        "driver_licence_controlled_by_id": currentStaff.id,
                      };

                      updateStaff(staff.id, attrs)
                    }}
                  >
                    {t("mark_as_controlled")}
                  </Button>
                  {staff.driver_licence_controlled_at && <span>{t("mark_as_controlled_text", { date: staff.driver_licence_controlled_at, name: staff.driver_licence_controlled_by_name })}</span>}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};


export default DriverLicenses;
