import React from 'react';
import Table from './Table';

const ServiceBusinessPartnerStatistics = ({ service_id, company_id, filters, businessPartnerId, tab}) => {

  const tableToolbar = () => {
    return (<></>)
  }

  return (
    <Table tabName={tab} service_id={service_id} businessPartnerId={businessPartnerId} company_id={company_id} tableToolbar={tableToolbar} filters={filters} />
  )
}

export default ServiceBusinessPartnerStatistics;
