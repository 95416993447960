import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { axiosInstance, routes } from '../../utils/api_base';
import EditableTextInput from '../Table/EditableTextInput';
import EditableSelectInput from '../Table/EditableSelectInput';

const DetailsPanel = ({ machine, updateMachine }) => {
  const appStore = ReactOnRails.getStore("appStore");
  const { currentUser, currentCustomizing } = appStore.getState()?.appStore || {};
  const { t } = useTranslation()

  const HPToCW = (hp) => {
    return Math.round(hp * 0.735499)
  }

  const toggleHtml = (attribute) => {
    const isActive = machine[attribute]
    const label = isActive ? t('yes') : t('no')
    return (
      <Form.Check
        onChange={() => updateMachine(machine.id, { [attribute]: !isActive })}
        checked={isActive}
        type="switch"
        id="combination-active-switch"
        label={label}
      />
    )
  }

  const getCostCentres = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.costCenters(), { params: params })
      .then((response) => {
        return response.data.data.map((cc) => ({
          value: cc.attributes.id,
          label: cc.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getMachineGroups = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      query: inputText
    }

    return axiosInstance.get(routes.machineGroups(), { params: params })
      .then((response) => {
        return response.data.data.map((mac) => ({
          value: mac.attributes.id,
          label: mac.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getMachineGroupForServiceFeatures = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.machineGroupForServiceFeatures(), { params: params })
      .then((response) => {
        return response.data.data.map((mgfsf) => ({
          value: mgfsf.attributes.id,
          label: mgfsf.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getBusinessPartners = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      company: true,
      query: inputText
    }

    return axiosInstance.get(routes.businessPartners(), { params: params })
      .then((response) => {
        return response.data.data.map((bp) => ({
          value: bp.attributes.id,
          label: bp.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getLocations = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      company: true,
      query: inputText
    }

    return axiosInstance.get(routes.locations(), { params: params })
      .then((response) => {
        return response.data.data.map((loc) => ({
          value: loc.attributes.id,
          label: loc.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  return (
    <div className="flex-column flex-lg-row-auto w-100 w-xl-350px">
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-center flex-column">
            <div className="fs-3 text-gray-800 fw-bold mb-1">
              <EditableTextInput
                id={machine.id}
                attribute="name"
                initialValue={machine.name}
                handleSubmit={updateMachine}
              />
            </div>
            <div className="fs-5 fw-semibold text-muted mb-6">
              <EditableTextInput
                id={machine.id}
                attribute="client_specific_number"
                initialValue={machine.client_specific_number}
                handleSubmit={updateMachine}
              />
            </div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold">Details</div>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="fs-6">
            <div className="fw-bold mt-5">{t("active_question")}</div>
            <div className="text-gray-600">
              {toggleHtml('active')}
            </div>
            {machine.active && currentCustomizing.mark_ressource_to_show_in_dispo &&
              <>
                <div className="fw-bold mt-5">{t('is_available_in_disposition_question')}</div>
                <div className="text-gray-600">
                  {toggleHtml('show_in_dispo')}
                </div>
              </>
            }
            <div className="fw-bold mt-5">{t("cost_centre")}</div>
            <div className="text-gray-600">
              <EditableSelectInput
                id={machine.id}
                attribute="cost_center_id"
                initialValue={{ value: machine.cost_center_id, label: machine.cost_center_name }}
                handleSubmit={updateMachine}
                getEntities={getCostCentres}
                editableAfterSubmit={false}
              />
            </div>
            <div className="fw-bold mt-5">{t("shared.machine_group")}</div>
            <div className="text-gray-600">
              <EditableSelectInput
                id={machine.id}
                attribute="machine_group_id"
                initialValue={{ value: machine.machine_group_id, label: machine.machine_group_name }}
                handleSubmit={updateMachine}
                getEntities={getMachineGroups}
                editableAfterSubmit={false}
              />
            </div>
            <div className="fw-bold mt-5">{t("machine_type")}</div>
            <div className="text-gray-600">{machine.machine_type}</div>
            {
              currentCustomizing.use_machine_group_for_service_feature &&
              <>
                <div className="fw-bold mt-5">{t('machine_group_for_price_surcharges')}</div>
                <div className="text-gray-600">
                  <EditableSelectInput
                    id={machine.id}
                    attribute="machine_group_for_service_feature_id"
                    initialValue={{
                      value: machine.machine_group_for_service_feature_id,
                      label: machine.machine_group_for_service_feature_name
                    }}
                    handleSubmit={updateMachine}
                    getEntities={getMachineGroupForServiceFeatures}
                    editableAfterSubmit={false}
                  />
                </div>
              </>
            }
            <div className="fw-bold mt-5">{t("manufacturer")}</div>
            <div className="text-gray-600">
              <EditableTextInput
                id={machine.id}
                attribute="manufacturer"
                initialValue={machine.manufacturer}
                handleSubmit={updateMachine}
              />
            </div>
            <div className="fw-bold mt-5">{t("hp")}</div>
            <div className="text-gray-600">
              <EditableTextInput
                id={machine.id}
                attribute="hp"
                initialValue={machine.hp}
                handleSubmit={updateMachine}
              />
            </div>
            <div className="fw-bold mt-5">{t("kw")}</div>
            <div className="text-gray-600">{machine.hp && HPToCW(machine.hp)}</div>
            <div className="fw-bold mt-5">{t("year_of_manufacture")}</div>
            <div className="text-gray-600">
              <EditableTextInput
                id={machine.id}
                attribute="year_of_manufacture"
                initialValue={machine.year_of_manufacture}
                handleSubmit={updateMachine}
              />
            </div>
            <div className="fw-bold mt-5">{t("shared.rental_machine")}</div>
            <div className="text-gray-600">
              {toggleHtml('rental')}
            </div>
            <div className="fw-bold mt-5">{t("acquisition_date")}</div>
            <div className="text-gray-600">
              <EditableTextInput
                id={machine.id}
                attribute="acquisition_date"
                initialValue={machine.acquisition_date}
                handleSubmit={updateMachine}
              />
            </div>
            <div className="fw-bold mt-5">{machine.rental ? t('machine_new_value') : t("acquisition_price")}</div>
            <div className="text-gray-600">
              <EditableTextInput
                id={machine.id}
                attribute="purchase_price"
                initialValue={machine.purchase_price}
                handleSubmit={updateMachine}
              />
            </div>
            <div className="fw-bold mt-5">{t("owner")}</div>
            <div className="text-gray-600">
              <EditableSelectInput
                id={machine.id}
                attribute="company_id"
                initialValue={{ value: machine.company_id, label: machine.company_name }}
                handleSubmit={updateMachine}
                getEntities={getBusinessPartners}
                editableAfterSubmit={false}
              />
            </div>
            <div className="fw-bold mt-5">{t("user")}</div>
            <div className="text-gray-600">
              <EditableSelectInput
                id={machine.id}
                attribute="user_id"
                initialValue={{ value: machine.user_id, label: machine.user_name }}
                handleSubmit={updateMachine}
                getEntities={getBusinessPartners}
                editableAfterSubmit={false}
              />
            </div>
            <div className="fw-bold mt-5">{t("location")}</div>
            <div className="text-gray-600">
              <EditableSelectInput
                id={machine.id}
                attribute="location_id"
                initialValue={{ value: machine.location_id, label: machine.location_name }}
                handleSubmit={updateMachine}
                getEntities={getLocations}
                editableAfterSubmit={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPanel;
