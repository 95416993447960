import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import Table from './Table';
import DatePicker from '../../components/DatePicker';

const RemunerationPayrolls = ({ visibility = {} }) => {
  const { t, i18n } = useTranslation()
  const columnVisibility = {
    special_surcharge: visibility.show_bonus_payment,
    company_name: visibility.show_company_name
  }

  const urlParams = new URLSearchParams(window.location.search)
  const month = urlParams.get('month') ?? (new Date().getMonth() + 1).toString().padStart(2, '0')
  const year = urlParams.get('year') ?? (new Date()).getFullYear()
  const [selectedDate, setSelectedDate] = useState({ year: year, month: month })

  const onChangeDatePicker = (selectedDates, dateStr) => {
    const [month, year] = i18n.language === "de" ? dateStr.split('.') : dateStr.split('-')
    setSelectedDate({ year: year, month: month })
  }

  const tableToolbar = () => {
    return (
      <DatePicker monthOnly options={{
        defaultDate: `${selectedDate.month}-${selectedDate.year}`,
        dateFormat: i18n.language === "de" ? 'm.Y' : 'm-Y',
        onChange: onChangeDatePicker
      }} />
    )
  }

  return (
    <Table tableToolbar={tableToolbar}
      columnVisibility={columnVisibility}
      year={selectedDate.year}
      month={selectedDate.month}
    />
  )
}

export default RemunerationPayrolls;
