import CommentsTable from '../../components/BusinessPartners/CommentsTable';
import ContactsTable from '../../components/BusinessPartners/ContactsTable';
import DetailsPanel from './detailPanel';
import DieselBook from '../../components/BusinessPartners/DieselBook';
import DocumentOfBusinessPartners from '../../pages/DocumentOfBusinessPartners';
import InvoiceTable from '../../components/BusinessPartners/InvoiceTable';
import OfferTable from '../../components/BusinessPartners/OfferTable';
import OrderPlantWasteTable from '../../components/BusinessPartners/OrderPlantWasteTable';
import OrderTable from '../../components/BusinessPartners/OrdersTable';
import PlantWasteAnalyse from '../../components/BusinessPartners/PlantWasteAnalyses';
import React from 'react';
import ReminderTable from '../../components/BusinessPartners/ReminderTable';
import SitesTable from '../../components/BusinessPartners/ConstructionSitesTable';
import { useTranslation } from 'react-i18next';

const Overview = ({ businessPartnerId, orderLimitChangedBy, currentStaffId,
                    companyId, businessPartner, paymentTerms, solvency, solvencies, solvencyChangedBy, country }) => {
  const { t } = useTranslation();
  const appStore = ReactOnRails.getStore("appStore");
  const { currentCustomizing } = appStore.getState()?.appStore || {};

  return (
    <>
      <DetailsPanel
        businessPartner={businessPartner}
        country={country}
        paymentTerms={paymentTerms}
        currentCustomizing={currentCustomizing}
        solvency={solvency}
        solvencies={solvencies}
        solvencyChangedBy={solvencyChangedBy}
        orderLimitChangedBy={orderLimitChangedBy}
      />
      {!businessPartner.company && (
        <>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#contacts" role="button">
              {t('contacts')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="contacts" className="collapse show">
            <ContactsTable businessPartnerId={businessPartnerId} />
          </div >
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#comments" role="button">
              {t('comments')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="comments" className="collapse show">
            <CommentsTable businessPartnerId={businessPartnerId} currentStaffId={currentStaffId} />
          </div >
        </>
      )}
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#documents" role="button">
          {t('documents')}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="documents" className="collapse show">
        <DocumentOfBusinessPartners tableName={'ocumentOfBusinessPartnersOverview'} business_partner_id={businessPartnerId} treaty={false} />
      </div >
      {businessPartner.customer && (
        <>
          {currentCustomizing.use_sites && (
            <>
              <div className="d-flex flex-stack fs-4 py-3">
                <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#construction_sites" role="button">
                  {t('construction_sites')}
                  <span className="ms-2 rotate-180">
                    <i className="ki-duotone ki-down fs-3"></i>
                  </span>
                </div>
              </div>
              <div className="separator my-3"></div>
              <div id="construction_sites" className="collapse show">
                <SitesTable key={'component-sites'} businessPartnerId={businessPartnerId} />
              </div>
            </>
          )}
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#offers" role="button">
              {t('offers')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="offers" className="collapse show">
            <OfferTable businessPartnerId={businessPartnerId} />
          </div >

          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#orders" role="button">
              {t('orders')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="orders" className="collapse show">
            <OrderTable businessPartnerId={businessPartnerId} currentCustomizing={currentCustomizing} companyId={companyId} />
          </div >

          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#invoices" role="button">
              {t('invoices')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="invoices" className="collapse show">
            <InvoiceTable businessPartnerId={businessPartnerId} />
          </div >

          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#reminder" role="button">
              {t('dunning.ueber_dunnings.reminders')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="reminder" className="collapse show">
            <ReminderTable businessPartnerId={businessPartnerId} />
          </div >

          {currentCustomizing.plant_waste && (
            <>
              <div className="d-flex flex-stack fs-4 py-3">
                <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#delivery_slips_according_to" role="button">
                  {t('delivery_slips_according_to')}
                  <span className="ms-2 rotate-180">
                    <i className="ki-duotone ki-down fs-3"></i>
                  </span>
                </div>
              </div>
              <div className="separator my-3"></div>
              <div id="delivery_slips_according_to" className="collapse show">
                <OrderPlantWasteTable businessPartnerId={businessPartnerId} />
              </div >
              <div className="d-flex flex-stack fs-4 py-3">
                <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#analyses_for_delivery_slips_according" role="button">
                  {t('analyses_for_delivery_slips_according')}
                  <span className="ms-2 rotate-180">
                    <i className="ki-duotone ki-down fs-3"></i>
                  </span>
                </div>
              </div>
              <div className="separator my-3"></div>
              <div id="analyses_for_delivery_slips_according" className="collapse show">
                <PlantWasteAnalyse businessPartnerId={businessPartnerId} />
              </div >
            </>
          )}
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#diesel_book" role="button">
              {t('diesel_book')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="diesel_book" className="collapse show">
            <DieselBook businessPartnerId={businessPartnerId} />
          </div >
        </>
      )}
    </>
  );
};

export default Overview;
