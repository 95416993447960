import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';

const Table = ({ filters, tableToolbar, startDate, endDate }) => {
  const { t, i18n } = useTranslation()

  const getPlantProtectionProducts = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination

    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })

    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], start_date: startDate, end_date: endDate }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.PlantProtectionProducts(), { params: params })
    pushToHistory(params)

    return ({ rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count })
  }

  const columns = useMemo(
    () => [
      {
        id: 'article_number',
        header: t('number'),
        accessorFn: row => row?.attributes?.article_client_specific_number,
        size: 100,
        minSize: 100,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes

          return <a
            key={attributes?.article_client_specific_number}
            href={attributes?.article_link}>
            {attributes?.article_client_specific_number}
          </a>
        },
      },
      {
        id: 'long_name',
        header: t('name'),
        enableSorting: true,
        accessorFn: row => row?.attributes?.long_name,
        cell: row => row.getValue(),
      },
      {
        id: 'action_date',
        header: t('date_of_action'),
        enableSorting: true,
        accessorFn: row => row?.attributes?.action_date,
        cell: row => row.getValue(),
      },
      {
        id: 'quantity',
        header: t('amount_in_kg_l'),
        accessorFn: row => row?.attributes?.quantity,
        cell: row => row.getValue(),
      },
      {
        id: 'creator_name',
        header: t('name_of_person'),
        accessorFn: row => row?.attributes?.creator_name,
        cell: row => row.getValue(),
      },
      {
        id: 'product_name',
        header: t('phytosanitary_designation'),
        enableSorting: true,
        accessorFn: row => row?.attributes?.cost,
        cell: row => row.getValue(),
      },
      {
        id: 'product_formulation',
        header: t('phytosanitary_formulation'),
        enableSorting: true,
        accessorFn: row => row?.attributes?.name,
        cell: row => row.getValue(),
      },
      {
        id: 'active_substances',
        header: t('plant_protective_active_ingredient'),
        accessorFn: row => row?.attributes?.active_substances,
        cell: row => row.getValue(),
      },
      {
        id: 'functions',
        header: t('effective_areas'),
        accessorFn: row => row?.attributes?.functions,
        cell: row => row.getValue(),
      },
      {
        id: 'field',
        header: t('field_amount'),
        accessorFn: row => row?.attributes?.field,
        cell: row => row.getValue(),
      },
      {
        id: 'start_date',
        header: t('start_of_admission'),
        enableSorting: true,
        accessorFn: row => row?.attributes?.start_date,
        cell: row => row.getValue(),
      },
      {
        id: 'end_date',
        header: t('end_of_registration'),
        enableSorting: true,
        accessorFn: row => row?.attributes?.end_date,
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getPlantProtectionProducts}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection='asc'
      trigger={startDate + endDate}
    />
  )
}

export default Table;
