import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import Table from './Table';
import Create from './Create';
import Modal from '../../components/Modal';
import EditCustomizingModal from '../Articles/EditCustomizingModal';
import { GearIcon, ExportIcon, PlusIcon } from '../../components/Icons';
import { axiosInstance, routes } from '../../utils/api_base';

const Articles = ({ filters, plant_protection }) => {
  const { t } = useTranslation()
  const [customizingModalOpen, setCustomizingModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <>
        <TableButton type="button" className="btn-light-primary me-3" onClick={() => createExport()} icon={<ExportIcon />} title={t('shared.export')} />
        <TableButton type="button" className="btn-light-primary me-3" href='/purchase_bills' title={t('all_good_receipts')} />
        <TableButton
          className="btn-primary me-4"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('shared.add_to')}
        />
        <TableButton
          className="btn-light-primary py-0"
          icon={<GearIcon />}
          onClick={() => setCustomizingModalOpen(true)}
        />
      </>
    )
  }

  const createExport = () => {
    const urlParams = new URLSearchParams(window.location.search)
    axiosInstance.post(routes.exports(), {
      type: 'ArticlesExport',
      export_format: 'xlsx',
      additional_params: {
        active: urlParams.get('active')
      }
    })
      .then(response => console.log('RESPONSE', response))
      .catch(error => console.log('ERROR', error))
  }

  return (
    <>
      <Modal className="modal fade show w-750px mw-750px" open={customizingModalOpen} onClose={() => setCustomizingModalOpen(false)} title={t('stock_settings')}>
        <EditCustomizingModal onClose={() => setCustomizingModalOpen(false)} plantProtection={plant_protection} />
      </Modal>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('articles.create_new')}>
        <Create onClose={() => setModalOpen(false)} />
      </Modal>
      <Table tableToolbar={tableToolbar} filters={filters} plantProtection={plant_protection} />
    </>
  )
}

export default Articles;
