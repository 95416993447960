import React from 'react';
import * as style from './index.module.scss'
import classNames from 'classnames'


const StatusProgress = ({orderId, statusId, statuses = [], callback}) => {
  const onItemClicked = (itemId) => {
    callback(orderId, { status_id: itemId })
  }
  const currentStatusIndex = statuses.findIndex(item => item.id === statusId)

  return (
    <div className={style['statuses-wrapper']}>
      <div className={style.statuses}>
        {
          statuses.map((item, index) => {
            return (
              <a key={index} onClick={() => {onItemClicked(item.id)}} className={classNames(style['status-item'], { [style['is-active']]: index <= currentStatusIndex })} >
                {item.name}
              </a>
            )
          })
        }
      </div>
    </div>
  );
};

export default StatusProgress;
