import React, { useCallback, useState } from 'react';
import Table from './Table';
import { useTranslation } from 'react-i18next';
import debounce from "lodash/debounce";
import { axiosInstance, routes } from '../../utils/api_base';
import DateRangePicker from '../../components/DateRangePicker';
import moment from 'moment';

const PlantProtectionProducts = ({ filters }) => {
  const { t, i18n } = useTranslation()
  const urlParams = new URLSearchParams(window.location.search);
  const fullYear = new Date().getFullYear();
  const startOfYear = new Date(fullYear, 0, 1).toISOString().split('T')[0];
  const endOfYear = new Date(fullYear, 11, 31).toISOString().split('T')[0];
  const startDate = urlParams.get('start_date') ?? startOfYear;
  const endDate = urlParams.get('end_date') ?? endOfYear;

  const [dateRange, setDateRange] = useState({ startDate: startDate, endDate: endDate });
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [placeholder, setPlaceholder] = useState("");

  const handleDateChange = (startTime, endTime) => {
    const dateFormat = i18n.language === "de" ? "DD.MM.YYYY" : "DD-MM-YYYY";

    // DateRangePicker component is expecting a Promise.
    return new Promise((resolve, reject) => {
      try {
        const formattedStartDate = moment(startTime).format(dateFormat);
        const formattedEndDate = moment(endTime).format(dateFormat);

        setPlaceholder(`${formattedStartDate} - ${formattedEndDate}`);
        setDateRange({ startDate: startTime, endDate: endTime });
        setIsDateSelected(true);

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const tableToolbar = () => {
    return (
      <DateRangePicker
        placeholder={isDateSelected ? placeholder : t('range_date_placeholder')}
        classList="cursor-pointer form-control text-left form-control-sm"
        style={{ width: "180px" }}
        language={i18n.language}
        options={{
          showDropdowns: true,
          onChange: handleDateChange,
          autoApply: true,
          autoUpdateInput: false,
          linkedCalendars: false
        }}
      />
    )
  }

  const fetchData = (url, params) => {
    return axiosInstance.get(url, { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.name,
      }))
      )
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadOrders = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, query: inputText };
    return fetchData(routes.orders(), params);
  });

  const orderFilter = {
    label: t("orders"),
    attribute: "order_id",
    type: 'async',
    select: true,
    include_all: true,
    options: loadOrders
  }

  const combinedFilters = [...filters, orderFilter];

  return (
    <Table
      filters={combinedFilters}
      tableToolbar={tableToolbar}
      startDate={dateRange.startDate}
      endDate={dateRange.endDate}
    />
  )
}

export default PlantProtectionProducts;
