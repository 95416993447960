import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import queryString from 'query-string';
import {axiosInstance, routes} from '../../utils/api_base';
import {pushToHistory} from '../../utils/base';
import * as style from './index.module.css';
import DetailsPanel from '../../components/Orders/DetailsPanel';
import Overview from '../../components/Orders/Overview';
import Article from '../../components/Orders/Article';
import DriverInputs from '../../components/Orders/DriverInputs';
import TableButton from '../../components/Table/TableButton';
import {GearIcon} from '../../components/Icons';
import Toastr from '../../components/Toastr';

const Order = ({id}) => {
  const { t } = useTranslation()
  const parsedQuery = queryString.parse(location.search)
  const [activeTab, setActiveTab] = useState(parsedQuery.tab || 'overview')
  const [order, setOrder] = useState({id: id})
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)

  const getOrder = (id) => {
    axiosInstance.get(routes.order(id))
    .then((response) => {
      setOrder(prevState => ({
        ...prevState,
        ...response.data.data.attributes
      }))
    })
    .catch((error) => {
      console.log('ERROR', error)
    });
  }

  useEffect(() => {
    getOrder(id)
  }, [id])

  useEffect(() => {
    pushToHistory({tab: activeTab})
  }, [activeTab])

  const updateOrder = (id, params) => {
    const data = {
      order: {
        active: params.active,
        status_id: params.status_id,
        business_partner_id: params.business_partner_id,
        site_id: params.site_id,
        offer_id: params.offer_id,
      },
      order_detail: {
      }
    }
    axiosInstance.put(routes.order(id), data)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setOrder((prevDetails) => ({
          ...prevDetails,
          ...resp.data.data.attributes,
        }));
      })
      .catch(error => {
        const errorObject = error.response?.data?.error
        Toastr({
          type: 'error',
          message: typeof errorObject === 'object' && errorObject !== null ? Object.values(errorObject) : t('critical_error')
        })
      })
  }

  return (
    <div className="app-content flex-column flex-row-fluid">
      <div className="app-toolbar pb-5">
        <div className="app-container container-xxl d-flex flex-stack">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              { order.full_name }
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <div className="d-flex">
              <TableButton
                className="btn-light-primary py-0"
                icon={<GearIcon />}
                onClick={() => setSettingsModalOpen(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex'>
      <div className={style['details-panel']}>
        <DetailsPanel order={order} updateOrder={updateOrder}/>
      </div>
      <div className={style['details-tabs']}>
        <Tabs
          onSelect={(k) => {
            setActiveTab(k)
            pushToHistory({tab: k})
          }}
          defaultActiveKey={activeTab}
          id="machine-tabs"
          className="mb-3"
        >
          <Tab eventKey="overview" title={t('overview')} mountOnEnter={true}>
            <Overview order={order} updateOrder={updateOrder}/>
          </Tab>
          <Tab eventKey="article" title={t('article')} mountOnEnter={true}>
            <Article order={order} />
          </Tab>
          <Tab eventKey="driver_inputs" title={t('driver_inputs')} mountOnEnter={true}>
            <DriverInputs order={order} />
          </Tab>
        </Tabs>
      </div>
    </div>
    </div>
  );
};

Order.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Order;
