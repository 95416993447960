import React, { useEffect, useState } from 'react';
import { axiosInstance, routes } from '../../../utils/api_base';
import Toastr from '../../Toastr';
import { TrashSquareIcon, SplitItemIcon, StopIcon, PlusSquareIcon, CrossSquareIcon } from '../../Icons';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'bootstrap'
import * as style from './index.module.css';
import Modal from '../../Modal';
import CreateSplitBooking from './CreateSplitBooking';

const ButtonsToolColumn = ({ row, deleteBooking, cancelBooking, setTrigger, setEditTime }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false)
  const attributes = row?.original?.attributes

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, []);


  const buildBooking = (currentBookingId, type) => {
    const params = {
      id: currentBookingId,
      booking_type: type
    };

    axiosInstance.post(routes.bookings(), params)
      .then(resp => {
        Toastr({ message: t('booking_create_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table

        type === "split_booking" ? setEditTime(true) : null;
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const stopBooking = (id) => {
    axiosInstance.put(routes.stopBooking(id))
      .then(resp => {
        Toastr({ message: t('booking_stop_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }


  const RemoveBooking = () => {
    if (attributes.can_delete_booking) {
      return (
        <div
          className={`${style['button-icon']}`}
          onClick={() => {
            if (window.confirm(t('booking_confirm_question'))) {
              deleteBooking(row.original.id)
            }
          }}
        >
          <TrashSquareIcon className="fs-2qx text-danger" />
        </div>
      );
    } else if (attributes.can_cancel_booking) {
      return (
        <div
          className={`${style['button-icon']}`}
          onClick={() => {
            if (window.confirm(t('booking_confirm_question'))) {
              cancelBooking(row.original.id)
            }
          }}
        >
          <CrossSquareIcon className="fs-2qx text-danger" />
        </div>
      );
    }
    else {
      return (
        <div className={style['button-cancel']}>
          <TrashSquareIcon className="fs-2qx test-muted" />
        </div>
      );
    };
  }

  const SplitBooking = () => {
    if (attributes.can_split_booking) {
      return (
        <div
          className={`${style['button-icon']}`}
          onClick={() => setModalOpen(true)}
        >
          <SplitItemIcon className="text-success fs-2qx me-2" />
        </div>
      );
    } else {
      return (
        <div className={style['button-cancel']}>
          <SplitItemIcon className="fs-2qx test-muted me-2" />
        </div>
      );
    };
  };

  const AddBooking = () => {
    if (attributes.can_split_booking && attributes.can_fill_time_gap_to_next_booking) {
      return (
        <div className={`${style['button-icon']}`} onClick={() => buildBooking(row.original.id, "clone_booking")}>
          <PlusSquareIcon className="text-success fs-2qx me-2" />
        </div>
      );
    } else {
      return (
        <div className={style['button-cancel']}>
          <PlusSquareIcon className="fs-2qx test-muted me-2" />
        </div>
      );
    };
  };

  const EndBooking = () => {
    if (attributes.can_end_booking) {
      return (
        <div className={`${style['button-icon']}`} onClick={() => stopBooking(row.original.id)}>
          <StopIcon className="text-warning fs-2qx" />
        </div>
      );
    } else {
      return (
        <div className={style['button-cancel']}>
          <StopIcon className="fs-2qx test-muted" />
        </div>
      );
    };
  };

  return (
    <div>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('split_working_time')}>
        <CreateSplitBooking
          onClose={() => setModalOpen(false)}
          attributes={attributes}
          currentStartTime={new Date(attributes.start_time)}
          currentEndTime={new Date(attributes.end_time)}
          setTrigger={setTrigger}
        />
      </Modal>
      <div className='d-flex justify-content-sm-center'>
        <span
          data-bs-toggle="tooltip"
          data-bs-custom-class="tooltip-inverse"
          data-bs-placement="top"
          title={t('split_working_time')}
          className='btn btn-sm p-2'
        >
          <SplitBooking />
        </span>
        <span
          data-bs-toggle="tooltip"
          data-bs-custom-class="tooltip-inverse"
          data-bs-placement="top"
          title={attributes.can_delete_booking ? t('delete_working_time') : t('cancel_working_time')}
          className='btn btn-sm p-2'
        >
          <RemoveBooking />
        </span>
      </div>
      <div className='d-flex justify-content-sm-center'>
        <span
          data-bs-toggle="tooltip"
          data-bs-custom-class="tooltip-inverse"
          data-bs-placement="top"
          title={t('add_working_time')}
          className='btn btn-sm p-2'
        >
          <AddBooking />
        </span>
        <span
          data-bs-toggle="tooltip"
          data-bs-custom-class="tooltip-inverse"
          data-bs-placement="top"
          title={t('end_working_time')}
          className='btn btn-sm p-2'
        >
          <EndBooking />
        </span>
      </div>

    </div>
  );
};

export default ButtonsToolColumn;
