import React from 'react';
import * as style from './index.module.scss';

const Button = ({ children, classNames = "primary", type = "button", loading = false, onClick }) => {
  const buttonType = `${style.button} ${style[classNames]}`;

  return (
    <button onClick={onClick} className={buttonType} disabled={loading} type={type}>
      {loading ? (
        <span className={style.loadingText}>Please wait...
          <span className={style.spinner}></span>
        </span>
      ) : (
        <span>{children}</span>
      )}
    </button>
  );
};

export default Button;
