import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RevenueTable from './Table'
import {axiosInstance, routes} from '../../utils/api_base';
import Toastr from '../../components/Toastr';

const RevenueAccounts = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('base');
  const [columnData, setColumnData] = useState([])

  const getCompanies = () => {
    axiosInstance
      .get(routes.companies())
      .then(response => {
        setColumnData(
          response.data.data.map((cd) => (cd.attributes))
        )
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  useEffect(()=> {
    getCompanies()
  }, [])

  return(
    <Tabs
      onSelect={(k) => setActiveTab(k)}
      defaultActiveKey={activeTab}
      id="unit-tabs"
      className="mb-3"
    >
      <Tab eventKey="base" title={t('basic')} mountOnEnter={true} unmountOnExit={true}>
        <RevenueTable columnData={columnData} type="base" />
      </Tab>
      <Tab eventKey="intercompany" title={t('intercompany')} mountOnEnter={true} unmountOnExit={true}>
        <RevenueTable columnData={columnData} type="intercompany" />
      </Tab>
    </Tabs>
  )
}

export default RevenueAccounts
