import React, { useEffect, useState } from "react";
import {List} from "../../components/List";
import StaffItem from "./StaffItem/index";
import {axiosInstance, routes} from "../../utils/api_base";

const StaffSidebar = ({ time }) => {
    const [absences, setAbsences] = useState([])

    return <div className={"card shadow-sm"}
                style={{position: 'absolute', top: 80, right: 10, width: 300, height: "calc(100% - 160px)"}}>
        <div className="card-header">
            <h3 className="card-title">Mitarbeiter</h3>
        </div>
        <div className="card-body p-2" style={{overvlowY: 'scroll', height: '100%', position: 'relative'}}>
            <List style={{ overflowY: 'scroll', height: '90%' }}>

            </List>
        </div>
    </div>
}

export default StaffSidebar
