import React from 'react'
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../Table/EditableTextInput';

const CostDetailsTable = ({machine, updateMachine}) => {
  const {t} = useTranslation()

  console.log({machine})
  return(
    <div className="card card-flush">
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          Cost Details
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="row mt-2 justify-content-md-center">
          <div className="col-12 table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
              <thead>
              <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th colSpan="1">
                  <div>{t('costs')}</div>
                </th>
                {
                  machine.diff_between_travel_and_work &&
                  <th colSpan="1">
                    <div>{t('drive_hour')}</div>
                  </th>
                }
                <th colSpan="1">
                  <div>{t('deployment_hour')}</div>
                </th>
                <th colSpan="1">
                  <div>{t('year')}</div>
                </th>
              </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
              <tr>
                <td>{t('repair')}</td>
                {
                  machine.diff_between_travel_and_work &&
                  <td>{machine.factored_travel_repair_per_hour}</td>
                }
                <td><EditableTextInput id={machine.id} attribute='repair_per_hour' initialValue={machine.repair_per_hour_value} handleSubmit={updateMachine} /></td>
                <td><EditableTextInput id={machine.id} attribute='repair_costs' initialValue={machine.repair_costs_value} handleSubmit={updateMachine} /></td>
              </tr>
              {
                !machine.calc_costs_as_leasing &&
                <tr>
                  <td>{t('depreciation')}</td>
                  {
                    machine.diff_between_travel_and_work &&
                    <td>{machine.factored_travel_depreciation_per_hour}</td>
                  }
                  <td>{machine.factored_work_depreciation_per_hour}</td>
                  <td>{machine.depreciation_per_year}</td>
                </tr>
              }
              {
                machine.calc_costs_as_leasing &&
                <tr>
                  <td>{t('leasing_rate_per_year')}</td>
                  {
                    machine.diff_between_travel_and_work &&
                    <td>{machine.factored_travel_leasing_rate_per_hour}</td>
                  }
                  <td>{machine.leasing_per_hour}</td>
                  <td>{machine.leasing_rate_per_year}</td>
                </tr>
              }
              <tr>
                <td><b>{t('total_variables_costs')}</b></td>
                {
                  machine.diff_between_travel_and_work &&
                  <td>{machine.var_travel_sum_per_hour}</td>
                }
                <td>{machine.var_work_sum_per_hour}</td>
                <td>{machine.var_sum_per_year}</td>
              </tr>
              <tr>
                <td>{t('insurance')}</td>
                {
                  machine.diff_between_travel_and_work &&
                  <td/>
                }
                <td>{machine.insurance_per_hour}</td>
                <td>{machine.insurance_per_year}</td>
              </tr>
              {
                !machine.calc_costs_as_leasing &&
                <tr>
                  <td>{t('financing_interest')}</td>
                  {
                    machine.diff_between_travel_and_work &&
                    <td/>
                  }
                  <td>{machine.interest_per_hour}</td>
                  <td>{machine.interest_per_year}</td>
                </tr>
              }
              <tr>
                <td><b>{t('total_sum')}<br/>{t('machine_expenses')}</b></td>
                {
                  machine.diff_between_travel_and_work &&
                  <td>{machine.machine_travel_per_hour}</td>
                }
                <td>{machine.machine_work_per_hour}</td>
                <td>{machine.machine_travel_and_work_per_year}</td>
              </tr>
              <tr>
                <td>{t('general_fixed_costs')}</td>
                {
                  machine.diff_between_travel_and_work &&
                  <td/>
                }
                <td>{machine.fix_cost}</td>
                <td>{machine.fix_cost_per_year}</td>
              </tr>
              <tr>
                <td><b>{t('total')}</b></td>
                {
                  machine.diff_between_travel_and_work &&
                  <td>{machine.var_travel_sum_per_hour}</td>
                }
                <td>{machine.cost_per_unit}</td>
                <td>{machine.total_cost}</td>
              </tr>
              </tbody>
              <tfoot className="fw-bold text-black">
              <tr>
                <th/>
                <th/>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CostDetailsTable;
