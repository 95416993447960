import React from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../Toastr';
import { axiosInstance, routes } from '../../../utils/api_base';
import { Form, Field } from 'react-final-form'
import ErrorField from '../../Form/ErrorField';
import TableButton from '../../../components/Table/TableButton';
import TimePickerAdapter from '../../Form/TimePickerAdapter';
import * as style from './index.module.css';

const CreateSplitBooking = ({ onClose, attributes, currentStartTime, currentEndTime, setTrigger }) => {
  const { t } = useTranslation();
  const currentStartHoursAndMin = `${currentStartTime.getHours()}:${currentStartTime.getMinutes()}`;
  const currentEndHoursAndMin = `${currentEndTime.getHours()}:${currentEndTime.getMinutes()}`;
  const maxTime = new Date(currentEndTime);
  maxTime.setMinutes(maxTime.getMinutes() - 1)

  const onSubmit = async values => {
    const params = {
      split_time: values.split_time
    }

    axiosInstance.post(routes.splitBooking(attributes.id), params)
      .then(resp => {
        Toastr({ message: t("booking_create_success"), options: { showDuration: 2000 } })
        onClose()

        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        split_time: currentStartTime
      }}
      render={({ handleSubmit, submitting, pristine, submitError }) => (
        <form onSubmit={handleSubmit}>
          <div id={"split-booking"} className={style["form-split-time-picker"]}>
            <span className='d-flex justify-content-center mb-4 me-1'>{currentStartHoursAndMin}</span>
            <div className='d-flex justify-content-center' style={{ backgroundColor: '#FFFCF6', height: "42px" }}>
              <Field
                className={"ms-6"}
                name='split_time'
                minTime={currentStartTime}
                maxTime={maxTime}
                component={TimePickerAdapter}
                clearIcon={false}
                disableClock={true}
                required={true}
              >
              </Field>
            </div>
            <span className='d-flex justify-content-center mt-4 me-1'>{currentEndHoursAndMin}</span>
            <ErrorField name="split_time" />
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6 me-4"
                type="submit"
                disabled={submitting || pristine}
                title={t('split')}
              />
            </div>
          </div>
        </form>
      )}
    />
  )
}

export default CreateSplitBooking;
