import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import Modal from '../../components/Modal';
import Table from './Table';
import Create from './Create';

const RessourceLocationHistories = ({ filters, ressource, current_location }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setModalOpen(true)}
        title={t('resource_location_histories.add_location_change_afterwards')}
      />
    )
  }

  return (
    <>
      <div className="alert alert-info">
        {t('resource_location_histories.current_location_is', { location: current_location }) }
      </div>
      <Modal className="modal fade show"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={t('resource_location_histories.add_location_change_afterwards')}>
        <Create onClose={() => setModalOpen(false)} ressource={ressource} />
      </Modal>
      <Table filters={filters} tableToolbar={tableToolbar} ressource={ressource} />
    </>
  )
}

export default RessourceLocationHistories;
