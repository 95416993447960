import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

const Table = ({ tableToolbar, staffId, filters, day, onApplyFilter }) => {
  const { t, i18n } = useTranslation()
  const getArticleGroups = async (pagination, sorting, globalFilter, customFilters) => {

    let params = { staff: staffId }
    if (day) {
      params.day = day
    }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.chroniclesStaffPerDays(), { params: params })
    pushToHistory(params)

    const groupedData = res.data.data.reduce((acc, item) => {
      const date = item?.attributes?.day;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    const rows = Object.entries(groupedData).flatMap(([date, items]) => [
      { isDateHeader: true, date },
      ...items
    ]);

    onApplyFilter(params)
    return { rows };
  }

  const htmlSafe = (value) => {
    const sanitizedHtml = DOMPurify.sanitize(value);

    return (
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'time',
        header: t('time_expressions.day'),
        accessorFn: row => row.isDateHeader ? row?.date : row?.attributes?.time,
        cell: row => {
          const value = row.getValue();
          return row.row.original.isDateHeader ? <strong className='fs-1'>{value}</strong> : value;
        },
        enableSorting: false,
      },
      {
        id: 'service_field_name',
        header: t('input_field'),
        accessorFn: row => row.isDateHeader ? '' : row?.attributes?.service_field_name,
        cell: row => row.isDateHeader ? '' : htmlSafe(row.getValue()),
        enableSorting: false
      },
      {
        id: 'input',
        header: t('input'),
        accessorFn: row => row.isDateHeader ? '' : row?.attributes?.input,
        cell: row => row.isDateHeader ? '' : htmlSafe(row.getValue()),
        enableSorting: false
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      filters={filters}
      getDataFn={getArticleGroups}
      tableToolbar={tableToolbar}
      enablePagination={false}
      showSearchInput={false}
      trigger={day}
    />
  )
}

export default Table;
