
import React from "react";
import EditableSelectInput from "../Table/EditableSelectInput";

const StaffSelectInput = ({ staffId, initialValue, translation, updateStaff, attribute, getEntities, isMulti }) => {
  return (
    <>
      <div className="fw-bold">{translation}</div>
      <div className="text-gray-600">
        <EditableSelectInput
          id={staffId}
          isMulti={isMulti}
          attribute={attribute}
          initialValue={initialValue}
          handleSubmit={updateStaff}
          getEntities={getEntities}
          fieldStyle="w-50"
        />
      </div>
      <span className="separator my-5"></span>
    </>
  );
}

export default StaffSelectInput;
