import React, { useMemo } from 'react';
import BaseTable from '../../Table';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../../Table/EditableTextInput';
import Toastr from '../../Toastr';
import OrderColumnInputs from "./OrderColumnInputs";
import ActivityColumnInput from './ActivityColumnInput';
import TimeColumnInput from './TimeColumnInput';
import ButtonsToolColumn from './ButtonsToolColumn';

const WorkingHoursTable = ({ tableToolbar, staff, day, trigger, setTrigger, setLastBookingTime }) => {
  const { t, i18n } = useTranslation();

  const getBookings = async (pagination, sorting, globalFilter) => {
    const { pageIndex } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })

    let params = {
      page: pageIndex + 1,
      limit: 20,
      sort: sortQuery[0],
      staff_id: staff.id,
      staff_time_bookings: "true",
      day: day
    }

    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.bookings(), { params: params })
    setLastBookingTime(res.data?.meta?.last_booking_time)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateBooking = (id, attrs) => {
    const params = {
      comment: attrs.comment,
      staff_order_id: attrs.staff_order_id,
      booking_code_id: attrs.booking_code_id,
      times: {
        start_time: attrs.start_time,
        end_time: attrs.end_time
      }
    }

    axiosInstance.put(routes.booking(id), params)
      .then(resp => {
        Toastr({ message: t("booking_update_success"), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const cancelBooking = (id) => {
    axiosInstance.put(routes.cancelBooking(id))
      .then(resp => {
        Toastr({ message: t('booking_cancel_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteBooking = (id) => {
    axiosInstance.delete(routes.booking(id))
      .then(resp => {
        Toastr({ message: t('booking_delete_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const buttonRow = (row) => {
    const attributes = row.original?.attributes

    return (
      attributes?.is_absence_booking ?
        attributes?.can_edit_absence ?
          <ButtonsToolColumn
            row={row}
            deleteBooking={deleteBooking}
            cancelBooking={cancelBooking}
            setTrigger={setTrigger}
          />
          : <a key={attributes.id} href={attributes.absence_link}>{t('show_absence')}</a>
        : <ButtonsToolColumn
          row={row}
          deleteBooking={deleteBooking}
          cancelBooking={cancelBooking}
          setTrigger={setTrigger}
        />
    )
  };

  const orderRow = (row) => {
    const attributes = row.original?.attributes

    return (
      attributes?.is_absence_booking ?
        <h6>N/A</h6>
        :
        <OrderColumnInputs
          attributes={row?.original?.attributes}
          deleteBooking={deleteBooking}
          setTrigger={setTrigger}
          updateBooking={updateBooking}
          staff_id={staff.id}
          day={day}
          cancelBooking={cancelBooking}
        />
    )
  };

  const commentRow = (row) => {
    const attributes = row.original?.attributes

    return (
      attributes?.can_edit_absence || attributes?.can_edit_booking ?
        <EditableTextInput
          id={row?.original?.id}
          attribute='comment'
          initialValue={row?.original?.attributes?.comment || "-"}
          handleSubmit={updateBooking}
        />
        : attributes?.comment
    )
  };

  const columns = useMemo(
    () => [
      {
        id: 'order_id',
        header: t('order'),
        size: 300,
        minSize: 300,
        cell: ({ row }) => orderRow(row)
      },
      {
        id: 'booking_code',
        header: t("activity"),
        size: 200,
        minSize: 200,
        cell: ({ row }) => <ActivityColumnInput
          attributes={row?.original?.attributes}
          updateBooking={updateBooking}
        />
      },
      {
        id: 'duration',
        header: t('duration'),
        accessorFn: row => row?.attributes?.quantity_with_units,
        cell: row => row?.getValue(),
      },
      {
        id: 'hour_and_min',
        header: t('time'),
        size: 200,
        minSize: 200,
        cell: ({ row, table }) => {
          const prevRow = row.index > 0 ? table?.getRowModel().rows[row.index - 1] : null;
          const nextRow = row.index < table?.getRowModel().rows.length - 1 ? table.getRowModel().rows[row.index + 1] : null;
          return <TimeColumnInput
            attributes={row?.original?.attributes}
            updateBooking={updateBooking}
            prevEndTime={prevRow?.original?.attributes.end_time}
            nextStartTime={nextRow?.original?.attributes.start_time}
          />
        }
      },
      {
        id: 'comment',
        header: t('comment'),
        cell: ({ row }) => commentRow(row)
      },
      {
        id: 'payroll',
        header: t('payroll'),
        accessorFn: row => row?.attributes?.payroll_name,
        cell: row => row?.getValue(),
      },
      {
        id: 'tool_buttons',
        header: null,
        cell: ({ row }) => buttonRow(row),
      }
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#working-hours" role="button">
          {t('working_hours').toUpperCase()}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="working-hours" className="collapse show">
        <BaseTable
          keyName="workingHours"
          getDataFn={getBookings}
          columns={columns}
          tableToolbar={tableToolbar}
          showSearchInput={false}
          trigger={day + trigger}
        />
      </div >
    </>
  )
}

export default WorkingHoursTable;

