import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import { Form, Field } from 'react-final-form'
import { CheckSquareIcon, CrossSquareIcon } from '../../Icons';
import AsyncSelectAdapter from '../../Form/AsyncSelectAdapter';
import debounce from "lodash/debounce";
import { useTranslation } from 'react-i18next';
import * as style from './index.module.css';

const CreateMachineBookingModal = ({ open, attribute, handleSubmit, getEntities, setOpen, fieldStyle = "" }) => {
  const { t } = useTranslation();
  const [optionsEmpty, setOptionsEmpty] = useState(false);

  const onSubmit = async values => {
    const selectedOption = values[attribute] || {};

    return handleSubmit({ [attribute]: selectedOption });
  }

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getEntities(inputText).then((options) => {
        setOptionsEmpty(options.length === 0);
        callback(options)
      });
    }, 1000), [])

  return (
    open ? (
      <Form
        onSubmit={onSubmit}
        initialValues={{ [attribute]: '' }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={style.form}>
              <Field
                name={attribute}
                component={AsyncSelectAdapter}
                loadOptions={loadOptions}
                placeholder={t('please_select')}
                isClearable={true}
                className={fieldStyle}
              />
              <div className={style['form-buttons']}>
                <button type="submit" disabled={submitting || pristine}>
                  <CheckSquareIcon className={classNames("fs-3 text-success", { "text-gray-500": submitting || pristine })} />
                </button>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  disabled={submitting}
                >
                  <CrossSquareIcon className="fs-3 text-danger" />
                </button>
              </div>
            </div>
          </form>
        )}
      />
    ) : null
  )
}

export default CreateMachineBookingModal;
