import React from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../Toastr';
import { axiosInstance, routes } from '../../../utils/api_base';
import { Form, Field } from 'react-final-form'
import TableButton from '../../Table/TableButton';
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'
import moment from "moment";
import DatePicker from '../../DatePicker';

const CreateChild = ({ onClose, setTrigger, staffId }) => {
  const { t, i18n } = useTranslation();
  const currentDate = moment().toDate();

  const onSubmit = async values => {
    const data = {
      child: {
        staff_id: staffId,
        name: values.name,
        surname: values.surname,
        birth_date: values.birth_date
      }
    };

    axiosInstance.post(routes.children(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
        setTrigger(true)
      })
      .catch(error => {
        const errorData = error.response.data

        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        birth_date: currentDate,
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row px-7 mb-3 mt-6">
              <div className="col">
                <label>{t('first_name')}</label>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  className="form-control"
                  autoFocus
                />
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('last_name')}</label>
                <Field
                  name="surname"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('birthday')}</label>
                <Field
                  name="birth_date"
                  render={({ input }) => (
                    <DatePicker
                      options={{
                        defaultDate: currentDate,
                        dateFormat: (i18n.language === "de" ? 'd.m.Y' : 'd-m-Y'),
                        onChange: input.onChange
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </form>
        </>
      )}
    />
  )
}

export default CreateChild;
