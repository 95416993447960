import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import Table from './Table';
import DatePicker from '../../components/DatePicker';
import TableButton from '../../components/Table/TableButton';
import { ExportIcon } from '../../components/Icons';

const ActiveStaffPerMonths = ({ visibility = {} }) => {
  const { t, i18n } = useTranslation()
  const columnVisibility = {
    working_days_of_temporary_staff: visibility.show_working_days_of_temporary_staff,
    working_days_of_subcontractor: visibility.show_working_days_of_subcontractor
  }

  const urlParams = new URLSearchParams(window.location.search)
  const month = urlParams.get('month') ?? (new Date().getMonth() + 1).toString().padStart(2, '0')
  const year = urlParams.get('year') ?? (new Date()).getFullYear()
  const [selectedDate, setSelectedDate] = useState({ year: year, month: month })
  const [isCurrentDate, setIsCurrentDate] = useState(false)
  const [licenceCountText, setLicenceCountText] = useState('')

  const onChangeDatePicker = (selectedDates, dateStr) => {
    const [month, year] = i18n.language === "de" ? dateStr.split('.') : dateStr.split('-')
    setSelectedDate({ year: year, month: month })
  }

  const handleFilterChange = (data) => {
    setIsCurrentDate(data.is_current_date)
    setLicenceCountText(data.licence_count_text)
  }

  const createExport = () => {
    axiosInstance.post(routes.exports(), {
      type: 'ActiveStaffPerMonthsExport',
      export_format: 'xlsx',
      additional_params: {
        year: year,
        month: month
      }
    })
      .then(response => console.log('RESPONSE', response))
      .catch(error => console.log('ERROR', error))
  }

  const tableToolbar = () => {
    return (
      <>
        <TableButton type="button"
          className="btn-light-primary me-3 d-flex"
          onClick={() => createExport()}
          icon={<ExportIcon />}
          title={t('shared.export')}
        />
        <DatePicker monthOnly options={{
          defaultDate: `${selectedDate.month}-${selectedDate.year}`,
          dateFormat: i18n.language === "de" ? 'm.Y' : 'm-Y',
          onChange: onChangeDatePicker
        }} />
      </>
    )
  }

  return (
    <>
      <h3>{licenceCountText}</h3>
      {
        isCurrentDate &&
          <div className="alert alert-info">
            {t('staffs.working_hours_and_days_alert_one')}
            <br/>
            {t('staffs.working_hours_and_days_alert_two')}
            <em>{` ${t('staffs.working_time')} `}</em>
            {t('and')}
            <em>{` ${t('staffs.working_days')} `}</em>
            {t('staffs.filled_with_zero')}
          </div>
      }
      <Table tableToolbar={tableToolbar}
        columnVisibility={columnVisibility}
        year={selectedDate.year}
        month={selectedDate.month}
        handleFilterChange={handleFilterChange}
      />
    </>
  )
}

export default ActiveStaffPerMonths;
