import React from 'react';
import { useTranslation } from 'react-i18next';
import CostCalculationTable from './CostCalculationTable';
import UtilizationScenariosTable from './UtilizationScenariosTable';
import CostDetailsTable from './CostDetailsTable';

const Costs = ({machine, updateMachine}) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex">
      <div className="flex-grow-2">
        <CostCalculationTable machine={machine} updateMachine={updateMachine} />
        <CostDetailsTable machine={machine} updateMachine={updateMachine} />
      </div>
      <div className="flex-grow-1">
        <UtilizationScenariosTable machine={machine} />
      </div>
    </div>
  );
};

export default Costs;
