import React, { useState, useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { TrashSquareIcon } from '../../components/Icons';
import * as style from './index.module.css';
import Toastr from '../../components/Toastr';
import Form from 'react-bootstrap/Form';
import EditableTextInput from '../../components/Table/EditableTextInput';
import classNames from 'classnames';
import Swal from 'sweetalert2'

const Table = ({tableToolbar}) => {
  const { t, i18n } = useTranslation()
  const [trigger, setTrigger] = useState(false)

  const getRevenueCodes = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.revenueCodes(), { params: params })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const deleteRevenueCode = (id, disabled) => {
    if (disabled) return
    Swal.fire({
      title: "Are you sure?",
      width: 400,
      toast: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("delete"),
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance.delete(routes.revenueCode(id))
          .then(resp => {
            Toastr({message: t('revenue_code.delete_success'), options: { showDuration: 2000}})
            setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
          })
          .catch(error => {
            Toastr({
              type: 'error',
              message: error.response?.data?.error || t('critical_error')
            })
          })
      }
    })
  }

  const updateRevenueCode = (id, params) => {
    axiosInstance.put(routes.revenueCode(id), params)
      .then(resp => {
        Toastr({message: t('revenue_code.update_success'), options: { showDuration: 2000}})
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    const disabled = !row.original.attributes?.can_delete
    return(
      <div
        className={style['trash-button']}
        onClick={() => deleteRevenueCode(row.original.id, disabled)}
        aria-disabled={disabled}
      >
        <TrashSquareIcon className={classNames("fs-2hx", { "text-dark": disabled, "text-danger": !disabled })}/>
      </div>
    )
  }

  const activeHtml = (row, attribute = 'active') => {
    const isActive = row.original.attributes[attribute]
    const label = isActive ? t('yes') : t('no')
    return(
      <Form.Check
        disabled={row.original.attributes?.is_default}
        onChange={() => updateRevenueCode(row.original.id, {[attribute]: !isActive})}
        checked={isActive}
        type="switch"
        id="revenue-code-active-switch"
        label={label}
      />
    )
  }

  const usedForGoodsHtml = (row) => {
    const isActive = row.original.attributes?.article
    const label = isActive ? t('goods') : t('not_for_goods')
    return(
      <Form.Check
        disabled={row.original.attributes?.is_default}
        onChange={() => updateRevenueCode(row.original.id, {article: !isActive})}
        checked={isActive}
        type="switch"
        id="revenue-code-active-switch"
        label={label}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: row => row?.attributes?.name,
        header: t('name'),
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='name' initialValue={row.original.attributes.name} handleSubmit={updateRevenueCode} />
      },
      {
        id: 'short_name',
        accessorFn: row => row?.attributes?.name,
        header: t('short_name'),
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='short_name' initialValue={row.original.attributes.short_name} handleSubmit={updateRevenueCode} />
      },
      {
        id: 'vat_percentage',
        minSize: 70,
        size: 70,
        accessorFn: row => row?.attributes?.vat_percentage,
        header: t('value_added_tax'),
        cell: row => row.getValue(),
      },
      {
        id: 'standard',
        accessorFn: row => row?.attributes?.service,
        header: t('standard'),
        size: 80,
        minSize: 80,
        cell: ({ row }) => {
          return activeHtml(row, 'service')
        },
      },
      {
        id: 'used_for_goods',
        minSize: 70,
        size: 70,
        accessorFn: row => row?.attributes?.active,
        header: t('used_for_goods_question'),
        cell: ({ row }) => {
          return usedForGoodsHtml(row)
        },
      },
      {
        id: 'active',
        accessorFn: row => row?.attributes?.active,
        header: t('active_question'),
        size: 80,
        minSize: 80,
        cell: ({ row }) => {
          return activeHtml(row)
        },
      },
      {
        id: 'delete',
        accessorFn: row => row?.attributes?.active,
        header: null,
        size: 50,
        minSize: 50,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getRevenueCodes}
      tableToolbar={tableToolbar}
      trigger={trigger}
      initialSortDirection={'asc'}
    />
  )
}

export default Table
