import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../Table/TableButton';
import { PlusIcon } from '../../Icons';
import Table from './Table';
import CreateMachine from '../../../components/Machines/Create';
import Modal from '../../Modal';

const ContactsTable = ({ businessPartnerId }) => {
  const { t } = useTranslation()
  const [trigger, setTrigger] = useState(false)
  const [openModal, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setModalOpen(true)}
        icon={<PlusIcon />}
        title={t('shared.add_to')}
      />
    )
  }

  return (
    <>
      <Modal className="modal fade show" open={openModal} onClose={() => setModalOpen(false)} title={t('machines.new_machine')}>
        <CreateMachine setTrigger={setTrigger} onClose={() => setModalOpen(false)} subContractorId={businessPartnerId} />
      </Modal>
      <Table tableToolbar={tableToolbar} businessPartnerId={businessPartnerId} setTrigger={setTrigger} trigger={trigger} />
    </>
  )
}

export default ContactsTable;
