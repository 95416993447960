import React, { useMemo } from 'react';
import BaseTable from '../../Table';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../../Table/EditableTextInput';
import EditableDatePickerInput from '../../Table/EditableDatePickerInput';
import { TrashSquareIcon } from '../../../components/Icons';
import * as style from '../index.module.css';
import Toastr from '../../../components/Toastr';

const WagesTable = ({ tableToolbar, trigger, setTrigger, staff }) => {
  const { t, i18n } = useTranslation();

  const getWages = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], staff_id: staff.id }

    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.remunerationWages(), { params: params })
    // pushToHistory(params) TODO: I guess we don't need to push to history on overview pages coz they may have multiple tables

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateWage = (id, params) => {
    axiosInstance.put(routes.remunerationWage(id), params)
      .then(resp => {
        Toastr({ message: t("update_success"), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    return (
      <div className={`${style['trash-button']}`} onClick={() => deleteWage(row.original.id)}>
        <TrashSquareIcon className="fs-2hx text-danger" />
      </div>
    )
  }

  const deleteWage = (id) => {
    axiosInstance.delete(routes.remunerationWage(id))
      .then(resp => {
        Toastr({ message: t('delete_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const columns = useMemo(
    () => [
      {
        id: 'wage',
        header: staff.payroll_not_needed ? t('fixed_salary') : t('hourly_salary'),
        accessorFn: row => row?.attributes?.hourly_wage,
        enableSorting: true,
        cell: ({ row }) => <EditableTextInput
          id={row?.original?.id}
          attribute='hourly_wage'
          initialValue={row?.original?.attributes?.hourly_wage || "-"}
          handleSubmit={updateWage}
        />
      },
      {
        id: 'comment',
        header: t("comment"),
        size: 250,
        minSize: 250,
        enableSorting: true,
        cell: ({ row }) => <EditableTextInput
          id={row?.original?.id}
          attribute='comment'
          initialValue={row?.original?.attributes?.comment || "-"}
          handleSubmit={updateWage}
        />
      },
      {
        id: 'valid_from',
        header: t('from_date'),
        accessorFn: row => row?.attributes?.valid_from,
        enableSorting: true,
        cell: ({ row }) => <EditableDatePickerInput
          id={row.original.id}
          attribute='valid_from'
          initialValue={row.original.attributes.valid_from}
          handleSubmit={updateWage}
        />
      },
      {
        id: 'delete',
        header: null,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      }
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#wages" role="button">
          {staff.payroll_not_needed ? t('salary_development').toUpperCase() : t('wage_development').toUpperCase()}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3" />
          </span>
        </div>
      </div>
      <div className="separator my-3" />
      <div id="wages" className="collapse show">
        <BaseTable
          keyName={"wages"}
          getDataFn={getWages}
          columns={columns}
          tableToolbar={tableToolbar}
          trigger={trigger}
        />
      </div >
    </>
  )
}

export default WagesTable;
