import React from "react";
import moment from 'moment'
const HeaderTitle = ({ type, startTime, endTime, loading = false }) => {

    const buildTitle = () => {
        switch (type) {
            case 'day':
                return moment(startTime).format("LL")
            case 'week':
                return moment(startTime).format("[KW]WW YYYY")
            case 'month':
                return moment(startTime).format("MMMM YYYY")
        }
    }

    return <>
        <h2 className="text-center mt-3">
            { loading && <div className="spinner-border mx-2" role="status" style={{ height: "1.5rem", width: "1.5rem" }}>
                <span className="visually-hidden">Loading...</span>
            </div> }
            {buildTitle()}</h2>
    </>
}

export default HeaderTitle
