import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import queryString from 'query-string';
import Overview from '../../components/Machines/Overview';
import Costs from '../../components/Machines/Costs';
import Statistics from '../../components/Machines/Statistics';
import Map from '../../components/Machines/Map';
import {axiosInstance, routes} from '../../utils/api_base';
import {pushToHistory} from '../../utils/base';
import * as style from './index.module.css';
import DetailsPanel from '../../components/Machines/DetailsPanel';
import TableButton from '../../components/Table/TableButton';
import {GearIcon} from '../../components/Icons';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import Toastr from '../../components/Toastr';

const Machine = ({id}) => {
  const { t } = useTranslation()
  const parsedQuery = queryString.parse(location.search)
  const [activeTab, setActiveTab] = useState(parsedQuery.tab || 'overview')
  const [machine, setMachine] = useState({id: id})
  const [settingsModalOpen, setSettingsModalOpen] = useState(false)

  const getMachine = (id) => {
    axiosInstance.get(routes.machine(id))
    .then((response) => {
      setMachine(prevState => ({
        ...prevState,
        ...response.data.data.attributes
      }))
    })
    .catch((error) => {
      console.log('ERROR', error)
    });
  }

  useEffect(() => {
    getMachine(id)
  }, [id])

  useEffect(() => {
    pushToHistory({tab: activeTab})
  }, [activeTab])

  const getMachines = (inputText) => {
    let params = { page: 1, limit: 20, active: true, serialize: 'short', query: inputText }

    return axiosInstance.get(routes.machines(), { params: params })
      .then((response) => {
        return response.data.data.map((m) => ({
          value: m.id,
          label: m.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getMachines(inputText).then((options) => callback(options));
    }, 1000), [])

  const onMachineSelect = (option) => {
    if (machine.id != option.value) {
      window.location.assign("/machines/" + option.value)
    }
  }

  const updateMachine = (id, params) => {
    const data = {
      machine: {
        active: params.active,
        show_in_dispo: params.show_in_dispo,
        name: params.name,
        client_specific_number: params.client_specific_number,
        cost_center_id: params.cost_center_id,
        company_id: params.company_id,
        location_id: params.location_id,
      },
      machine_detail: {
        machine_group_id: params.machine_group_id,
        machine_group_for_service_feature_id: params.machine_group_for_service_feature_id,
        manufacturer: params.manufacturer,
        hp: params.hp,
        year_of_manufacture: params.year_of_manufacture,
        rental: params.rental,
        acquisition_date: params.acquisition_date,
        purchase_price: params.purchase_price,
        user_id: params.user_id,
        plan_travel_hours_per_year: params.plan_travel_hours_per_year,
        plan_work_hours_per_year: params.plan_work_hours_per_year,
        plan_years: params.plan_years,
        residual_value: params.residual_value,
        residual_value_perc: params.residual_value_perc
      }
    }
    axiosInstance.put(routes.machine(id), data)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setMachine((prevDetails) => ({
          ...prevDetails,
          ...resp.data.data.attributes,
        }));
      })
      .catch(error => {
        const errorObject = error.response?.data?.error
        Toastr({
          type: 'error',
          message: typeof errorObject === 'object' && errorObject !== null ? Object.values(errorObject) : t('critical_error')
        })
      })
  }

  return (
    <div className="app-content flex-column flex-row-fluid">
      <div className="app-toolbar pb-5">
        <div className="app-container container-xxl d-flex flex-stack">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              {/*{ machine.long_name }*/}
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <div className="d-flex">
              <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                onChange={onMachineSelect}
                value={{value: machine.id, label: machine.long_name}}
              />
              <TableButton
                className="btn-light-primary py-0"
                icon={<GearIcon />}
                onClick={() => setSettingsModalOpen(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex'>
      <div className={style['details-panel']}>
        <DetailsPanel machine={machine} updateMachine={updateMachine}></DetailsPanel>
      </div>
      <div className={style['details-tabs']}>
        <Tabs
          onSelect={(k) => {
            setActiveTab(k)
            pushToHistory({tab: k})
          }}
          defaultActiveKey={activeTab}
          id="machine-tabs"
          className="mb-3"
        >
          <Tab eventKey="overview" title={t('overview')} mountOnEnter={true}>
            <Overview machine={machine}/>
          </Tab>
          <Tab eventKey="costs" title={t('costs')} mountOnEnter={true}>
            <Costs machine={machine} updateMachine={updateMachine}/>
          </Tab>
          <Tab eventKey="statistics" title={t('statistics')} mountOnEnter={true}>
            <Statistics />
          </Tab>
          { machine.is_tracked &&
              <Tab eventKey="map" title={t('map')} mountOnEnter={true}>
                <Map />
              </Tab>
          }
        </Tabs>
      </div>
    </div>
    </div>
  );
};

Machine.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Machine;
