import React from 'react';
import Table from '../../BusinessPartners/OrdersTable/Table';

const Orders = ({ businessPartnerId, companyId }) => {
  const tableToolbar = () => {
    return (
      <></>
    )
  }

  return (
    <Table tableToolbar={tableToolbar} businessPartnerId={businessPartnerId} companyId={companyId} />
  )
}

export default Orders
