import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import locales from '../DateRangePicker/translations';
import daterangepicker from "bootstrap-daterangepicker";

const DateRangePicker = ({ placeholder, options, language = "en", classList = "form-control", style, onChange }) => {
    const datepickerInput = useRef(null);

    useEffect(() => {
        const localeOptions = { ...locales[language] || locales.en };

        if (Boolean(options.timePicker)) localeOptions.format += " HH:mm"

        const mergedOptions = { ...options, locale: localeOptions };

        const dateRangePicker = new daterangepicker(datepickerInput.current, mergedOptions);

        // Event Listener für Änderungen im DateRangePicker
        if (onChange) {
            dateRangePicker.element.on('apply.daterangepicker', (event, picker) => {
                onChange({ from: picker.startDate, to: picker.endDate });
            });
        }

        // Cleanup on unmount
        return () => {
            dateRangePicker.remove();
        };
    }, [options, language, onChange]); // Abhängigkeiten, um sicherzustellen, dass der Effekt erneut ausgelöst wird, wenn sich Optionen oder Sprache ändern

    return (
        <input style={style} className={classList} placeholder={placeholder} ref={datepickerInput} />
    )
}

const DateRangePickerAdapter = ({ input: { value, onChange }, meta, ...rest }) => {
    const { i18n } = useTranslation();

    const defaultOptions = {
        timePicker: true,
        showDropdowns: true,
        startDate: value?.from || new Date(),
        endDate: value?.to || new Date(),
        autoApply: false
    }

    return (
        <DateRangePicker
            language={i18n.language}
            classList="form-control form-control-sm form-control-solid text-center"
            options={defaultOptions}
            onChange={onChange}  // Übergibt die onChange-Logik an den Picker
            {...rest}  // Weitere Props falls notwendig
        />
    )
}

export default DateRangePickerAdapter;
