import React from 'react';

const DriverInputs = ({order}) => {
  return (
    <div>
      <h1>DriverInputs</h1>
    </div>
  );
};

export default DriverInputs;
