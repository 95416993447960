import CustomizingModal from './CustomizingModal';
import Modal from '../Modal';
import React, { useState, useCallback, useEffect } from 'react';
import TableButton from '../Table/TableButton';
import { GearIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import AsyncSelect from 'react-select/async';
import { axiosInstance, routes } from '../../utils/api_base';

const SettingsPanel = ({ id, tab, paymentTerms, businessPartner }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false);

  const getBusinessPartners = (inputText) => {
    let params = { page: 1, limit: 20, active: true, serialize: 'short', query: inputText }

    return axiosInstance.get(routes.businessPartners(), { params: params })
      .then((response) => {
        return response.data.data.map((m) => ({
          value: m.id,
          label: m.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getBusinessPartners(inputText).then((options) => callback(options));
    }, 1000), [])

  const onSelect = (option) => {
    if (businessPartner.id != option.value) {
      window.location.assign("/business_partners/" + option.value)
    }
  }

  return (
    <div className="d-flex flex-stack mb-4">
      <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
          {t('view_business_partner')}
        </h1>
      </div>
      <div className="d-flex align-items-center gap-2 gap-lg-3">
        <div className="d-flex align-items-center gap-3">
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            onChange={onSelect}
            className='w-200px'
            value={{ value: businessPartner.id, label: businessPartner.name }}
          />
          <TableButton
            type="button"
            className="btn-light-primary py-2"
            icon={<GearIcon />}
            onClick={() => setModalOpen(true)}
          />
          <Modal className="modal fade show w-750px mw-750px" open={modalOpen} onClose={() => setModalOpen(false)} title={t('customizing_modal.settings_for_clients')}>
            <CustomizingModal paymentTerms={paymentTerms}/>
          </Modal>
        </div>
      </div>
    </div>
  );
};


export default SettingsPanel;
