import React from 'react';
import TimePickerInput from './TimePickerInput';

const TimeColumnInput = ({ attributes, prevEndTime, nextStartTime, updateBooking }) => {
  const Times = () => {
    return (
      <TimePickerInput
        attributes={attributes}
        prevEndTime={new Date(prevEndTime)}
        nextStartTime={new Date(nextStartTime)}
        initialStartTimeValue={new Date(attributes.start_time)}
        initialEndTimeValue={new Date(attributes.end_time)}
        handleSubmit={updateBooking}
      />
    )
  };

  return attributes.can_edit_absence || attributes.can_edit_booking && attributes.can_change_time ? <Times /> : attributes?.hour_and_min
};

export default TimeColumnInput;
