import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';

const Table = ({ tableToolbar, columnVisibility, year, month, handleFilterChange }) => {
  const { t, i18n } = useTranslation()
  const [totalRow, setTotalRow] = useState([])

  const getActiveStaffPerMonths = async () => {
    let params = { year: year, month: month }

    const res = await axiosInstance.get(routes.activeStaffPerMonths(), { params: params })
    handleFilterChange(res.data.meta)
    setTotalRow(res.data.meta.total_row)
    pushToHistory(params)

    return(
      { rows: res.data.data }
    )
  }

  const tableFooter = () => {
    return (
      (totalRow && totalRow.active_staffs_count !== 0 &&
        <tr className='fw-bold'>
          <td>{t('shared.total')}</td>
          <td></td>
          <td>{totalRow.working_times_sum}</td>
          <td>{totalRow.working_days_sum}</td>
          <td>{totalRow.working_days_sum_temporary_licences}</td>
          <td>{totalRow.working_days_sum_subcontractors}</td>
        </tr>
      )
    )
  }

  const staffHtml = (row) => {
    if (row?.original?.attributes?.staff_link) {
      return(
        <>
          <a href={row.original.attributes.staff_link}>
            {row.original.attributes.staff_name}
          </a>
          {
            row.original.attributes.is_temporary_staff &&
              <>
                <br />
                <span className='badge badge-light-warning'>{t('staffs.temporary_staff')}</span>
              </>
          }
        </>
      )
    }
  }

  const columns = useMemo(
    () => [
      {
        id: 'number',
        cell: ({row}) => { return row.index + 1 }
      },
      {
        id: 'staff_name',
        accessorFn: row => row?.attributes?.staff_name,
        header: t('name'),
        enableSorting: false,
        cell: ({ row }) => {
          return staffHtml(row)
        }
      },
      {
        id: 'formatted_working_time',
        accessorFn: row => row?.attributes?.formatted_working_time,
        header: t('staffs.working_time'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'formatted_working_days',
        accessorFn: row => row?.attributes?.formatted_working_days,
        header: t('staffs.working_days'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'working_days_of_temporary_staff',
        accessorFn: row => row?.attributes?.working_days_of_temporary_staff,
        header: t('staffs.subcontractors_work_days_number'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'working_days_of_subcontractor',
        accessorFn: row => row?.attributes?.working_days_of_subcontractor,
        header: t('staffs.temporary_work_days_number'),
        enableSorting: false,
        cell: row => row.getValue()
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getActiveStaffPerMonths}
      columnVisibility={columnVisibility}
      tableToolbar={tableToolbar}
      showSearchInput={false}
      enablePagination={false}
      enableSorting={false}
      tableFooter={tableFooter()}
      trigger={year + month}
    />
  )
}

export default Table;
