import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../../utils/api_base';
import { Form, Field } from 'react-final-form'
import AsyncSelectAdapter from '../../Form/AsyncSelectAdapter';
import ErrorField from '../../Form/ErrorField';
import Toastr from '../../Toastr';
import { FORM_ERROR } from 'final-form'
import TableButton from '../../Table/TableButton';
import DateRangePicker from '../../DateRangePicker';
import moment from 'moment';
import { mapValues } from 'lodash';
import { isRequired } from '../../Form/validations';

const ExchangeDriver = ({ onClose, setTrigger, staff, day }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const data = {
      old_staff_order: values.old_staff_order?.value,
      new_staff_order: values.new_staff_order?.value,
      staff_id: staff.id,
      day: day
    };

    return axiosInstance.put(routes.bookingsExchangeStaffOrders(), data)
      .then(response => {
        Toastr({
          title: t('success'),
          message: response.data.message,
          options: { closeButton: true }
        })
        onClose()
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        const errorData = error.response?.data

        return { [FORM_ERROR]: errorData.error[0] }
      })
  }

  const getStaffOrders = (inputText, bookedStaffOrders = false) => {
    let params = {
      page: 1,
      limit: 20,
      staff_id: staff.id,
      day: day,
      apply_status: "true",
      query: inputText,
      booked_staff_orders: bookedStaffOrders
    }

    return axiosInstance.get(routes.staffOrders(), { params: params })
      .then((response) => {
        return response.data.data.map((staffOrder) => ({
          value: staffOrder.id,
          label: staffOrder.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="row mt-2">
            <div className="col m-4">
              <h6>{t("staff_name_at_day", { staff_name: staff.name.toUpperCase(), day: day })}</h6>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col m-4">
              <label className='mb-2'>{t('driver_order_booked')}</label>
              <Field
                name='old_staff_order'
                component={AsyncSelectAdapter}
                loadOptions={(inputText) => getStaffOrders(inputText, true)}
                placeholder={t('select')}
                isClearable={true}
              >
              </Field>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col m-4">
              <label className='mb-2'>{t('driver_order_desired')}</label>
              <Field
                name='new_staff_order'
                component={AsyncSelectAdapter}
                loadOptions={getStaffOrders}
                placeholder={t('select')}
                isClearable={true}
              >
              </Field>
            </div>
          </div>
          {submitError && <div className="form-error-message">{submitError}</div>}
          <div className="modal-footer">
            <TableButton
              className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
              type="button"
              onClick={onClose}
              title={t('cancel')}
            />
            <TableButton
              className="btn btn-primary fw-semibold px-6"
              type="submit"
              title={t('create')}
              disabled={submitting || pristine}
            />
          </div>
        </form>
      )}
    />
  )
}

export default ExchangeDriver;
