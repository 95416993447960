import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { PlusIcon } from '../Icons';
import Create from './Create';
import Modal from '../Modal';
import Table from './Table';

const BusinessPartners = ({ attr, filters, visibility = {} }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const columnVisibility = {
    group: visibility.show_group,
    contact_name: visibility.show_contact_info,
    fon: visibility.show_contact_info,
    location: visibility.show_location,
    remaining_order_limit: visibility.show_remaining_order_limit
  }

  const tableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setModalOpen(true)}
        icon={<PlusIcon />}
        title={t('shared.add_to')}
      />
    )
  }

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('shared.new_customer')}>
        <Create attr={attr} onClose={() => setModalOpen(false)} />
      </Modal>
      <Table tableToolbar={tableToolbar} columnVisibility={columnVisibility} filters={filters} />
    </>
  )
}

export default BusinessPartners;
