import React from 'react'
import TimePicker from 'react-time-picker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';
// Horrible hack to fix timepicker styles.
import './react-time-picker-override.css';

const TimePickerAdapter = ({ input, ...rest }) => {

  return (
    <TimePicker
      {...input}
      cacheOptions
      defaultOptions
      {...rest}
    />
  )
}

export default TimePickerAdapter
