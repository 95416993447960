import React from 'react';
import Table from './Table';

const OrderWithoutInvoices = ({ filters, business_partner_id, prev_job_id }) => {
  const tableToolbar = () => { return (<></>) }

  return (
    <Table filters={filters} tableToolbar={tableToolbar} businessPartnerId={business_partner_id} prevJobId={prev_job_id} />
  )
}

export default OrderWithoutInvoices;
