import axios from 'axios'
import ReactOnRails from 'react-on-rails';

const defaultOptions = {
  headers: ReactOnRails.authenticityHeaders()
}

axios.defaults.withCredentials = true
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Accept'] = 'application/json'

export const axiosInstance = axios.create(defaultOptions)

export const routes = {
  absences: () => '/api/frontend/absences',
  absenceReasons: () => '/api/frontend/absence_reasons',
  activeStaffPerMonths: () => '/api/frontend/active_staff_per_months',
  addRangeBusinessPartnerServiceFeature: (id) => `/api/frontend/business_partner_service_features/${id}/add_range`,
  addServiceFeatures: (id) => `/api/frontend/business_partners/${id}/add_service_features`,
  apprenticeships: () => '/api/frontend/apprenticeships',
  articleBillFeatures: () => '/api/frontend/article_bill_features',
  articleConsumption: (id) => `/api/frontend/article_consumptions/${id}`,
  articleConsumptions: () => '/api/frontend/article_consumptions',
  articleDisposals: () => '/api/frontend/article_disposals',
  articleGroups: () => '/api/frontend/article_groups',
  articlePurchases: () => '/api/frontend/article_purchases',
  articles: () => '/api/frontend/articles',
  articlesLoadPlantProtectionProducts: () => '/articles/load_plant_protection_products',
  bills: () => '/api/frontend/bills',
  booking: (id) => `/api/frontend/bookings/${id}`,
  bookings: () => '/api/frontend/bookings',
  bookingsExchangeStaffOrders: () => '/api/frontend/bookings/exchange_staff_orders',
  bookingCodes: () => '/api/frontend/booking_codes',
  bookingResult: (id) => `/api/frontend/booking_results/${id}`,
  bookingResults: () => '/api/frontend/booking_results',
  bookingResultItem: (id) => `/api/frontend/booking_result_items/${id}`,
  businessAreas: () => '/api/frontend/business_areas',
  bookingsSummary: () => '/api/frontend/bookings_summary',
  businessPartner: (id) => `/api/frontend/business_partners/${id}`,
  businessPartnerAreaRelations: (id) => `/api/frontend/business_partners/${id}/update_area_relations`,
  businessPartnerComment: (id) => `/api/frontend/business_partner_comments/${id}`,
  businessPartnerComments: () => '/api/frontend/business_partner_comments',
  businessPartnerDunningBills: () => '/api/frontend/ueber_dunnings/bills',
  businessPartnerGroups: () => '/api/frontend/business_partner_groups',
  businessPartners: () => '/api/frontend/business_partners',
  businessPartnerServiceFeature: (id) => `/api/frontend/business_partner_service_features/${id}`,
  businessPartnerServiceFeatures: () => '/api/frontend/business_partner_service_features',
  businessPartnerServices: () => '/api/frontend/business_partner_service_features/services',
  businessPartnersSubcontractors: () => '/api/frontend/business_partners_subcontractors',
  cancelBooking: (id) => `/api/frontend/bookings/${id}/cancel_booking`,
  child: (id) => `/api/frontend/children/${id}`,
  children: () => '/api/frontend/children',
  chroniclesStaffPerDays: () => '/api/frontend/chronicles/staff_per_days',
  cloneBusinessPartnerServiceFeatures: () => '/api/frontend/business_partner_service_features/clone',
  combination: (id) => `/api/frontend/combinations/${id}`,
  combinations: () => '/api/frontend/combinations',
  companies: () => '/api/frontend/companies',
  company_statistics: () => '/api/frontend/revenue_accounts/company_statistics',
  contact: (id) => `/api/frontend/contacts/${id}`,
  contacts: () => '/api/frontend/contacts',
  costCenters: () => '/api/frontend/cost_centers',
  countries: () => '/api/frontend/countries',
  currentCustomizing: () => '/api/frontend/customizings',
  customizing: (id) => `/api/frontend/customizings/${id}`,
  customizings: () => '/api/frontend/business_partners/customizings',
  dataServiceForm: () => '/api/frontend/services/data_for_service_form',
  deliverySlips: () => '/api/frontend/delivery_slips',
  dieselBooks: () => '/api/frontend/diesel_books',
  dispositions: () => '/api/frontend/dispositions',
  documentOfBookingReport: (id) => `/api/frontend/document_of_booking_reports/${id}`,
  documentOfBookingReports: () => '/api/frontend/document_of_booking_reports',
  documentOfBusinessPartner: (id) => `/api/frontend/document_of_business_partners/${id}`,
  documentOfBusinessPartners: () => '/api/frontend/document_of_business_partners',
  documentOfResource: (id) => `/api/frontend/document_of_resources/${id}`,
  documentOfResources: () => '/api/frontend/document_of_resources',
  downloadDocumentOfBookingReports: (id) => `/api/frontend/document_of_booking_reports/${id}/download`,
  dunnings: () => '/api/frontend/dunnings',
  exports: () => '/api/frontend/exports',
  farmerLoginNotifications: () => '/api/frontend/farmer_login_notifications',
  fields: () => '/api/frontend/fields',
  increaseAllPrices: (id) => `/api/frontend/business_partners/${id}/increase_all_prices`,
  jobProgress: (id) => `jobs/${id}`,
  licenseChronicles: () => '/api/frontend/license_chronicles',
  locations: () => '/api/frontend/locations',
  machine: (id) => `/api/frontend/machines/${id}`,
  machineBookings: () => '/api/frontend/bookings/create_machine_booking',
  machineComment: (id) => `/api/frontend/machine_comments/${id}`,
  machineComments: () => '/api/frontend/machine_comments',
  machineGroupForServiceFeatures: () => '/api/frontend/machine_group_for_service_features',
  machineGroups: () => '/api/frontend/machine_groups',
  machineGroupStatistics: () => '/api/frontend/statistics/machine_groups',
  machineRepairs: () => '/api/frontend/machine_repairs',
  machines: () => '/api/frontend/machines',
  newServices: () => '/api/frontend/services',
  offers: () => '/api/frontend/offers',
  order: (id) => `/api/frontend/orders/${id}`,
  orderArticle: (id) => `/api/frontend/order_articles/${id}`,
  orderArticles: () => '/api/frontend/order_articles',
  orderPlantWaste: (id) => `/api/frontend/order_plant_wastes/${id}`,
  orderPlantWastes: () => '/api/frontend/order_plant_wastes',
  orderPlantWastes: () => '/api/frontend/order_plant_wastes',
  orders: () => '/api/frontend/orders',
  ordersWithoutInvoices: () => '/api/frontend/orders_without_invoices',
  PlantProtectionProducts: () => '/api/frontend/plant_protection_products',
  plantWasteAnalyses: () => '/api/frontend/plant_waste_analyses',
  purchaseBills: () => '/api/frontend/purchase_bills',
  refreshDocumentOfBookingReports: (id) => `/api/frontend/document_of_booking_reports/${id}/refresh`,
  remunerationPayrolls: () => '/api/frontend/remuneration/payrolls',
  remunerationWages: () => '/api/frontend/remuneration/wages',
  remunerationWage: (id) => `/api/frontend/remuneration/wages/${id}`,
  resetLocation: () => `/locations/reset`,
  ressourceLocationHistories: () => '/api/frontend/ressource_location_histories',
  ressourceLocationHistoriesRessource: () => '/api/frontend/ressource_location_histories/ressource',
  ressourceLocationHistory: (id) => `/api/frontend/ressource_location_histories/${id}`,
  revenue_statistics: () => '/api/frontend/revenue_accounts/revenue_statistics',
  revenueAccount: (id) => `/api/frontend/revenue_accounts/${id}`,
  revenueCode: (id) => `/api/frontend/revenue_codes/${id}`,
  revenueCodes: () => '/api/frontend/revenue_codes',
  serviceBusinessPartnerStatistics: () => '/api/frontend/statistics/service_business_partners',
  serviceFeatureChronicles: () => '/api/frontend/service_feature_chronicles',
  serviceFeatureRows: (id) => `/api/frontend/service_feature_rows/${id}`,
  serviceFeatures: () => '/api/frontend/service_features',
  serviceFields: () => '/api/frontend/service_fields',
  serviceGroupStatistics: () => '/api/frontend/statistics/service_groups',
  services: () => '/api/frontend/services',
  setCompany: (id) => `/business_partners/${id}/set_company`,
  setLocation: (id) => `/locations/${id}/set`,
  splitBooking: (id) => `/api/frontend/bookings/${id}/split_booking`,
  sites: () => '/api/frontend/sites',
  staff: (id) => `/api/frontend/staffs/${id}`,
  staffAdditionalRoles: () => '/api/frontend/staffs/additional_staff_roles',
  staffAddAdditionalRoles: () => '/api/frontend/staffs/add_additional_staff_roles',
  staffCustomizing: (id) => `/api/frontend/staff_customizings/${id}`,
  staffRoles: () => '/api/frontend/staffs/staff_roles',
  staffOrders: () => '/api/frontend/staff_orders',
  staffOvertime: () => '/api/frontend/staff_overtimes',
  staffQualification: (id) => `/api/frontend/staff_qualifications/${id}`,
  staffQualifications: () => '/api/frontend/staff_qualifications',
  staffs: () => '/api/frontend/staffs',
  stopBooking: (id) => `/api/frontend/bookings/${id}/stop_booking`,
  ueberBills: () => '/api/frontend/business_partners_without_invoices',
  ueberDunnings: () => '/api/frontend/ueber_dunnings',
  units: () => '/api/frontend/units',
  updateDateRange: (id) => `/staff_customizings/${id}/update_current_start_and_end_date`,
  updateLocale: () => '/api/frontend/staff_customizings/update_locale',
  vendors: () => '/api/frontend/vendors',
}
