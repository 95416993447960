import React, { useEffect, useRef, useMemo } from 'react';
import {includes} from 'lodash';

const Map = ({fields = [], selected = [], onClick = (field) => null, businessPartnerRef, onUpdatePolygon, drawPolygonEnabled = false, drawLineEnabled = false}) => {
    const mapRef = useRef(null);
    const boundsRef = useRef(new window.google.maps.LatLngBounds());
    const googleMapRef = useRef(null);
    const markersRef = useRef([])
    const polygonRef = useRef(null)
    const strokeColor = (fieldData) => {
      if (includes(selected, fieldData.id)) {
        return '#ff0000';
      }
      return fieldData.used_in_order ? '#e85454' : fieldData.disposed_in_order ? '#E87954' : '#0066ff';
    };

    useEffect(() => {
        if(!drawPolygonEnabled && !drawLineEnabled) return

        markersRef.current.map(marker => {
            marker.setMap(null); // Entfernt den Marker von der Karte
        })

        markersRef.current = []

        drawPolygon()

        googleMapRef.current.addListener('click', (event) => {
            addMarker(event.latLng);
        });
    }, [drawLineEnabled, drawPolygonEnabled]);

    const showFieldsOnMap = () => {
      const fieldsToShow = fieldsData()
      if(fieldsToShow.length === 0) return

      fieldsToShow.forEach(field => {
            field.polygon.setMap(googleMapRef.current);
            boundsRef.current.extend(new window.google.maps.LatLng(field.latitude, field.longitude));
      });

      fitBounds()
    };

    const geojsonToPath = (geojson) => {
        const path = [];
        if (geojson) {
            geojson.coordinates.forEach((ext) => {
                ext.forEach((coordinate) => {
                    const latLng = new window.google.maps.LatLng(coordinate[1], coordinate[0]);
                    path.push(latLng);
                });
            });
        }
        return path;
    };

    const drawPolygon = () => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
        }

        const paths = markersRef.current.map(marker => marker.getPosition());

        onUpdatePolygon(paths)

        if(drawPolygonEnabled) {
            polygonRef.current = new window.google.maps.Polygon({
                paths,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
            });
        }


        if(drawLineEnabled) {
            polygonRef.current = new window.google.maps.Polyline({
                path: paths,
                geodesic: true,
                strokeColor: "#0000FF",
                strokeOpacity: 0.8,
                strokeWeight: 2
            });
        }

        polygonRef.current.setMap(googleMapRef.current);
    };

  const initMap = () => {
    const mapElement = mapRef.current

    const mapOptions = {
      tilt: 0, // Prevent incorrect click lat/long issue
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      gestureHandling: 'greedy',
      center: { lat: 52.3611591, lng: 7.46929 }, // Startpunkt für die Karte
      zoom: 16, // Zoom-Level, angepasst an die gegebene Position
    };

    // Create the map instance
    googleMapRef.current = new window.google.maps.Map(mapElement, mapOptions);
  };

    const addMarker = (location) => {
        const marker = new window.google.maps.Marker({
            position: location,
            map: googleMapRef.current,
            title: 'Neuer Punkt',
            draggable: true, // Marker ist nun verschiebbar
            icon: {
                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
            },
        });

        // Speichern des Markers in markersRef, um später darauf zugreifen zu können
        markersRef.current.push(marker);

        drawPolygon()

        // Beispiel für ein Click-Event auf den Marker (z.B. Löschen des Markers)
        marker.addListener('rightclick', () => {
            marker.setMap(null); // Entfernt den Marker von der Karte
            markersRef.current = markersRef.current.filter((m) => m !== marker); // Entfernt ihn aus dem Array

            drawPolygon()
        });

        // Event listener für Drag-End, um die neue Position des Markers zu erhalten
        marker.addListener('dragend', () => {
            const newPosition = marker.getPosition();
            console.log('Neuer Standort des Markers:', newPosition.lat(), newPosition.lng());

            drawPolygon()
        });
    };

  useEffect(() => {
    initMap()
    showFieldsOnMap()
  }, [])

  useEffect(() => {
    showFieldsOnMap()
  }, [fields, selected, businessPartnerRef.current]);

  const fieldsData = () => {
    return fields.map((fieldData) => {
      const path = geojsonToPath(fieldData.poly);
      const options = {
        strokeColor: strokeColor(fieldData),
        strokeWeight: 1,
        fillColor: fieldData.used_in_order ? '#74E854' : fieldData.disposed_in_order ? '#E87954' : '#0066ff',
        fillOpacity: includes(selected, fieldData.id) ? 0.6 : 0.3,
        paths: path,
        id: fieldData.id,
        name: fieldData.name_with_hectare,
        latitude: fieldData.latitude,
        longitude: fieldData.longitude
      };
      const polygon = new window.google.maps.Polygon(options);
      polygon.setMap(googleMapRef.current);

      // Füge einen Marker hinzu
      const markerPosition = new window.google.maps.LatLng(fieldData.latitude, fieldData.longitude);

      const createSvgWithText = (text) => {
        const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");

        // Setze den Textinhalt und andere Attribute für das Text-Element
        textElement.setAttribute("x", "0");
        textElement.setAttribute("y", "15");
        textElement.setAttribute("font-size", "12");
        textElement.setAttribute("font-family", "Arial");
        textElement.textContent = text;

        // Füge das Text-Element dem SVG hinzu
        svgElement.appendChild(textElement);

        // Füge das SVG dem DOM hinzu (temporär, um die Breite des Textes zu messen)
        document.body.appendChild(svgElement);

        // Messen der Textbreite
        const textWidth = textElement.getBBox().width;

        // Entferne das temporäre SVG aus dem DOM
        document.body.removeChild(svgElement);

        // Erstelle das finale SVG mit dynamischer Breite basierend auf der Textbreite
        const svgString = `
                <svg xmlns="http://www.w3.org/2000/svg" width="${textWidth + 20}" height="30">
                    <rect x="0" y="0" width="${textWidth + 10}" height="20" fill="white" stroke="black" rx="3" ry="3" />
                    <text x="50%" y="40%" alignment-baseline="middle" text-anchor="middle" fill="black" font-size="12" font-family="Arial">${text}</text>
                </svg>
    `;

        return {
          url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgString)}`,
          width: textWidth + 20,  // Breite des SVGs inklusive Padding
          height: 30  // Höhe des SVGs
        };
      };

      // Jetzt passe den Marker an
      const fieldDataSvg = createSvgWithText(fieldData.name_with_hectare);

      const marker = new window.google.maps.Marker({
        position: markerPosition,
        map: googleMapRef.current,
        icon: {
          url: fieldDataSvg.url, // Die URL für das dynamische SVG
          scaledSize: new window.google.maps.Size(fieldDataSvg.width, fieldDataSvg.height), // Dynamische Größe basierend auf Textbreite und Höhe
          anchor: new window.google.maps.Point(fieldDataSvg.width / 2, fieldDataSvg.height / 2) // Zentriere den Anker in der Mitte des SVGs
        }
      });


      // Klick-Event für das Polygon
      polygon.addListener('click', () => {
        onClick(fieldData)
      });

      return { polygon, marker, ...fieldData };
    });
  }

  const fitBounds = () => {
    if (!boundsRef.current.isEmpty()) {
      googleMapRef.current.fitBounds(boundsRef.current);
    }
  };

// You can trigger fitBounds() when needed by calling it


return (
        <div ref={mapRef} style={{ height: '50vh', width: '100%', marginTop: "2rem" }}></div>
    );
};

export default Map;
