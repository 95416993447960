import React, { useState, useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import TableButton from '../../components/Table/TableButton'
import { isEmpty } from 'lodash';
import { TrashSquareIcon } from '../../components/Icons';
import * as style from './index.module.css';
import Toastr from '../../components/Toastr';
import Form from 'react-bootstrap/Form';
import EditableTextInput from '../../components/Table/EditableTextInput';
import EditableSelectInput from '../../components/Table/EditableSelectInput';

const Table = ({tableToolbar}) => {
  const { t, i18n } = useTranslation()
  const [trigger, setTrigger] = useState(false)

  const getCombinations = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.combinations(), { params: params })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const codeHtml = (row) => {
    return(
      <TableButton
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.client_specific_number}
      />
    )
  }

  const deleteCombination = (id) => {
    axiosInstance.delete(routes.combination(id))
    .then(resp => {
      Toastr({message: t('combinations.delete_success'), options: { showDuration: 2000}})
      setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
    })
    .catch(error => {
      Toastr({
        type: 'error',
        message: error.response?.data?.error || t('critical_error')
      })
    })
  }

  const updateCombination = (id, params) => {
    axiosInstance.put(routes.combination(id), params)
      .then(resp => {
        Toastr({message: t('combinations.update_success'), options: { showDuration: 2000}})
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const getStaffs = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      without_admin_and_farmer: 'true',
      without_sub_contractor: 'true',
      show_in_dispo: 'true',
      active: 'true',
      query: inputText
    }

    return axiosInstance.get(routes.staffs(), { params: params })
      .then((response) => {
        return response.data.data.map((staff) => ({
          value: staff.id,
          label: staff.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getFahrzeugMachines = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      serialize: 'short',
      active: 'true',
      fahrzeug: 'true',
      query: inputText
    }

    return axiosInstance.get(routes.machines(), { params: params })
      .then((response) => {
        return response.data.data.map((machine) => ({
          value: machine.id,
          label: machine.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getGeraetMachines = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      sort: 'name',
      serialize: 'short',
      active: 'true',
      geraet: 'true',
      query: inputText
    }

    return axiosInstance.get(routes.machines(), { params: params })
      .then((response) => {
        return response.data.data.map((machine) => ({
          value: machine.id,
          label: machine.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const deleteHtml = (row) => {
    return(
      <div className={style['trash-button']} onClick={() => deleteCombination(row.original.id)}><TrashSquareIcon className="fs-2hx text-danger"/></div>
    )
  }

  const activeHtml = (row) => {
    const isActive = row.original.attributes?.active
    const label = isActive ? t('yes') : t('no')
    return(
      <Form.Check
        onChange={() => updateCombination(row.original.id, {active: !isActive})}
        checked={isActive}
        type="switch"
        id="combination-active-switch"
        label={label}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'name',
        accessorFn: row => row?.attributes?.name,
        header: t('shared.name'),
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='name' initialValue={row.original.attributes.name} handleSubmit={updateCombination} />
      },
      {
        id: 'driver',
        accessorFn: row => row?.attributes?.staff_id,
        header: t('shared.driver'),
        cell: ({ row }) => {
          const staffInitialValue = { value: row.original.attributes?.staff_id, label: row.original.attributes?.staff_long_name }
          return <EditableSelectInput id={row.original.id} attribute='staff_id' initialValue={staffInitialValue} handleSubmit={updateCombination} getEntities={getStaffs}/>
        }
      },
      {
        id: 'machine',
        accessorFn: row => row?.attributes?.fahrzeug_id,
        header: t('shared.vehicle'),
        cell: ({ row }) => {
          const staffInitialValue = { value: row.original.attributes?.fahrzeug_id, label: row.original.attributes?.machine_long_name }
          return <EditableSelectInput id={row.original.id} attribute='fahrzeug_id' initialValue={staffInitialValue} handleSubmit={updateCombination} getEntities={getFahrzeugMachines}/>
        }
      },
      {
        id: 'implement_1',
        accessorFn: row => row?.attributes?.geraet1_id,
        header: t('shared.device1'),
        cell: ({ row }) => {
          const geraet1InitialValue = { value: row.original.attributes?.geraet1_id, label: row.original.attributes?.geraet_1_long_name }
          return <EditableSelectInput id={row.original.id} attribute='geraet1_id' initialValue={geraet1InitialValue} handleSubmit={updateCombination} getEntities={getGeraetMachines}/>
        }
      },
      {
        id: 'implement_2',
        accessorFn: row => row?.attributes?.geraet2_id,
        header: t('shared.device2'),
        cell: ({ row }) => {
          const geraet2InitialValue = { value: row.original.attributes?.geraet2_id, label: row.original.attributes?.geraet_2_long_name }
          return <EditableSelectInput id={row.original.id} attribute='geraet2_id' initialValue={geraet2InitialValue} handleSubmit={updateCombination} getEntities={getGeraetMachines}/>
        }
      },
      {
        id: 'active',
        accessorFn: row => row?.attributes?.active,
        header: t('shared.active_question'),
        size: 80,
        minSize: 80,
        cell: ({ row }) => {
          return activeHtml(row)
        },
      },
      {
        id: 'delete',
        accessorFn: row => row?.attributes?.active,
        header: null,
        size: 50,
        minSize: 50,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getCombinations}
      tableToolbar={tableToolbar}
      trigger={trigger}
      initialSortDirection={'asc'}
    />
  )
}

export default Table
