import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import { isRequired } from '../../components/Form/validations';
import TableButton from '../../components/Table/TableButton';
import ErrorField from '../../components/Form/ErrorField';
import AsyncSelectAdapter from '../../components/Form/AsyncSelectAdapter';
import debounce from "lodash/debounce";
import { Tooltip } from 'bootstrap'
import QuestionMark from '../Icons/QuestionMark';
import DateRangePicker from "../DateRangePicker";
import moment from 'moment';

const Create = ({ onClose, clientSpecificNumber }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [specificNumber, setSpecificNumber] = [clientSpecificNumber]

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [onClose]);

  const onSubmit = async values => {
    const data = {
      site: {
        name: values?.name,
        client_specific_number: values?.clientSpecificNumber,
        external_number: values?.externalNumber,
        business_partner_id: values?.businessPartnerId.value,
        site_street: values?.siteStreet,
        site_postal_code: values?.sitePostalCode,
        site_city: values?.siteCity,
        bill_address_name: values?.billAddressName,
        bill_address_street: values?.billAddressStreet,
        bill_address_city: values?.billAddressCity,
        bill_address_postal_code: values?.billAddressPostalCode,
        start_date: values?.period?.startTime.format("DD.MM.YYYY"),
        end_date: values?.period?.endTime.format("DD.MM.YYYY")
      }
    };

    axiosInstance.post(routes.sites(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
        window.location.href = response.data.redirect_url
      })
      .catch(error => {
        setErrors(error.response.data.error);
      })
  }

  const fetchData = (url, params) => {
    return axiosInstance.get(url, { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.long_name,
      }))
      )
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadCustomers = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'code', query: inputText, active: true };
    return fetchData(routes.businessPartners(), params);
  });

  const DateSelectAdapter = ({ input, meta, ...rest }) => {
    const [isDateSelected, setIsDateSelected] = useState(false);
    const [placeholder, setPlaceholder] = useState("");

    const handleDateChange = (startTime, endTime) => {
      // DateRangePicker component is expecting a Promise.
      return new Promise((resolve, reject) => {
        try {
          input.onChange({ startTime, endTime });
          const formattedStartDate = moment(startTime).format("DD-MM-YYYY");
          const formattedEndDate = moment(endTime).format("DD-MM-YYYY");

          setPlaceholder(`${formattedStartDate}  ${formattedEndDate}`);
          setIsDateSelected(true);

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    return (
      <DateRangePicker
        placeholder={isDateSelected ? placeholder : t('range_date_placeholder')}
        classList="cursor-pointer form-control text-left"
        options={{
          showDropdowns: true,
          startDate: input.value?.startTime ? moment(input.value.startTime) : moment(),
          endDate: input.value?.endTime ? moment(input.value.endTime) : moment(),
          onChange: handleDateChange,
          autoApply: true,
          autoUpdateInput: false
        }}
        {...rest}
      />
    );
  };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        name: '',
        clientSpecificNumber: specificNumber,
        externalNumber: '',
        businessPartnerId: '',
        siteStreet: '',
        sitePostalCode: '',
        siteCity: '',
        billAddressName: '',
        billAddressStreet: '',
        billAddressCity: '',
        billAddressPostalCode: '',
        startDate: '',
        endDate: ''
      }}
      validate={values => {
        let errors = {}
        errors.name = isRequired(values.name)
        errors.businessPartnerId = isRequired(values.businessPartnerId)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <>
            <div className="container">
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('name_of_site')}</label>
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <ErrorField name="name" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('own_project_number')}</label>
                  <Field
                    name='clientSpecificNumber'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('own_project_placeholder')}
                  >
                  </Field>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('external_project_number')}</label>
                  <Field
                    name='externalNumber'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('number')}
                  >
                  </Field>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('customer')}</label>
                  <Field
                    name='businessPartnerId'
                    component={AsyncSelectAdapter}
                    loadOptions={loadCustomers}
                    placeholder={t('select')}
                    isClearable={true}
                  >
                  </Field>
                  <ErrorField name="businessPartnerId" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('location')}</label>
                  <Field
                    name='siteStreet'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('street')}
                  >
                  </Field>
                  <Field
                    name='siteCity'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('city')}
                  >
                  </Field>
                  <Field
                    name='sitePostalCode'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('postcode')}
                  >
                  </Field>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('billing_address')}</label>
                  <button
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('billing_address_title')}
                    className='btn btn-primary-outline btn-sm ps-2 pe-0'
                  >
                    <QuestionMark />
                  </button>
                  <Field
                    name='billAddressName'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('name')}
                  >
                  </Field>
                  <Field
                    name='billAddressStreet'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('street')}
                  >
                  </Field>
                  <Field
                    name='billAddressCity'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('city')}
                  >
                  </Field>
                  <Field
                    name='billAddressPostalCode'
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder={t('postcode')}
                  >
                  </Field>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('period')}</label>
                  <Field
                    name='period'
                    component={DateSelectAdapter}
                    isClearable={true}
                  >
                  </Field>
                </div>
              </div>
              {submitError && <div className="form-error-message">{submitError}</div>}
              <div className="modal-footer">
                <TableButton
                  className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                  type="button"
                  onClick={onClose}
                  title={t('cancel')}
                />
                <TableButton
                  className="btn btn-primary fw-semibold px-6"
                  type="submit"
                  disabled={submitting || pristine}
                  title={t('create')}
                />
              </div>
            </div>
          </>
        </form>
      )}
    />
  )
}

export default Create;
