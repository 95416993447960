import React, { useCallback } from 'react';
import debounce from "lodash/debounce";
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';


const Search = () => {
  const { t } = useTranslation()
  const fetchData = (url, params) => {
    return axiosInstance.get(url, { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.long_name,
      })))
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadStaffOptions = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 30, sort: 'code', query: inputText, without_admin_and_farmer: "true", active: "true", with_sub_contractor: "true"};
    return fetchData(routes.staffs(), params);
  });

  const loadMachineOptions = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'code', query: inputText, with_sub_contractor: "true"};
    return fetchData(routes.machines(), params);
  });

  const selectOne = (option, url_key) => {
    window.location.assign(url_key + option.value)
  }

  return (
    <>
      <div className="card mt-4">
        <div className="card-body">
          <span className="fs-1 fw-bold mb-4">
            {t('find_employees_and_machines_from_subcontractors')}
          </span>
          <div className='separator my-4'></div>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center">
                <span className='text-gray-500 me-3'>{t('staff')}</span>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadStaffOptions}
                  defaultOptions
                  id='staffs_select'
                  placeholder={t('select')}
                  onChange={(value) => selectOne(value, '/staffs/')}
                  className="min-w-300px"
                />
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center">
                <span className='text-gray-500 me-3'>{t('machine_group.table.machines')}</span>
                <div className="d-flex align-items-center"></div>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadMachineOptions}
                  defaultOptions
                  id='machines_select'
                  placeholder={t('select')}
                  onChange={(value) => selectOne(value, '/machines/')}
                  className="min-w-300px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Search;
