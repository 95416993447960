import React, { useCallback, useState } from 'react';
import Table from './Table';
import { useTranslation } from 'react-i18next';
import debounce from "lodash/debounce";
import { axiosInstance, routes } from '../../utils/api_base';
import DateRangePicker from '../../components/DateRangePicker';
import moment from 'moment';

const Bookings = ({ filters }) => {
  const { t, i18n } = useTranslation()
  const urlParams = new URLSearchParams(window.location.search);
  const startOfYear = new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0];
  const endOfYear = new Date(new Date().getFullYear(), 11, 31).toISOString().split('T')[0];
  const startDate = urlParams.get('start_date') ?? startOfYear;
  const endDate = urlParams.get('end_date') ?? endOfYear;

  const [dateRange, setDateRange] = useState({ startDate: startDate, endDate: endDate });
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [placeholder, setPlaceholder] = useState("");

  const handleDateChange = (startTime, endTime) => {
    // DateRangePicker component is expecting a Promise.
    return new Promise((resolve, reject) => {
      try {
        const formattedStartDate = moment(startTime).format("DD-MM-YYYY");
        const formattedEndDate = moment(endTime).format("DD-MM-YYYY");

        setPlaceholder(`${formattedStartDate} - ${formattedEndDate}`);
        setDateRange({ startDate: startTime, endDate: endTime });
        setIsDateSelected(true);

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const tableToolbar = () => {
    return (
      <DateRangePicker
        placeholder={isDateSelected ? placeholder : t('range_date_placeholder')}
        classList="cursor-pointer form-control text-left form-control-sm"
        style={{ width: "180px" }}
        language={i18n.language}
        options={{
          showDropdowns: true,
          onChange: handleDateChange,
          autoApply: true,
          autoUpdateInput: false,
          linkedCalendars: false
        }}
      />
    )
  }

  const fetchData = (url, params, attr) => {
    return axiosInstance.get(url, { params })
      .then(response => {
        const options = response.data.data.map(item => ({
          value: item.attributes.id,
          label: item.attributes[attr],
        }));

        options.unshift({ value: "", label: "All" });

        return options;
      })
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadCustomers = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 30, query: inputText, active: "true", customers: "true" };
    return fetchData(routes.businessPartners(), params, "long_name");
  });

  const loadOrders = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 30, query: inputText };
    return fetchData(routes.orders(), params, "name");
  });

  const loadStaffs = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 30, query: inputText, without_admin_and_farmer: "true" };
    return fetchData(routes.staffs(), params, "long_name");
  });

  const loadMachines = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 30, query: inputText, active: "true", serialize: "short" };
    return fetchData(routes.machines(), params, "long_name");
  });

  const loadServices = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 30, query: inputText, serialize: "short" };
    return fetchData(routes.services(), params, "long_name");
  });

  const loadArticles = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 30, query: inputText };
    return fetchData(routes.articles(), params, "long_name");
  });

  const extraFilters = [
    {
      label: t("customer"),
      attribute: "business_partner_id",
      type: 'async',
      select: true,
      include_all: true,
      options: loadCustomers
    },
    {
      label: t("order"),
      attribute: "order_id",
      type: 'async',
      select: true,
      include_all: true,
      options: loadOrders
    },
    {
      label: t("staff"),
      attribute: "staff_id",
      type: 'async',
      select: true,
      include_all: true,
      options: loadStaffs
    },
    {
      label: t("vehicle"),
      attribute: "machine_id",
      type: 'async',
      select: true,
      include_all: true,
      options: loadMachines
    },
    {
      label: t("article"),
      attribute: "article_id",
      type: 'async',
      select: true,
      include_all: true,
      options: loadArticles
    },
    {
      label: t("service"),
      attribute: "service_id",
      type: 'async',
      select: true,
      include_all: true,
      options: loadServices
    }
  ];

  const combinedFilters = [...extraFilters, ...filters];

  return (
    <Table
      filters={combinedFilters}
      tableToolbar={tableToolbar}
      startDate={dateRange.startDate}
      endDate={dateRange.endDate}
    />
  )
}

export default Bookings;
