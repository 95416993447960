import React, { useState } from 'react';
import Table from './Table';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../components/DateRangePicker';
import moment from 'moment';

const LicenseChronicles = ({ filters }) => {
  const { t, i18n } = useTranslation()

  const urlParams = new URLSearchParams(window.location.search);
  const fullYear = new Date().getFullYear();
  const startOfYear = new Date(fullYear, 0, 1).toISOString().split('T')[0];
  const endOfYear = new Date(fullYear, 11, 31).toISOString().split('T')[0];
  const startDate = urlParams.get('start_date') ?? startOfYear;
  const endDate = urlParams.get('end_date') ?? endOfYear;
  const [dateRange, setDateRange] = useState({ startDate: startDate, endDate: endDate });
  const [placeholder, setPlaceholder] = useState(`${startDate} - ${endDate}`);

  const handleDateChange = (startTime, endTime) => {
    const dateFormat = i18n.language === "de" ? "DD.MM.YYYY" : "DD-MM-YYYY";
    // DateRangePicker component is expecting a Promise.
    return new Promise((resolve, reject) => {
      try {
        const formattedStartDate = moment(startTime).format(dateFormat);
        const formattedEndDate = moment(endTime).format(dateFormat);

        setPlaceholder(`${formattedStartDate} - ${formattedEndDate}`);
        setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const tableToolbar = () => {
    return (
      <DateRangePicker
        placeholder={placeholder}
        classList="cursor-pointer form-control text-left form-control-sm"
        style={{ width: "180px" }}
        language={i18n.language}
        options={{
          showDropdowns: true,
          onChange: handleDateChange,
          autoApply: true,
          autoUpdateInput: false,
          linkedCalendars: false
        }}
      />
    )
  }

  return (
    <Table filters={filters} tableToolbar={tableToolbar} startDate={dateRange.startDate} endDate={dateRange.endDate} />
  )
}

export default LicenseChronicles;
