export const NEW = 'NEW';
export const SENT = 'SENT';
export const INPROCESS = 'INPROCESS';
export const PENDING = 'PENDING';
export const BOOKED = 'BOOKED';
export const NO_BILL = 'NO_BILL';
export const BILLED = 'BILLED';
export const PAID = 'PAID';
export const PARTLY_PAID = 'PARTLY_PAID';
export const TO_BE_DUNNED = 'TO_BE_DUNNED';
export const PENDING_REVERSED = 'PENDING_REVERSED';
export const REVERSED = 'REVERSED';
export const NEW_OFFER = 'NEW_OFFER';
export const BOOKED_OFFER = 'BOOKED_OFFER';
export const SECOND_REMINDER = 'SECOND_REMINDER';
export const FIRST_REMINDER = 'FIRST_REMINDER';
export const CANCELED = 'CANCELED';
export const INNO_INPROCESS = 'INNO_INPROCESS';
export const INNO_DONE = 'INNO_DONE';
export const OLD_BILL_BOOKING = 'OLD_BILL_BOOKING';
export const OLD_ARTICLE_COST_BOOKING = 'OLD_ARTICLE_COST_BOOKING';
export const INNO_NEW = 'INNO_NEW';
export const INKASSO = 'INKASSO';
export const THIRD_REMINDER = 'THIRD_REMINDER';
