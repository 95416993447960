import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { CheckSquareIcon, CrossSquareIcon, EditNotepadIcon } from '../Icons';
import * as style from './index.module.css';
import classNames from 'classnames';
import CurrencyInput from 'react-currency-input-field';

const EditableTextInput = ({ id, attribute, initialValue, handleSubmit, label }) => {
  const [editable, setEditable] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [newValue, setNewValue] = useState({});

  const onSubmit = async () => {
    setEditable(false)
    await handleSubmit(id, { [attribute]: newValue })
  }

  const inputHtml = () => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ [attribute]: initialValue }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={style.form}>
              <CurrencyInput
                name={attribute}
                defaultValue={initialValue}
                groupSeparator=','
                fixedDecimalLength='2'
                decimalScale='2'
                decimalsLimit={2}
                precision={2}
                suffix=' €'
                decimalSeparator="."
                onValueChange={(value) => setNewValue(value)}
              />
              <div className={style['form-buttons']}>
                <button type="submit" disabled={submitting}>
                  <CheckSquareIcon className={classNames("fs-3 text-success", { "text-gray-500": submitting })} />
                </button>
                <button
                  type="button"
                  onClick={() => setEditable(false)}
                  disabled={submitting}>
                  <CrossSquareIcon className="fs-3 text-danger" />
                </button>
              </div>
            </div>
          </form>
        )}
      />
    )
  }

  const viewHtml = () => {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setEditable(true)}
      >
        {label || '- - - - -'}
        {isHovered && <EditNotepadIcon />}
      </div>
    )
  }
  return (
    <>
      {editable ? inputHtml() : viewHtml()}
    </>
  )
}

export default EditableTextInput
