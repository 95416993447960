import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../Table/EditableTextInput';
import EditableDatePickerInput from '../Table/EditableDatePickerInput';
import { Tooltip } from 'bootstrap'
import QuestionMark from '../Icons/QuestionMark';
import ToggleSwitch from '../ToggleSwitch';

const DetailsPanel = ({ staff, updateStaff }) => {
  const { t } = useTranslation()

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, []);

  return (
    <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8">
        <div className="card-body pt-15">
          <div className="d-flex flex-center flex-column mb-5">
            <h1 className="fs-3 text-gray-800 fw-bold mb-1">{staff.name}</h1>
            <div className="fs-5 fw-semibold text-muted mb-6">{staff.role?.toUpperCase()}</div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_customer_view_details" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
              {t('details')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span></div>
          </div>
          <div className="separator separator-dashed my-3"></div>
          <div id="kt_customer_view_details" className="collapse show">
            <div className="py-5 fs-6">
              <div className="badge badge-light-info d-inline">{staff.role_name}</div>
              <div className="fw-bold mt-5">{t("personal_number")}</div>
              <div className="text-gray-600">{staff.client_specific_number}</div>
              <div className="fw-bold mt-5">
                {t("email")}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('email_question_title')}
                  className='btn btn-sm p-2 mb-1'
                >
                  <QuestionMark />
                </span>
              </div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={staff.id}
                  attribute="email"
                  initialValue={staff.user_email}
                  handleSubmit={updateStaff}
                />
              </div>
              <div className="fw-bold mt-5">
                {t("password")}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('password_question_title')}
                  className='btn btn-sm p-2'
                >
                  <QuestionMark />
                </span>
              </div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={staff.id}
                  attribute="password"
                  initialValue={"*********"}
                  handleSubmit={updateStaff}
                />
              </div>
              <div className="fw-bold mt-5">{t("birthday")}</div>
              <div className="text-gray-600">
                <EditableDatePickerInput
                  id={staff.id}
                  attribute='birthday'
                  initialValue={staff.birthday || "2000-01-01"}
                  handleSubmit={updateStaff}
                />
              </div>
              <div className="fw-bold mt-5">{t("billing_address")}</div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={staff.id}
                  attribute="street"
                  initialValue={staff.street || "-"}
                  handleSubmit={updateStaff}
                />
                <EditableTextInput
                  id={staff.id}
                  attribute="postal_code"
                  initialValue={staff.postal_code || "-"}
                  handleSubmit={updateStaff}
                />
                <EditableTextInput
                  id={staff.id}
                  attribute="city"
                  initialValue={staff.city || "-"}
                  handleSubmit={updateStaff}
                />
              </div>
              <div className="fw-bold mt-5">
                {t("active")}
                <span
                  data-bs-toggle="tooltip"
                  data-bs-custom-class="tooltip-inverse"
                  data-bs-placement="top"
                  title={t('active_question_title')}
                  className='btn btn-sm p-2'
                >
                  <QuestionMark />
                </span>
              </div>
              <div className="align-items-center">
                <div className="fw-bold mb-2 me-5"></div>
                <div className="text-gray-600">
                  <ToggleSwitch
                    id={staff.id}
                    handleToggle={updateStaff}
                    attribute={"active"}
                    active={staff.active}
                  />
                </div>
              </div>
              <div className="fw-bold mt-5">{t("show_in_disposition")}</div>
              <div className="align-items-center">
                <div className="fw-bold mb-2 me-5"></div>
                <div className="text-gray-600">
                  <ToggleSwitch
                    id={staff.id}
                    handleToggle={updateStaff}
                    attribute={"show_in_dispo"}
                    active={staff.show_in_dispo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPanel;
