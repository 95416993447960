import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import TableButton from '../../components/Table/TableButton';
import { isEmpty } from 'lodash';
import { ArrowRightBase } from "../../components/Icons";

const Table = ({filters, tableToolbar}) => {
  const { t, i18n } = useTranslation()

  const getPartners = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.ueberBills(), { params: { ...params } })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const createLink = (row) => {
    return (
      <TableButton
        type="button"
        target="_blank"
        href={row?.original?.attributes?.create_link}
        icon={<ArrowRightBase className='fs-1' />}
        className="btn-light-primary me-3"
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'customer',
        header: t('shared.customer'),
        minSize: 70,
        size: 70,
        accessorFn: row => row?.attributes?.long_name,
        cell: row => row.getValue(),
      },
      {
        id: 'open_orders',
        header: t('orders'),
        size: 70,
        minSize: 70,
        accessorFn: row => row?.attributes?.open_orders,
        cell: row => row.getValue(),
      },
      {
        id: 'create_reminder',
        header: t('create_collective_invoice'),
        size: 70,
        minSize: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.create_link,
        cell: ({ row }) => createLink(row)
      },

    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      initialSortDirection={'asc'}
      getDataFn={getPartners}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
