import React from 'react';
import { useTranslation } from 'react-i18next';
import Documents from '../../components/SubContactors/DocumentsTable';
import CommentsTable from '../../components/BusinessPartners/CommentsTable';
import StaffsTable from '../../components/SubContactors/StaffsTable';
import MachinesTable from '../../components/SubContactors/MachinesTable';
import DetailSideBar from '../../components/SubContactors/sidebar';
import TableButton from '../../components/Table/TableButton';

const show = ({ id, currentStaffId, businessPartner, currentLocation, country }) => {

  const { t } = useTranslation()
  return (
    <>
      <div className='d-flex flex-column flex-lg-row'>
        <DetailSideBar
          currentCountry={country}
          businessPartner={businessPartner}
          currentLocation={currentLocation}
        />
        <div className='flex-lg-row-fluid ms-lg-15'>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#comments" role="button">
              {t('comments')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="comments" className="collapse show">
            <CommentsTable businessPartnerId={id} currentStaffId={currentStaffId} />
          </div >

          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#documents" role="button">
              {t('documents')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="documents" className="collapse show">
            <Documents
              tableName={'Documents'}
              business_partner_id={id}
              treaty={false}
            />
          </div >
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#staffs" role="button">
              {t('staff')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="staffs" className="collapse show">
            <StaffsTable
              tableName={'Staffs'}
              businessPartnerId={id}
            />
          </div >
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#machines" role="button">
              {t('Machines')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
          </div>
          <div className="separator my-3"></div>
          <div id="machines" className="collapse show">
            <MachinesTable
              businessPartner={businessPartner}
              tableName={'machines'}
              businessPartnerId={id}
            />
          </div >
          <TableButton
            type="button"
            target="_blank"
            href={'/business_partners/sub_contractors'}
            className="btn-light-primary my-3"
            title={t('overview')}
          />
        </div>
      </div>
    </>
  )
};

export default show;
