import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../Table/TableButton';
import { PlusIcon } from '../../Icons';
import Table from './Table';
import Create from './Create';
import Modal from '../../../components/Modal';

const Comments = ({ businessPartnerId, currentStaffId }) => {
  const { t } = useTranslation()
  const [commentsTrigger, setCommentsTrigger] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const tableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setModalOpen(true)}
        icon={<PlusIcon />}
        title={t('shared.add_to')}
      />
    )
  }

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('new_comment')}>
        <Create businessPartnerId={businessPartnerId} currentStaffId={currentStaffId} onClose={() => setModalOpen(false)} setTrigger={setCommentsTrigger} />
      </Modal>
      <Table tableToolbar={tableToolbar} businessPartnerId={businessPartnerId} setTrigger={setCommentsTrigger} trigger={commentsTrigger} currentStaffId={currentStaffId} />
    </>
  )
}

export default Comments;
