import BaseTable from '../../../components/Table';
import EditableTextInput from '../../../components/Table/EditableTextInput';
import Form from 'react-bootstrap/Form';
import React, { useMemo, useState, useEffect } from 'react';
import Toastr from '../../../components/Toastr';
import { TrashSquareIcon, HeartIcon } from '../../../components/Icons';
import { axiosInstance, routes } from '../../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import CurrencyInput from "../../../components/Table/EditableCurrencyInput";
import { pushToHistory } from '../../../utils/base';

const Table = ({ tableToolbar, filters, businessPartnerId, columnVisibility, indexTrigger }) => {
  const { t, i18n } = useTranslation()
  const [trigger, setTrigger] = useState(false)
  const appStore = ReactOnRails.getStore("appStore");
  const { currentUser, currentCustomizing } = appStore.getState()?.appStore || {};
  const getContacts = async (pagination, sorting, globalFilter, customFilters) => {

    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })

    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.staffs(), { params: { ...params, sub_contractor_id: businessPartnerId, serialize: 'full' } })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateStaff = (id, params) => {
    axiosInstance.put(routes.staff(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const updateStaffCustomizing = (id, params) => {
    axiosInstance.put(routes.staffCustomizing(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteStaff = (id) => {
    axiosInstance.delete(routes.staff(id))
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const CheckBoxActionHtml = (row) => {
    const isActive = row.original.attributes?.active
    const label = isActive ? t('true') : t('false')
    const showInDispoLabel = row.original.attributes?.show_in_dispo ? t('true') : t('false')
    const MaximizeEditableBillLabel = row.original.attributes.staff_customizing.attributes.maximize_editable_bill_input_width ? t('true') : t('false')
    const CustomizingId = row.original.attributes.staff_customizing.attributes.id

    return (
      <>
        <div className='d-flex gap-2 justify-content-end align-items-end' >
          {t('active_question')}
          <Form.Check
            onChange={() => updateStaff(row.original.id, { active: !isActive })}
            checked={isActive}
            type="switch"
            id={`active-${row.original.id}`}
            label={label}
          />
        </div>
        <div className='d-flex gap-2 justify-content-end align-items-end my-2'>
          {t('in_planning_question')}
          <Form.Check
            onChange={() => updateStaff(row.original.id, { show_in_dispo: !row.original.attributes?.show_in_dispo })}
            checked={row.original.attributes?.show_in_dispo}
            type="switch"
            id={`showInDispo-${row.original.id}`}
            label={showInDispoLabel}
          />
        </div>
        {
          currentUser.admin && (
            <div className='d-flex gap-2 justify-content-end align-items-end'>
              <HeartIcon className="fs-2 text-danger" />
              <Form.Check
                onChange={() => updateStaffCustomizing(CustomizingId, { maximize_editable_bill_input_width: !row.original.attributes.staff_customizing.attributes.maximize_editable_bill_input_width })}
                checked={row.original.attributes.staff_customizing.attributes.maximize_editable_bill_input_width}
                type="switch"
                id={`maximize_editable_bill_input_width_${row.original.id}`}
                label={MaximizeEditableBillLabel}
              />
            </div>
          )
        }
      </>
    )
  }

  const workingTimesHtml = (row) => {
    const id = row.original.id
    return (
      row.original.attributes.quantity_time_booking && (
        <a href={`/staffs/${id}/multi_bookings`}>
          {row.original.attributes.quantity_time_booking}
        </a>
      )
    );
  }

  const deleteHtml = (row) => {
    return (
      <div onClick={() => deleteStaff(row.original.id)}><TrashSquareIcon className="fs-2hx text-danger cursor-pointer" /></div>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.number'),
        size: 70,
        minSize: 70,
        accessorFn: row => row?.attributes?.client_specific_number,
        cell: ({ row }) => <EditableTextInput id={row.original.id} attribute='client_specific_number' initialValue={row.original.attributes.client_specific_number} handleSubmit={updateStaff} />
      },
      {
        id: 'first_name',
        header: t('shared.first_name'),
        accessorFn: row => row?.attributes?.user?.user_first_name,
        cell: ({ row }) => (
          <EditableTextInput
            id={row.original.id}
            attribute='first_name'
            initialValue={row.original.attributes.user_first_name}
            handleSubmit={(id, attr) => {
              const updateData = {
                user_attributes: {
                  id: row.original.attributes.user_id,
                  ...attr
                }
              };
              updateStaff(row.original.id, updateData);
            }}
          />
        )
      },
      {
        id: 'last_name',
        header: t('last name'),
        accessorFn: row => row?.attributes?.user_last_name,
        cell: ({ row }) => (
          <EditableTextInput
            id={row.original.id}
            attribute='last_name'
            initialValue={row.original.attributes.user_last_name}
            handleSubmit={(id, attr) => {
              const updateData = {
                user_attributes: {
                  id: row.original.attributes.user_id,
                  ...attr
                }
              };
              updateStaff(row.original.id, updateData);
            }}
          />
        )
      },
      {
        id: 'email',
        header: t('login'),
        accessorFn: row => row?.attributes?.user_email,
        cell: ({ row }) => (
          <EditableTextInput
            id={row.original.id}
            attribute='email'
            initialValue={row.original.attributes.user_email}
            handleSubmit={(id, attr) => {
              const updateData = {
                user_attributes: {
                  id: row.original.attributes.user_id,
                  ...attr
                }
              };
              updateStaff(row.original.id, updateData);
            }}
          />
        )
      },
      {
        id: 'password',
        header: t('shared.password'),
        size: 70,
        minSize: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.id,
        cell: ({ row }) => (
          <EditableTextInput
            id={row.original.id}
            attribute='password'
            initialValue=''
            handleSubmit={(id, attr) => {
              const updateData = {
                user_attributes: {
                  id: row.original.attributes.user_id,
                  ...attr
                }
              };
              updateStaff(row.original.id, updateData);
            }}
          />
        )
      },
      {
        id: 'cost_per_unit',
        header: t('shared.cost_rate'),
        accessorFn: row => row?.attributes?.cost_per_unit,
        cell: ({ row }) => (
          <CurrencyInput
            id={row.original.id}
            attribute="cost_per_unit"
            suffix={row.original.attributes.cost_per_unit.currency_iso}
            initialValue={row.original.attributes.cost_per_unit.cents / 10000}
            label={`${row.original.attributes.cost_per_unit.cents / 10000} €`}
            handleSubmit={(id, attr) => {
              const updateData = {
                staff: {
                  ...attr
                }
              };
              updateStaff(row.original.id, updateData);
            }}
          />
        )
      },
      {
        id: 'working_times',
        header: t('staffs.working_times_in_hrs'),
        size: 70,
        minSize: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.working_times,
        cell: ({ row }) => workingTimesHtml(row)
      },
      {
        id: 'check_box_actions',
        header: '',
        size: 220,
        minSize: 210,
        enableSorting: false,
        cell: ({ row }) => CheckBoxActionHtml(row)
      },
      {
        id: 'delete',
        size: 70,
        minSize: 70,
        enableSorting: false,
        cell: ({ row }) => deleteHtml(row)
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getContacts}
      columns={columns}
      columnVisibility={columnVisibility}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection={'asc'}
      trigger={trigger + indexTrigger}
      tableName={'Staffs'}
    />
  )
}

export default Table;
