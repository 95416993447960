import React from "react";
import classNames from 'classnames';

export { default as AddIcon } from './Add'
export { default as ArrowLeftIcon } from './ArrowLeft'
export { default as ArrowRightIcon } from './ArrowRight'
export { default as ArrowsCircleIcon } from './ArrowsCircle'
export { default as CloudDownloadIcon } from './CloudDownload'
export { default as ArrowRightBase } from './ArrowRightBase'
export { default as TrashIcon } from './Trash'

export const SearchIcon = () => {
  return (
    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const FilterIcon = () => {
  return (
    <i className="ki-duotone ki-filter fs-2">
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const RankingIcon = ({ className }) => {
  return (
    <i className={classNames('ki-duotone ki-ranking', className)}>
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
    </i>
  )
}

export const QuestionIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-question-2", className)}>
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
    </i>
  )
}

export const ExportIcon = ({ className }) => {
  return (
    <i className={classNames('ki-duotone ki-exit-up', className ? className : 'fs-2')}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const PlusIcon = () => <i className="ki-duotone ki-plus fs-2" />

export const GearIcon = () => <i className="ki-solid ki-gear fs-2x" />

export const PencilIcon = () => {
  return (
    <i className="ki-duotone ki-pencil fs-2">
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  )
}

export const CloudAdd = () => {
  return (
    <i className="ki-duotone ki-cloud-add fs-2">
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  )
}

export const WrenchIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-wrench", className)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}
export const PhoneIcon = () => {
  return (
    <i className="ki-duotone ki-phone">
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const EditIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-message-edit", className)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const CheckIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-check", className)}></i>
  )
}

export const CrossSquareIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-cross-square", className)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const SendIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-send", className)}>
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  )
}

export const HeartIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-heart", className)}>
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  )
}

export const CheckCircleIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-check-circle", className)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const WatchIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-watch", className)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const TrashSquareIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-trash-square", className)}>
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
      <span className="path4" />
    </i>
  )
}

export const CheckSquareIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-check-square", className)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const NavArrowRight = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-arrow-right", className)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}

export const PlusSquareIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-plus-square", className)}>
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
    </i>
  )
}

export const NavArrowLeft = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-arrow-left", className)}>
      <span className="path1" />
      <span className="path2" />
    </i>
  )
}
export const EditNotepadIcon = ({ className, onClick }) => {
  return (
    <i
      className={classNames("ki-duotone ki-notepad-edit text-primary fs-3", className)}
      onClick={onClick}
      style={{
        marginLeft: '8px',
        verticalAlign: 'middle',
        cursor: 'pointer',
      }}
    >
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  )
}

export const SplitItemIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-add-item", className)}>
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
    </i>
  );
};

export const StopIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-minus-circle", className)}>
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  );
};

export const CircleArrowIcon = ({ className }) => {
  return (
    <i className={classNames("ki-duotone ki-arrows-circle", className)}>
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  );
};
