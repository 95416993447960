import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { Form, Field } from 'react-final-form'
import ErrorField from '../Form/ErrorField';
import {axiosInstance, routes} from '../../utils/api_base';
import Toastr from '../Toastr';
import {mapValues} from 'lodash';
import { FORM_ERROR } from 'final-form'
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';
import debounce from 'lodash/debounce';

const CreatePlannedArticle = ({onClose, setTrigger, orderId }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    const data = {
      order_id: orderId,
      article_id: values.article?.value,
      quantity: values.quantity,
    };

    axiosInstance.post(routes.orderArticles(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: "message here",
          options: { closeButton: true }
        })

        onClose()
        setTrigger(true)
      })
      .catch(error => {
        const errorData = error.response.data

        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const getArticles = (inputText) => {
    let params = { page: 1, limit: 20, sort: 'client_specific_number', query: inputText }

    return axiosInstance.get(routes.articles(), { params: params })
      .then((response) => {
        return response.data.data.map((mg) => ({
          value: mg.id,
          label: mg.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const loadArticleOptions = useCallback(
    debounce((inputText, callback) => {
      getArticles(inputText).then((options) => callback(options));
    }, 1000), [])

  return (
    <Form
      onSubmit={onSubmit}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('quantity')}</label>
                <Field
                  name="quantity"
                  component="input"
                  type="text"
                  className="form-control"
                />
                <ErrorField name="quantity" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label className='required'>{t('article')}</label>
                <Field
                  name='article'
                  component={AsyncSelectAdapter}
                  loadOptions={loadArticleOptions}
                  placeholder="Select"
                  isClearable={true}
                >
                </Field>
                <ErrorField name="articleGroupId" />
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </form>
        </>
      )}
    />
  );
};

export default CreatePlannedArticle;
