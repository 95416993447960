import BaseTable from '../../Table';
import React, { useMemo, Fragment } from 'react';
import TableButton from '../../Table/TableButton';
import classNames from 'classnames'
import { PlusIcon } from '../../Icons';
import { axiosInstance, routes } from '../../../utils/api_base';
import { statusCodeStyle } from '../../../core/StatusLabel';
import { useTranslation } from 'react-i18next';

const Dunnings = () => {
  const { t, i18n } = useTranslation()

  const getData = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })

    const params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    const res = await axiosInstance.get(routes.dunnings(), { params: params })
    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const tableToolbar = () => {
    return (
      <TableButton className="btn-primary" href="/ueber_dunnings" icon={<PlusIcon />} title={t('shared.add_to')} />
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary px-3 lh-1 w-100px"
        title={dunningName(row?.original?.attributes?.level)}
      />
    )
  }

  const customerHtml = (row) => {
    return (
      <Fragment key={row}>
        <a target="_blank" href={row.original.attributes.business_partner_link}>
          {row.original.attributes.business_partner_name}
        </a>
      </Fragment>
    )
  }

  const dunningName = (level) => {
    switch (level) {
      case 0:
        return t('dunning.payment_reminder')
      case 1:
        return t('dunning.first_reminder')
      case 2:
        return t('dunning.second_reminder')
      case 3:
        return t('dunning.third_reminder')
    }
  }

  const billsHtml = (row) => {
    const bills = row?.original?.attributes?.bills || []
    return bills.map((bill, index) => billHtml(bill, index))
  }

  const billHtml = (bill, index) => {
    return (
      <Fragment key={index}>
        <a target="_blank" href={bill.attributes.link}>
          {bill.attributes.name}
        </a>
      </Fragment>
    )
  }

  const statusHtml = (row) => {
    const status = row.original.attributes.status

    return (
      <span className={classNames('badge', statusCodeStyle(status))}>
        {status}
      </span>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'reminder',
        header: t('shared.dunning'),
        accessorFn: row => row?.attributes?.id,
        size: 100,
        minSize: 100,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'bills',
        accessorFn: row => row?.attributes?.business_partner_name,
        header: t('shared.bill'),
        cell: ({ row }) => {
          return billsHtml(row)
        },
      },

      {
        id: 'customer',
        header: t('shared.customer'),
        accessorFn: row => row?.attributes?.business_partner_name,
        cell: ({ row }) => {
          return customerHtml(row)
        },
      },
      {
        id: 'amount',
        header: t('shared.amount'),
        accessorKey: 'attributes.dunning_amount',
        cell: row => row.getValue(),
      },
      {
        id: 'deadline',
        header: t('shared.deadline'),
        accessorKey: 'attributes.due_date',
        cell: row => row.getValue(),
      },
      {
        id: 'status',
        accessorKey: 'attributes.status',
        header: t('shared.status'),
        cell: ({ row }) => {
          return statusHtml(row)
        },
        size: 80,
        minSize: 80,
      },
      {
        id: 'paid_data',
        header: t('shared.paid_at'),
        accessorKey: 'attributes.paid_at',
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      tableName={'delivery_slip_table'}
      columnVisibility={{}}
      getDataFn={getData}
      tableToolbar={tableToolbar}
      initialSortDirection={'asc'}
    />
  )
}

export default Dunnings
