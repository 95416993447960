import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames'
import * as style from './index.module.css';
import Toastr from '../Toastr';
import QuestionMark from '../Icons/QuestionMark';
import { axiosInstance, routes } from '../../utils/api_base';
import Checkbox from '../Checkbox';
import { Form, Field } from 'react-final-form'
import TableButton from '../Table/TableButton';
import { Tooltip } from 'bootstrap'
import { isRequired } from '../Form/validations';
import ErrorField from '../Form/ErrorField';
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'

const Create = ({isCustomer, onClose, subContractorId}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [onClose]);

  const onSubmit = async values => {
    const data = {
      staff: {
        sub_contractor_id: subContractorId,
        user_attributes: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
          temporary_staff: values.temporaryStaff
        }
      }
    };

    axiosInstance.post(routes.staffs(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
        window.location.href = response.data.redirect_url
      })
      .catch(error => {
        const errorData = error.response.data
        setErrors(errorData.error)

        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const invalidInput = (message) => {
    return (
      <div className="d-block invalid-feedback">
        {message}
      </div>
    )
  };

  return(
    <Form
      onSubmit={onSubmit}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        temporaryStaff: false
      }}
      validate={values => {
        let errors = {}
        errors.lastName = isRequired(values.lastName)
        errors.email = isRequired(values.email)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <>
          {
            isCustomer &&
              <div className="px-7 mt-3">
                <div className={classNames("alert alert-info", style['pre-line'])}>
                  {t('staffs.new_employee_alert_html')}
                </div>
              </div>
          }
          <form onSubmit={handleSubmit}>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('shared.first_name')}</label>
                <Field
                  name="firstName"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <label className='required'>{t('shared.last_name')}</label>
                <Field
                  name="lastName"
                  component="input"
                  type="text"
                  className="form-control"
                />
                <ErrorField name="lastName" />
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <label className='required'>
                  {t('staffs.user_name_in_email_format')}
                  <button
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('staffs.user_name_in_email_format_title')}
                    className='btn btn-primary-outline btn-sm ps-2 pe-0'
                  >
                    <QuestionMark/>
                  </button>
                </label>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  className="form-control"
                />
                <ErrorField name="email" />
                {errors["user.email"] ? invalidInput(errors["user.email"]) : null}
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('shared.password')}</label>
                <Field
                  name="password"
                  component="input"
                  type="password"
                  className="form-control"
                />
              </div>
            </div>
            <div className="px-7 mb-3">
              <Field
                name="temporaryStaff"
                component={Checkbox}
                type="checkbox"
                size="sm"
                label={t('staffs.temporary_staff')}
              />
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </form>
        </>
      )}
    />
  )
}

export default Create;
